import React, { useState, useEffect } from "react";
import {
  Tabs,
  Input,
  Form,
  Row,
  Col,
  List,
  Rate,
  Button,
  Progress,
  Spin,
  Modal,
} from "antd";
import { deleteRequest, getRequest, postRequest } from "../../api/http";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import lockIcon from "../../assets/images/lockIcon.png";
import userapiservice from "../../api/userapiservice";
import { CommentOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import ContactMapComponent from "../../components/Contact-map/ContactMap";
import "./contactUs.scss";
import watsapp from "../../assets/images/watsapp.svg";
import address from "../../assets/images/address.svg";
import Linkedin from "../../assets/images/linkedIn.svg";
import instagram from "../../assets/images/instagram.svg";
import tiktok from "../../assets/images/tiktok.svg";
import facebook from "../../assets/images/facebook.svg";
import mail from "../../assets/images/mail.svg";
import twitter from "../../assets/images/twitter.svg";
import youtubeIc from "../../assets/images/youtube.svg";
import Website from "../../assets/images/website.svg";
import phoneIc from "../../assets/images/phone.svg";

const { TextArea } = Input;
function ContactUs() {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteReviewId, setDeleteReviewId] = useState(null);

  const handleDeleteReply = (replyId) => {
    const token = localStorage.getItem("token");

    deleteRequest(userapiservice.deleteReview(replyId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success("Reply deleted successfully", response.data);
        getReviewData();
      })
      .catch((error) => {
        toast.error("Error deleting reply:", error);
      });
  };

  const [replyFormOpen, setReplyFormOpen] = useState({});
  const toggleReplyForm = (reviewId) => {
    setReplyFormOpen((prevState) => ({
      ...prevState,
      [reviewId]: !prevState[reviewId],
    }));
  };

  const [contactUsKey, setContactUsKey] = useState(
    localStorage.getItem("contact-tabValue")
      ? localStorage.getItem("contact-tabValue")
      : "REVIEWS"
  );
  const [responseData, setReponseData] = useState([]);
  const [ReviewData, setReviewData] = useState([]);

  const [salesLocation, setSalesLocation] = useState();
  const [hrLocation, setHrLocation] = useState();
  const [eowLocation, setEowLocation] = useState();

  const profession = localStorage.getItem("prof");
  const age = localStorage.getItem("age");
  const access = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "";
  // const phone = localStorage.getItem("Phone Number");

  const auth = localStorage.getItem("_grecaptcha");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  let tabValue = localStorage.getItem("tabValue");
  const [fadingOut] = useState(false);
  const phoneNumberWithCountryCode = localStorage.getItem("Phone Number");
  // console.log(phoneNumberWithCountryCode,"phhhhh");

  const [phone, setphone] = useState();
  const [showReviewForm, setShowReviewForm] = useState(false);
  // ... (other state and useEffect code)

  const toggleReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };

  const contactUsChangeTab = (tabValue) => {
    setContactUsKey(tabValue);
  };

  const getContactusData = async (activeKey, id) => {
    try {
      setLoading(true);
      if (id) {
        const response = await getRequest(
          userapiservice.getContactUsDetails(id, contactUsKey)
        );
        if (response) {
          setReponseData(response);
          if (activeKey === "SALES") {
            setSalesLocation([response[0]?.lat || 0, response[0]?.lng || 0]);
          } else if (activeKey === "HUMAN_RESOURCE") {
            setHrLocation([response[0]?.lat || 0, response[0]?.lng || 0]);
          } else {
            setEowLocation([response[0]?.lat || 0, response[0]?.lng || 0]);
          }

          setLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const getReviewData = async () => {
    try {
      setLoading(true);

      const response = await getRequest(
        userapiservice.getReviews(id, phoneNumberWithCountryCode)
      );
      setReviewData(response);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contactUsKey != "REVIEWS") {
      getContactusData(contactUsKey, id);
    }
    // contactUsChangeTab()
    getReviewData();
  }, [contactUsKey, id]);

  const calculateAverageRating = () => {
    if (ReviewData.length === 0) return 0;

    const totalRating = ReviewData.reduce((sum, item) => sum + item.rating, 0);
    const averageRating = totalRating / ReviewData.length;

    return Number(averageRating);
  };
  const avgrate = calculateAverageRating();

  const calculateRatingDistribution = () => {
    if (ReviewData.length === 0) {
      return {};
    }

    const ratingCounters = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    ReviewData.forEach((item) => {
      const roundedRating = Math.floor(item.rating);
      ratingCounters[roundedRating] += 1;
    });

    const totalCount = ReviewData.length;

    const ratingPercentages = {
      1: (ratingCounters[1] / totalCount) * 100,
      2: (ratingCounters[2] / totalCount) * 100,
      3: (ratingCounters[3] / totalCount) * 100,
      4: (ratingCounters[4] / totalCount) * 100,
      5: (ratingCounters[5] / totalCount) * 100,
    };

    return ratingPercentages;
  };

  const ratingPercentages = calculateRatingDistribution();

  const changeContactUsTab = (tabValue) => {
    setSalesLocation(null);
    setHrLocation(null);
    setEowLocation(null);
    setContactUsKey(tabValue);
  };

  useEffect(() => {
    let age = localStorage.getItem("age");
  }, []);

  const [rating, setRating] = useState(2.5);
  const [disabled, setDisabled] = useState(true);
  const [document, setDocuments] = useState();
  const [Rew, setRew] = useState("");
  const handleRatingChange = (newRating) => {
    setDisabled(false);
    setRating(newRating);
  };

  const handleReviewChange = (e) => {
    setRew(e.target.value);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();

    const url = userapiservice.addReview();

    const requestBody = {
      mobileNumber: phoneNumberWithCountryCode,
      isPartnerApproved: true,
      description: Rew,
      rating: rating,
      micrositeId: id,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await postRequest(url, requestBody, headers);

      // Handle the response data here if needed
      toast.success("Review Submitted!");

      // console.log("Response:", response);
      setRating(0);
      setRew("");
      getReviewData();
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  const value = avgrate;
  const allowHalf = value % 1 !== 0;

  const getFiles = async () => {
    try {
      const response = await getRequest(
        userapiservice.getFiles(localStorage.getItem("micrositeID"))
      );
      if (response) {
        // console.log("res", response)
        setDocuments(response);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (access.length > 1) {
      getFiles();
    }
  }, []);

  const callContactViewAPI = async (micrositeId, tabEnum) => {
    if (
      localStorage.getItem("ageID") !== "3923bb48-79f8-4a9e-98f8-b604137197cc"
    ) {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const res = await postRequest(
          userapiservice.contactPageViewCount(micrositeId, tabEnum),
          headers
        );
        if (res) {
          if (tabEnum === "SALES") {
            sessionStorage.setItem("salesView", "viewed");
          } else if (tabEnum === "HUMAN_RESOURCE") {
            sessionStorage.setItem("hrView", "viewed");
          } else if (tabEnum === "EXPERIENCES_OF_WORK") {
            sessionStorage.setItem("eowView", "viewed");
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const micrositeId = localStorage.getItem("micrositeID");

  useEffect(() => {
    if (contactUsKey === "SALES") {
      const tabCount = sessionStorage.getItem("salesView");
      if (tabCount != "viewed") {
        callContactViewAPI(micrositeId, "SALES");
      }
    } else if (contactUsKey === "HUMAN_RESOURCE") {
      const tabCount = sessionStorage.getItem("hrView");
      if (tabCount != "viewed") {
        callContactViewAPI(micrositeId, "HUMAN_RESOURCE");
      }
    } else if (contactUsKey === "EXPERIENCES_OF_WORK") {
      const tabCount = sessionStorage.getItem("eowView");
      if (tabCount != "viewed") {
        callContactViewAPI(micrositeId, "EXPERIENCES_OF_WORK");
      }
    }
  }, [contactUsKey]);

  if (age <= 18 || auth) {
    // if (age >= 18) {
    return (
      <div className="background-container" style={{ width: "100%" }}>
        <Tabs
          onChange={changeContactUsTab}
          activeKey={contactUsKey}
          destroyInactiveTabPane
        // style={this.state.fadingOut ? {filter : "blur(2px)"}}
        >
          <Tabs.TabPane tab="Reviews" key="REVIEWS">
            <div className="ratingNdReview">
              <div className="ratingBars">
                <div>
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ fontSize: 25, fontWeight: "bold" }}>
                      {avgrate.toFixed(2)}
                    </div>
                    <Rate
                      value={value}
                      disabled
                      style={{ fontSize: 15 }}
                      allowHalf
                    />
                    <div style={{ fontSize: 10 }}>({ReviewData.length})</div>
                  </div>
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="progressBarContainer">
                        <span className="progressNumber">5</span>
                        <Progress
                          className="progressBar"
                          percent={ratingPercentages[5]}
                          format={(percent) => `${percent.toFixed(2)}%`}
                          showInfo={false}
                        />
                      </div>
                      <div className="progressBarContainer">
                        <span className="progressNumber">4</span>
                        <Progress
                          className="progressBar"
                          percent={ratingPercentages[4]}
                          format={(percent) => `${percent.toFixed(2)}%`}
                          showInfo={false}
                        />
                      </div>
                      <div className="progressBarContainer">
                        <span className="progressNumber">3</span>
                        <Progress
                          className="progressBar"
                          percent={ratingPercentages[3]}
                          format={(percent) => `${percent.toFixed(2)}%`}
                          showInfo={false}
                        />
                      </div>
                      <div className="progressBarContainer">
                        <span className="progressNumber">2</span>
                        <Progress
                          className="progressBar"
                          percent={ratingPercentages[2]}
                          format={(percent) => `${percent.toFixed(2)}%`}
                          showInfo={false}
                        />
                      </div>
                      <div className="progressBarContainer">
                        <span className="progressNumber">1</span>
                        <Progress
                          className="progressBar"
                          percent={ratingPercentages[1]}
                          format={(percent) => `${percent.toFixed(2)}%`}
                          showInfo={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reviewForm">
                {age > 18 ? (
                  <Form
                    onFinish={handleSubmit}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      rules={[
                        {
                          required: true,
                          message: "Description is required",
                        },
                      ]}
                    >
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "left",
                          marginRight: "20px",
                        }}
                      >
                        Rate us
                      </label>
                      {/* Assuming you have a Rate component */}
                      <Rate
                        allowHalf
                        value={rating}
                        onChange={handleRatingChange}
                        defaultValue={rating}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "10px" }}>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        Write us a Review
                      </label>
                      <Input.TextArea
                        placeholder="Enter Review"
                        value={Rew}
                        onChange={handleReviewChange}
                        style={{
                          width: "100%",
                          minHeight: "100px",
                          borderRadius: "5px",
                          resize: "none",
                        }}
                        maxLength={100}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ alignSelf: "flex-end", marginTop: "10px" }}
                    >
                      <Button
                        htmlType="submit"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#569dfa",
                          color: "white",
                        }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <div style={{ padding: 10 }}>
                <List
                  dataSource={ReviewData}
                  renderItem={(item) => (
                    <div>
                      {/* console.log(item,'item'); */}
                      <div>{item.description}</div>
                      <Rate
                        allowHalf
                        value={item.rating}
                        disabled
                        style={{ fontSize: 15 }}
                      />
                      {item.replies && item.replies.length > 0 && (
                        <Button
                          type="link"
                          icon={<CommentOutlined />}
                          onClick={() => toggleReplyForm(item.id)}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                      {item.mobileNumber === phoneNumberWithCountryCode && (
                        <>
                          <Modal
                            title="Delete Review"
                            visible={modalVisible}
                            onCancel={() => setModalVisible(false)}
                            footer={[
                              <Button
                                key="cancel"
                                onClick={() => setModalVisible(false)}
                              >
                                Cancel
                              </Button>,
                              <Button
                                key="delete"
                                // type="primary"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() => handleDeleteReply(item.id)}
                              >
                                Delete
                              </Button>,
                            ]}
                          >
                            <p>Are you sure you want to delete this review?</p>
                          </Modal>

                          <Button
                            onClick={() => {
                              setDeleteReviewId(item.id);
                              setModalVisible(true);
                            }}
                            type="link"
                            style={{ marginLeft: 10 }}
                            icon={<DeleteOutlined />}
                          />
                        </>
                      )}

                      {replyFormOpen[item.id] && (
                        <div>
                          {item.replies && item.replies.length > 0 && (
                            <List
                              style={{ marginLeft: 20 }}
                              dataSource={item.replies}
                              renderItem={(reply, index) => (
                                <div>
                                  <div>
                                    <p style={{ fontWeight: 600 }}>
                                      reply from the organization:{" "}
                                    </p>
                                    {reply.description}
                                  </div>
                                  {index < item.replies.length - 1 && (
                                    <hr
                                      style={{
                                        borderColor: "lightgray",
                                        opacity: 0.3,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            />
                          )}
                        </div>
                      )}
                      <hr style={{ borderColor: "lightgray", opacity: 0.5 }} />
                    </div>
                  )}
                />
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Social media" key="SOCIAL_MEDIA">
            <div className="content">
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ overflow: 'hidden' }}
              >
                {contactUsKey === "SOCIAL_MEDIA" &&
                  responseData &&
                  responseData.length > 0 ? (
                  responseData.map((data) => {
                    return (
                      <>
                        {data.linkedInLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={Linkedin} alt="linkedIn"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Linkedin
                              </label>
                            </Col>
                            <Col
                              className="gutter-row"
                            >
                              <a href={data.linkedInLink} target="_blank">
                                {data.linkedInLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.twitterLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={twitter} alt="twitter"></img>
                              <label style={{ marginLeft: "3px" }}>X</label>
                            </Col>
                            <Col
                              className="gutter-row"
                            // span={window.innerWidth > "375" ? 20 : 15}
                            >
                              <a href={data.twitterLink} target="_blank">
                                {data.twitterLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.youtubeLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={youtubeIc} alt="youtube"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Youtube
                              </label>
                            </Col>
                            <Col
                              className="gutter-row"
                            >
                              <a href={data.youtubeLink} target="_blank">
                                {data.youtubeLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.instagramLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={instagram} alt="instagram"></img>
                              <label style={{ marginLeft: "3px" }}>
                                instagram
                              </label>
                            </Col>
                            <Col
                              className="gutter-row"
                            >
                              <a href={data.instagramLink} target="_blank">
                                {data.instagramLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.tiktokLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={tiktok} alt="tiktok"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Tik-Tok
                              </label>
                            </Col>
                            <Col
                              className="gutter-row"
                            >
                              <a href={data.tiktokLink} target="_blank">
                                {data.tiktokLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.facebookLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col
                              className="gutter-row col-style"
                              xs={6} xl={4}                            >
                              <img src={facebook} alt="facebook"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Facebook
                              </label>
                            </Col>
                            <Col
                              className="gutter-row"
                            >
                              <a href={data.facebookLink} target="_blank">
                                {data.facebookLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div
                    className="background-container"
                    style={{ width: "100%" }}
                  >
                    <div className="login-wall">
                      <div className="info-container">
                        {/* <img src={lockIcon} alt="Partner Logo" /> */}
                        <span>No contact details available at the moment</span>
                        {/* <a onClick={() => navigate("/login", { state: { id } })}>Login</a> */}
                      </div>
                    </div>
                  </div>
                )}
              </Row>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Sales" key="SALES">
            <div className="content">
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                {contactUsKey === "SALES" &&
                  responseData &&
                  responseData.length > 0 ? (
                  responseData.map((data) => {
                    return (
                      <>
                        {data.phone && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={24}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={phoneIc} alt="phone"></img>
                              <label style={{ marginLeft: "3px" }}>Phone</label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              <a href={"tel:" + data.phone}>{data.phone}</a>
                            </Col>
                          </Col>
                        )}
                        {data.email && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={mail} alt="mail"></img>
                              <label style={{ marginLeft: "3px" }}>Email</label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              <a href={"mailto:" + data.email}>{data.email}</a>
                            </Col>
                          </Col>
                        )}
                        {data.chatNumber && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={watsapp} alt="watsapp"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Whatsapp
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              {data.chatNumber}
                            </Col>
                          </Col>
                        )}
                        {data.websiteLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={Website} alt="website"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Website
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              <a href={data.websiteLink} target="_blank">
                                {data.websiteLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.postalAddress && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={address} alt="address"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Address
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              {data.postalAddress}
                            </Col>
                          </Col>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div
                    className="background-container"
                    style={{ width: "100%" }}
                  >
                    <div className="login-wall">
                      <div className="info-container">
                        {/* <img src={lockIcon} alt="Partner Logo" /> */}
                        <span>No contact details available at the moment</span>
                        {/* <a onClick={() => navigate("/login", { state: { id } })}>Login</a> */}
                      </div>
                    </div>
                  </div>
                )}
              </Row>
              {/* {contactUsKey === "SALES" &&
                responseData &&
                salesLocation?.length > 0 &&
                responseData.length > 0 && (
                  <div className="map-container">
                    <ContactMapComponent position={salesLocation} />
                  </div>
                )} */}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Career" key="HUMAN_RESOURCE">
            <div className="content">
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                {contactUsKey === "HUMAN_RESOURCE" &&
                  responseData &&
                  responseData.length > 0 ? (
                  responseData.map((data) => {
                    return (
                      <>
                        {data.phone && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={phoneIc} alt="phone"></img>
                              <label style={{ marginLeft: "3px" }}>Phone</label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              <a href={"tel:" + data.phone}>{data.phone}</a>
                            </Col>
                          </Col>
                        )}
                        {data.email && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={mail} alt="mail"></img>
                              <label style={{ marginLeft: "3px" }}>Email</label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              <a href={"mailto:" + data.email}>{data.email}</a>
                            </Col>
                          </Col>
                        )}
                        {data.chatNumber && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={watsapp} alt="watsapp"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Whatsapp
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              {data.chatNumber}
                            </Col>
                          </Col>
                        )}
                        {data.websiteLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={Website} alt="website"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Website
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              {/* {data.websiteLink} */}
                              <a href={data.websiteLink} target="_blank">
                                {data.websiteLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.postalAddress && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={6} xl={4}>
                              <img src={address} alt="address"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Address
                              </label>
                            </Col>
                            <Col className="gutter-row" span={18}>
                              {data.postalAddress}
                            </Col>
                          </Col>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div
                    className="background-container"
                    style={{ width: "100%" }}
                  >
                    <div className="login-wall">
                      <div className="info-container">
                        {/* <img src={lockIcon} alt="Partner Logo" /> */}
                        <span>No contact details available at the moment</span>
                        {/* <a onClick={() => navigate("/login", { state: { id } })}>Login</a> */}
                      </div>
                    </div>
                  </div>
                )}
              </Row>
              {/* {contactUsKey === "HUMAN_RESOURCE" &&
                responseData &&
                hrLocation?.length > 0 &&
                responseData.length > 0 && (
                  <div className="map-container">
                    <ContactMapComponent position={hrLocation} />
                  </div>
                )} */}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Experiences of work" key="EXPERIENCES_OF_WORK">
            {profession && profession === "Teacher" && document && (
              <div className="download-container">
                <a
                  href={document?.filePath}
                  download={document?.filePath}
                  target="_blank"
                >
                  <Button className="download-btn">Download Insurance</Button>
                  {/* {document?.fileName} */}
                </a>
              </div>
            )}
            <div className="content">
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              >
                {contactUsKey === "EXPERIENCES_OF_WORK" &&
                  responseData &&
                  responseData.length > 0 ? (
                  responseData.map((data) => {
                    return (
                      <>
                        {data.phone && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={9} xl={4}>
                              <img src={phoneIc} alt="phone"></img>
                              <label style={{ marginLeft: "3px" }}>Phone</label>
                            </Col>
                            <Col className="gutter-row" span={15}>
                              <a href={"tel:" + data.phone}>{data.phone}</a>
                            </Col>
                          </Col>
                        )}
                        {data.email && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={9} xl={4}>
                              <img src={mail} alt="mail"></img>
                              <label style={{ marginLeft: "3px" }}>Email</label>
                            </Col>
                            <Col className="gutter-row" span={15}>
                              <a href={"mailto:" + data.email}>{data.email}</a>
                            </Col>
                          </Col>
                        )}
                        {data.chatNumber && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={9} xl={4}>
                              <img src={watsapp} alt="watsapp"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Whatsapp
                              </label>
                            </Col>
                            <Col className="gutter-row" span={15}>
                              {data.chatNumber}
                            </Col>
                          </Col>
                        )}
                        {data.websiteLink && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={9} xl={4}>
                              <img src={Website} alt="website"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Website
                              </label>
                            </Col>
                            <Col className="gutter-row" span={15}>
                              {/* {data.websiteLink} */}
                              <a href={data.websiteLink} target="_blank">
                                {data.websiteLink}
                              </a>
                            </Col>
                          </Col>
                        )}
                        {data.postalAddress && (
                          <Col
                            className="gutter-row tabContent contactTab"
                            span={23}
                          >
                            <Col className="gutter-row col-style" xs={9} xl={4}>
                              <img src={address} alt="address"></img>
                              <label style={{ marginLeft: "3px" }}>
                                Address
                              </label>
                            </Col>
                            <Col className="gutter-row" span={15}>
                              {data.postalAddress}
                            </Col>
                          </Col>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div
                    className="background-container"
                    style={{ width: "100%" }}
                  >
                    <div className="login-wall">
                      <div className="info-container">
                        {/* <img src={lockIcon} alt="Partner Logo" /> */}
                        <span>No contact details available at the moment</span>
                        {/* <a onClick={() => navigate("/login", { state: { id } })}>Login</a> */}
                      </div>
                    </div>
                  </div>
                )}
              </Row>
              {/* {contactUsKey === "EXPERIENCES_OF_WORK" &&
                responseData &&
                eowLocation?.length > 0 &&
                responseData.length > 0 && (
                  <div className="map-container">
                    <ContactMapComponent position={eowLocation} />
                  </div>
                )} */}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  } else {
    return (
      <div className="background-container" style={{ width: "100%" }}>
        <div className="login-wall">
          <div className="info-container">
            <img src={lockIcon} alt="Partner Logo" />
            <span>Login to view contact information</span>
            <a
              onClick={() =>
                navigate("/login", { state: { page: "microsite" } })
              }
            >
              Register/Login
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
