import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./ProfileDropdown.scss";
import OrgLogin from "../../assets/images/OrgLogin.svg"

const ProfileDropdown = ({ closeDropdown }) => {
  const page = localStorage.getItem("Page")
  const navigate = useNavigate();
  const age = localStorage.getItem("age");
  const authToken = localStorage.getItem("accessToken");


  // const [isProfileModalShown, setIsProfileModalShown] = useState();
  const [showEditProfile, setShowEditProfile] = useState(false);
  // const [partner, setPartner] = useState(false);

  // const clickHandler = () => {
  //   setIsProfileModalShown(true);
  // };

  const editProfileClickHandler = () => {
    // console.log('check');
    setShowEditProfile(true)
  }

  const LogoutHandler = () => {
    closeDropdown();
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("age");
    localStorage.removeItem("Phone Number");
    localStorage.removeItem("tabValue");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sector");
    localStorage.removeItem("subsector");
    localStorage.removeItem("size");
    localStorage.removeItem("Interest/AssetType");
    localStorage.removeItem("zoom");
    localStorage.removeItem("lat");
    localStorage.removeItem("lng");
    localStorage.removeItem("prof");
    localStorage.removeItem("micrositeID");
    localStorage.removeItem("micrositeName");
    localStorage.removeItem("ageID");
    localStorage.removeItem("profID");
    localStorage.removeItem("searchName");
    localStorage.removeItem("OrganisationType");
    navigate("/frontend");
  };

  const closeModal = () => {
    // console.log("closed")
    setShowEditProfile(false)
  }

  useEffect(() => {
    // getPartner(); To be used when subdomains come into picture so that i can get the partner org login url in the API
  }, [])

  return (
    <div className="profileDropDown profile-container">
      {/* {console.log('showEditProfile', showEditProfile)} */}
      {/* <div className="profile-info-container"> */}
      {/* <p className="profile-username">
          {userData.firstName ? userData.firstName : "Login/Sign-up"}{" "}
          {userData.lastName ? userData.lastName : ""}
        </p> */}
      {/* <p className="profile-email">{userData.email}</p> */}
      {/* </div> */}
      <div className="dropdown-details">
        {(page === "partner") ? (
          <div style={{ border: "none" }} className="profile-navigate-container">
            <a href={`https://orgadmin.expo365.app/login/${localStorage.getItem("partnerId")}`} target="_blank">
              <div className="orgLoginContainer">
                <img
                  src={OrgLogin}
                  alt="main logo"
                />
                <label className="orgLogin">
                  Organisation Login
                </label>
              </div>
            </a>
          </div>
        ) : (
          ""
        )}
        {/* {(age > 18) && (authToken) && (page != "partner") ? (
          <div>
            <div className="profile-navigate-container orgLoginContainer" >
              <img
                src={EditProfileLogo}
                alt="main logo"
                onClick={editProfileClickHandler}
              />
              <label className="orgLogin" onClick={editProfileClickHandler}>
                Edit user profile
              </label>
              {showEditProfile ? (
                <EditProfilePage closeModal={closeModal} />
              ) : (<></>)}
            </div>
          </div>
        ) : (
          // <div className="profile-navigate-container">
          // </div>
          ""
        )} */}
        {(page != "partner") ? (
          <div className="profile-navigate-container">
            <a href="https://connectsu-dev.eastus.cloudapp.azure.com/login/YourBusiness" target="_blank">
              <div className="orgLoginContainer">
                <img
                  src={OrgLogin}
                  alt="main logo"
                />
                <label className="orgLogin">
                  Organisation Login
                </label>
              </div>
            </a>
          </div>
        ) : (
          // <div className="profile-navigate-container">
          // </div>
          ""
        )}
        {/* {(page != "partner") ? ( */}
        < div
          className="profile-navigate-container orgLoginContainer border-none"
          onClick={LogoutHandler}
        >
          <img
            src={OrgLogin}
            alt="main logo"
          />
          <label className="orgLogin">
            User logout
          </label>
          {/* <img
              src={require("../../assets/images/Group5886.png")}
              alt="main logo"
            // width={206}
            /> */}
        </div>
        {/* ) : ("")} */}
      </div>
    </div >
  );
  // return <Profile />;
};

export default ProfileDropdown;
