import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import "./OtpPage.scss";
import { postRequest } from "../../api/http";
import loginServices from "../../api/loginservices";
import Leftarrow from "../../assets/images/Leftarrow.svg";

export default function OtpPage() {
  // const { Countdown } = Statistic;
  const navigate = useNavigate();
  const location = useLocation();
  const [otpValue, setOtpValue] = useState("");
  // const [timer, setTimer] = useState(Date.now() + 10 * 1000);
  // const [enableResendOtp, setEnableResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const auth = localStorage.getItem("_grecaptcha");
  // const [resendOtpTries, setResendOtpTries] = useState(1);
  const { state } = useLocation();
  const page = state.page;
  const id = localStorage.getItem("micrositeID");
  const name = localStorage.getItem("micrositeName");
  const partner = localStorage.getItem("partner");
  // const handleOTPChange = (e) => {
  //   console.log("e---->", e)
  //   setOtpValue(e);
  // };
  // console.log("OTPpage", state)
  // const handleResend = async (values) => {
  //   try {
  //     setLoading(true);
  //     const reCaptchaToken = await window.recaptchaVerifier.verify();
  //     const phone = localStorage.getItem("Phone Number");
  //     // console.log("phone", phone);
  //     // console.log(reCaptchaToken);

  //     const session = await postRequest(
  //       loginServices.getOtp(),
  //       {
  //         phoneNumber: phone,
  //         recaptchaToken: reCaptchaToken,
  //       },
  //       auth
  //     );
  //     setLoading(false);
  //     setResendOtpTries(resendOtpTries - 1);
  //     // navigate("/verifyOtp", {
  //     // state: { sessionInfo: session.sessionInfo, phone: phone },
  //     // });
  //   } catch (e) {
  //     // console.log("error total");
  //     Modal.error({
  //       title: "Error",
  //       content: e.response?.data?.message || "Something went wrong...",
  //     });
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    // console.log("submit", otpValue)
    try {
      setLoading(true);
      const data = {
        sessionInfo: location.state.sessionInfo,
        phoneNumber: location.state.phone,
        code: otpValue,
      };
      const response = await postRequest(loginServices.studentLogin(), data);
      localStorage.setItem("accessToken", response.accessToken);
      if (!response.accessToken) {
        localStorage.removeItem("Phone Number");
      }
      if (page === "microsite") {
        navigate(`/microsite/${name}`, { replace: true });
      } else if (page === "partner") {
        navigate(`/`);
      }
      else {
        navigate(`/home`);
      }
      setLoading(false);
      //   if (response.isUserRegistered) {
      //     localStorage.setItem("auth", JSON.stringify(response));
      //     navigate(`/microsite/${id}`, { replace: true });
      //   } else
      //     navigate("/register", { state: { tokens: response }, replace: true });
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  // const handleResendOtp = () => {
  //   setEnableResendOtp(false);
  //   setTimer(Date.now() + 10 * 1000);
  // };

  // useEffect(() => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         return response;
  //       },
  //     },
  //     firebaseAuth
  //   );
  //   if (location?.state?.sessionInfo === undefined)
  //     navigate("/login", { replace: true });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [resendOtpTries]);

  // if (loading) return <LoadingView />;

  const backButtonHandler = () => {
    localStorage.removeItem("Phone Number");
    localStorage.removeItem("_grecaptcha");
    navigate("/login", { state: { id, page: page } });
  }

  return (
    <div
      className="background-container"
    >
      <div className="header">
        <div className="onBoarding-header">
          <img
            className="back-btn"
            src={Leftarrow}
            style={{ cursor: "pointer" }}
            onClick={backButtonHandler}
          />

          <h2>Phone verification</h2>
        </div>
      </div>
      <div className="otp-container">
        <div className="verify-heading">
          <p className="regular-text">One Time Password (OTP)</p>
        </div>
        {error && (
          <div className="verify-error">
            <div style={{ padding: "0.5em" }}>
              Authentication Failed! Please Retry
            </div>
          </div>
        )}
        <div className="spacer"></div>
        <div className="verify-input">
          <div className="OTP-form">
            <OtpInput
              className="otpFields"
              shouldAutoFocus={true}
              value={otpValue}
              onChange={setOtpValue}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className="spacer"></div>
            <Button
              className="button-primary"
              type="primary"
              onClick={() => handleSubmit()}
            // disabled={loading || otpValue.length !== 6}
            >
              Verify Now
            </Button>
          </div>
          {/* <Form
            name="OTPForm"
            className="OTP-form"
            onFinish={handleSubmit}>
            <Form.Item>
              <OtpInput
                className="otpFields"
                isInputNum={true}
                shouldAutoFocus={true}
                numInputs={6}
                value={otpValue}
                onChange={handleOTPChange}
                hasErrored={true}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="button-primary"
                htmlType="submit"
              // onClick={() => handleSubmit()}
              // disabled={loading || otpValue.length !== 6}
              >
                Verify Now
              </Button>
            </Form.Item>
          </Form> */}
          {/* <div className="spacer"></div>
          <span onClick={handleResend} className="link text-regular">
            <u>Resend OTP</u>
          </span> */}
          {/* {enableResendOtp ? (
              <span className="link text-regular" onClick={handleResendOtp}>
                <u>Resend OTP</u>
              </span>
            ) : (
              <Countdown
                title="Resend OTP in:"
                format="mm:ss"
                value={timer}
                onFinish={() => setEnableResendOtp(true)}
              />
            )} */}
        </div>
      </div>
      {/* <div id="recaptcha" style={{ display: "none" }}></div> */}
    </div>
  );
}
