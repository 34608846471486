import React,{useEffect} from 'react'
import './ToastComponent.scss'

const ToastComponent = ({type,message}) => {
  return (
    <div className='toast-component'>
    <div className='toast-header' style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "4px", display: "block" }}>
      {type || 'Success'}
    </div>
    <span className='toast-label'>{message || ''}</span>
  </div>
  )
}

export default ToastComponent