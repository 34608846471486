import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice'
import globalReducer from './globalSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    global:globalReducer
  },
});

export default store;