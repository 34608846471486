import axios from "axios";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import userapiservice from "./userapiservice";

const getCurrentTime = async () => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("accessToken");
    const decoded = jwtDecode(token);
    const jwtExpTime = decoded?.exp;
    if (jwtExpTime) {
      const currentTime = moment().add(5, "minutes").unix();
      if (currentTime <= jwtExpTime) {
        resolve(true);
      } else {
        resolve(false);
      }
    } else {
      reject(false);
    }
  });
};

const getToken = async () => {
  try {
    console.log("--gettoken------")
    const refreshToken = localStorage.getItem("refresh");
    if (refreshToken) {
      const response = await fetch(userapiservice.refreshToken(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
      });
      const dataR = await response.json();
      if (dataR) {
        localStorage.setItem("accessToken", dataR.accessToken);
        return dataR.accessToken;
      }
    } else {
      console.log("refresh token not recived");
    }
  } catch (error) {
    console.error(error);
  }
};

// const refreshAuthLogic = (failedRequest) => {
//   const auth = JSON.parse(localStorage.getItem("auth"));
//   return axios
//     .post(`${API_URL}/refresh-token`, {
//       refreshToken: auth.refreshToken,
//       pauseInstanceWhileRefreshing: true,
//     })
//     .then((tokenRefreshResponse) => {
//       localStorage.setItem("auth", JSON.stringify(tokenRefreshResponse.data));
//       failedRequest.response.config.headers["Authorization"] =
//         "Bearer " + tokenRefreshResponse.data.refreshToken;
//       return Promise.resolve();
//     });
// };

// createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(
  async (req) => {
    if (localStorage.getItem("accessToken")) {
      const tokenExist = await getCurrentTime();
      if (tokenExist) {
        const token = localStorage.getItem("accessToken");
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
      } else {
        const token = await getToken();
        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
      }
    } else {
      return req;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const postRequest = async (url, data, headers) => {
  try {
    const response = await axios.post(url, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const putRequest = async (url, data, headers) => {
  try {
    const response = await axios.put(url, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRequest = async (url, headers) => {
  try {
    const response = await axios.get(url, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return Promise.reject(error);
  }
};

export const deleteRequest = async (url, data, headers) => {
  try {
    const response = await axios.delete(url, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const API_URL = process.env.REACT_APP_API_BASE_URL;
