import { LoadingOutlined } from "@ant-design/icons";
import {
  Select as AntSelect,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio
} from "antd";
import { RecaptchaVerifier } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from 'react-redux';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getRequest, postRequest, putRequest } from "../../api/http";
import { default as loginservices, default as loginServices } from "../../api/loginservices";
import userapiservice from "../../api/userapiservice";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import countryData from "../../json/countries.json";
import { setUserProfile } from "../../Reducer/globalSlice";
import firebaseAuth from "./firebase";
import "./LoginPage.scss";
import ToastComponent from "../../components/ToastCompoent/ToastComponent";

export default function LoginPage() {
  const { endUserDetails, setEndUserDetails } =
    useContext(GlobalContext);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [phoneFormat, setPhoneFormat] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const auth = localStorage.getItem("auth");
  const { state } = useLocation();
  // const page = state.page ? state.page : localStorage.getItem("Page");
  const page = localStorage.getItem("Page");
  const id = localStorage.getItem("micrositeID");
  const name = localStorage.getItem("micrositeName");
  const [selectedCountry, setSelectedCountry] = useState({ value: '+44', label: 'United Kingdom (+44)' });
  const [OTPPage, setOTPPage] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [phoneRegistered, setPhoneRegistered] = useState(true);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupData, setAgeGroupsData] = useState([]);
  const [selectedAgeGroupsData, setSelectedAgeGroupsData] = useState();
  const [age, setAge] = useState();
  const [prof, setProf] = useState();

  //OTP states
  const [otpValue, setOtpValue] = useState("");
  const [error, setError] = useState(false);

  const partner = localStorage.getItem("partner");

  const countryOptions = countryData.map((country) => ({
    value: country.dial_code,
    label: `${country.name} (${country.dial_code})`,
  }));

  const defaultUKOption = countryOptions.find(option => option.label === 'United Kingdom (+44)');

  // Set the default value to the UK option
  const defaultSelectedCountry = defaultUKOption ? defaultUKOption : null;

  const handleRegisterSubmit = async (values) => {
    const selectedCountryCode = selectedCountry.value;
    const phone = `${selectedCountryCode}${values.phoneNumber}`;
    setPhone(phone);
    setEmail(values.email);
    setUsername(values.userName);
    setAge(values.ageId);
    setProf(values.tagId);

    try {
      setLoading(true);
      const reCaptchaToken = await window.recaptchaVerifier.verify();
      localStorage.setItem("Phone Number", phone);
      const session = await postRequest(
        loginservices.getOtp(),
        {
          phoneNumber: phone,
          recaptchaToken: reCaptchaToken,
          email: values.email,
          userName: values.userName,
          ageId: values.ageId,
          tagId: values.tagId,
        },
        auth
      );
      setShowLogin(false);
      setOTPPage(true);
      setSessionInfo(session.sessionInfo);
      // setLoading(false);
      // navigate("/verifyOtp", {
      //   state: {
      //     sessionInfo: session.sessionInfo,
      //     phone: phone,
      //     micrositeID: id,
      //     page: state.page,
      //   },
      // });
    } catch (e) {
      // console.log(e)
      Modal.error({
        title: "Error",
        content:
          e?.response?.data?.error ||
          "Something went wrong, please try again later.",
      });
      setLoading(false);
    }
  };

  const handleLoginSubmit = async (values) => {
    const checkPhone = await getRequest(
      userapiservice.checkPhonenumber(
        `${selectedCountry.value.replace(/\+/g, "%2B")}${values.phoneNumber}`
      )
    );
    if (checkPhone) {
      const selectedCountryCode = selectedCountry.value;
      const phone = `${selectedCountryCode}${values.phoneNumber}`;
      setPhone(phone);

      try {
        setLoading(true);
        const reCaptchaToken = await window.recaptchaVerifier.verify();
        localStorage.setItem("Phone Number", phone);
        const session = await postRequest(
          loginservices.getOtp(),
          {
            phoneNumber: phone,
            recaptchaToken: reCaptchaToken,
          },
          auth
        );
        setShowLogin(false);
        setOTPPage(true);
        setSessionInfo(session.sessionInfo);
        // setLoading(false);
        // navigate("/verifyOtp", {
        //   state: {
        //     sessionInfo: session.sessionInfo,
        //     phone: phone,
        //     micrositeID: id,
        //     page: state.page,
        //   },
        // });
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    } else {
      setPhoneRegistered(false);
    }
    // if (!checkPhone) {
    //   toast.error("Register to login");
    // }
  };

  const getProfilePic = async (accessToken) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getRequest(
        userapiservice.getEndUserDetails(),
        headers
      );

      if (response) {
        dispatch(setUserProfile(response))
      }
    } catch (error) {
      console.log('error loading profile ', error)
    }
  }

  const handleSubmit = async () => {
    try {
      let data = {};
      setLoading(true);
      if (email && username) {
        data = {
          sessionInfo: sessionInfo,
          phoneNumber: phone,
          code: otpValue,
          email: email,
          userName: username,
          ageId: age,
          tagId: prof,
        };
      } else {
        data = {
          sessionInfo: sessionInfo,
          phoneNumber: phone,
          code: otpValue,
        };
      }
      const response = await postRequest(loginServices.studentLogin(), data);
      if (response) {
        const AGEname =
          ageGroups?.find((ageGroup) => ageGroup.id === age)?.ageGroup || "";
        setLocalAge(AGEname, age);
        if (!localStorage.getItem("profID")) {
          localStorage.setItem("profID", prof);
        }
        const name =
          ageGroupData?.find((tagObj) => tagObj?.tag?.id === prof)?.tag
            ?.userTag || "";
        if (!localStorage.getItem("prof")) {
          localStorage.setItem("prof", name);
        }
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refresh", response.refreshToken);
        getProfilePic(response.accessToken)
        toast.success(<ToastComponent type={'Success'} message={'Successfully logged in!'} />)


        try {
          const updatedDetails = {
            ...endUserDetails,
            endUserDetailsInput: {
              ...endUserDetails.endUserDetailsInput,
              tagId: localStorage.getItem("profID"),
              ageId: localStorage.getItem("ageID"),
            },
          };

          setEndUserDetails(updatedDetails);

          const formData = new FormData();
          const endUserDetailsInputBlob = new Blob(
            [JSON.stringify(updatedDetails.endUserDetailsInput)],
            { type: "application/json" }
          );
          formData.append("endUserDetailsInput", endUserDetailsInputBlob);

          const updateResponse = await putRequest(
            userapiservice.getEndUserDetails(),
            formData,
            response.accessToken
          );
        } catch (updateError) {
          console.error("Error updating end user details:", updateError);
        }

        if (!response.accessToken) {
          localStorage.removeItem("Phone Number");
        }
        if (page === "microsite") {
          navigate(`/microsite/${name}`, { replace: true });
        } else if (page === "partner") {
          navigate(`/`);
        } else if (page === "view") {
          navigate(`/microsite`);
        } else {
          navigate(`/microsite`);
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      setPartnerLogo(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const BackButtonHandler = () => {
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("Phone Number");
    if (page === "home") {
      navigate(`/home`);
    } else if (page === "partner") {
      navigate(`/`);
    } else if (page === "view") {
      navigate(`/view`);
    } else {
      navigate(`/ microsite / ${name} `, { replace: true });
    }
  };

  useEffect(() => {
    getPartnerLogo();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          return response;
        },
      },
      firebaseAuth
    );
  }, []);

  const getAgeGroups = async () => {
    try {
      const response = await getRequest(userapiservice.getEndUserAgeGroups());
      setAgeGroups(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeData = async (value) => {
    try {
      const response = await getRequest(userapiservice.getWhatDoYouDo(value));
      if (response) {
        setAgeGroupsData(response);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getAgeGroups();
    if (localStorage.getItem("ageID")) {
      getAgeData(localStorage.getItem("ageID"));
    }
  }, []);

  const setLocalAge = (value, id) => {
    if (value === "< 18") {
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 18);
    } else if (value === "19 - 23") {
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 23);
    } else if (value === "24 - 37") {
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 37);
    } else if (value === "38 - 53") {
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    } else if (value === "54+") {
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    }
  };

  return (
    <div className="login-wrapper bg-white ">
      <div className="login-card-container">
        <h3 className="login-welcome">Welcome</h3>
        <div className="logo">
          {partnerLogo?.mediaLink && (
            <img src={partnerLogo?.mediaLink} width={206} alt="Partner Logo" />
          )}
        </div>
        {showLogin ? (
          phoneRegistered ? (
            <div className="inputContainer">
              <div className="textInput">
                <Form
                  name="userRegistrationForm"
                  onFinish={handleLoginSubmit}
                  requiredMark={false}
                  autoComplete="off"
                  className="form-container"
                >
                  <div className="ant-form-item">
                    <Select
                      className="user-registration-select"
                      classNamePrefix="user-registration-select"
                      value={selectedCountry || defaultSelectedCountry}
                      onChange={(value) => setSelectedCountry(value)}
                      options={countryOptions}
                      placeholder="Select Country"
                    />
                  </div>
                  <Form.Item
                    name="phoneNumber"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mobile number!",
                      },
                      {
                        validator(_, value) {
                          if (value.toString().length <= 12)
                            return Promise.resolve();
                          return Promise.reject(
                            new Error("Invalid mobile number")
                          );
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={() => {
                        setPhoneFormat(true);
                      }}
                      maxLength={12}
                      min={0}
                      style={{ width: "100%" }}
                      placeholder="Mobile number"
                    // className=" "
                    />
                  </Form.Item>
                  {phoneFormat ? (
                    <p style={{ marginTop: "-20px", color: "grey" }}>
                      Use the format "7012341234" without prefixing 0
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-center">
                    <button
                      id="submit-btn"
                      type="submit"
                      disabled={loading}
                      className="btn btn-danger form-submit-btn get-opt-btn d-flex justify-content-center align-items-center "
                    >

                      {loading && (
                        <LoadingOutlined style={{ color: "white", padding: '5px' }} />
                      )}
                      <span>Next</span>
                    </button>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#333",
                    }}
                  >
                    {"< 18 years users need not login"}
                  </div>

                </Form>
              </div>
            </div>
          ) : (
            <div className="inputContainer">
              <div className="textInput">
                <Form
                  name="userRegistrationForm"
                  onFinish={handleRegisterSubmit}
                  requiredMark={false}
                  autoComplete="off"
                  className="form-container"
                >
                  <div className="ant-form-item">
                    <Select
                      className="user-registration-select"
                      classNamePrefix="user-registration-select"
                      value={selectedCountry || defaultSelectedCountry}
                      onChange={(value) => setSelectedCountry(value)}
                      options={countryOptions}
                      placeholder="Select Country"
                    />
                  </div>
                  <Form.Item
                    name="phoneNumber"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mobile number!",
                      },
                      {
                        validator(_, value) {
                          if (value.toString().length <= 12)
                            return Promise.resolve();
                          return Promise.reject(
                            new Error("Invalid mobile number")
                          );
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      onChange={() => {
                        setPhoneFormat(true);
                      }}
                      maxLength={12}
                      min={0}
                      style={{ width: "100%" }}
                      placeholder="Mobile number"
                      className=" "
                    />
                  </Form.Item>
                  {phoneFormat ? (
                    <p className="phoneFormat">
                      Use the format "7012341234" without prefixing 0                    </p>
                  ) : (
                    ""
                  )}
                  <Form.Item
                    name="email"
                    validateFirst={true}
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Enter valid email" },
                    ]}
                  >
                    <Input placeholder="Email" className=" " />
                  </Form.Item>
                  <Form.Item
                    name="userName"
                    validateFirst={true}
                    rules={[
                      { required: true, message: "Username is required" },
                    ]}
                  >
                    <Input placeholder="Name" className=" " />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    className="age-form"
                    initialValue={
                      localStorage.getItem("ageID")
                        ? localStorage.getItem("ageID")
                        : ""
                    }
                    rules={[{ required: true, message: "Age is required" }]}
                    name="ageId"
                  >
                    <Radio.Group
                      className="age-radio"
                      buttonStyle="solid"

                    >
                      {ageGroups && ageGroups.length > 0
                        ? ageGroups.slice(1).map((ageGroup) => (
                          <Radio.Button
                            value={ageGroup?.id}
                            onClick={() => {
                              getAgeData(ageGroup?.id);
                              setSelectedAgeGroupsData(null);
                              setLocalAge(ageGroup.ageGroup, ageGroup.id);
                            }}
                            onChange={() => {
                              setSelectedAgeGroupsData("");
                            }}
                            style={{ display: 'flex', justifyContent: 'center', minWidth: '25px', whiteSpace: 'nowrap', margin: "0 2px" }}
                          >
                            {ageGroup?.ageGroup}
                          </Radio.Button>
                        ))
                        : ""}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    // initialValue={
                    //   localStorage.getItem("profID")
                    //   ? localStorage.getItem("profID")
                    //   : selectedAgeGroupsData
                    // }
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "What do you do? is required",
                      },
                    ]}
                    labelCol={{ span: window.innerWidth > 380 ? 4 : 7 }}
                    name="tagId"
                  >
                    <AntSelect
                      // className="customSelect"
                      className=" "
                      value={selectedAgeGroupsData || ''}
                      placeholder="What do you do?"
                      onChange={(e) => setSelectedAgeGroupsData(e)}
                    >
                      {ageGroupData &&
                        ageGroupData?.map((ageGroupData, i) => (
                          <AntSelect.Option
                            key={ageGroupData.tag.userTag}
                            value={ageGroupData.tag.id}
                          >
                            {ageGroupData.tag.userTag}
                          </AntSelect.Option>
                        ))}
                    </AntSelect>
                  </Form.Item>
                  <div className="d-flex justify-content-center">
                    <button
                      id="submit-btn"
                      type="submit"
                      disabled={loading}
                      className="btn btn-danger form-submit-btn get-opt-btn d-flex justify-content-center align-items-center "
                    >

                      {loading && (
                        <LoadingOutlined style={{ color: "white", padding: '5px' }} />
                      )}
                      <span>Next</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          )
        ) : (
          <div className="inputContainer">
            <div className="verify-heading">
              <p className="regular-text">{`Please enter the One Time Password(OTP) sent to ${phone} `}</p>
            </div>
            {error && (
              <div className="verify-error">
                <div style={{ padding: "0.5em" }}>
                  Authentication Failed! Please Retry
                </div>
              </div>
            )}
            <div className="verify-input">
              <div className="OTP-form">
                <OtpInput
                  className="otpFields"
                  shouldAutoFocus={true}
                  value={otpValue}
                  onChange={setOtpValue}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <button
                className="btn btn-danger get-opt-btn verify-now-btn "
                type="button"
                onClick={() => handleSubmit()}
              // disabled={loading || otpValue.length !== 6}
              >
                Verify Now
              </button>

            </div>

          </div>
        )}

        <div className="spacer"></div>
        <div id="recaptcha" style={{ display: "none" }}></div>
      </div>
    </div>
  );
}
