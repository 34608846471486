import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Nav() {
  const navigate = useNavigate();
  // const [partner, setPartner] = useState();

  // useEffect(() => {
  //     const partner = localStorage.getItem("partner");
  //     setPartner(partner);
  // })

  // const getPartnerNameFromCache = async (partnerName) => {
  //     try {
  //         const cache = await caches.open('partnerCache');
  //         const response = await cache.match('/frontend/' + partnerName);

  //         if (response) {
  //             const cachedData = await response.text();
  //             return cachedData;
  //         }

  //         return null; // Return null if no cached data found
  //     } catch (error) {
  //         console.error('Error retrieving data from cache:', error);
  //         return null;
  //     }
  // };

  // useEffect(() => {
  //     const fetchData = async () => {
  //         const data = await getPartnerNameFromCache('New%20Partner');
  //         console.log("cached", data);
  //     };

  //     fetchData();
  // }, []);

  useEffect(() => {
    navigate(`/frontend/Partner_Name`);
  }, []);

  // return (
  //     <p>Partner:{partner}</p>
  // )
}
