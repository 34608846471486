import React, { useEffect, useState } from "react";
import { Form, Button, Card, Spin, Input } from "antd";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import userServiceApi from "../../api/userapiservice";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import ytPlayBtn from "../../assets/newImages/Play.svg";
import "./Help.scss";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import SearchBarHelp from "./searchHelp";
import { SearchOutlined } from "@ant-design/icons";
import TitleBar from "../../components/TitleBar/TitleBar";
import ToastComponent from "../../components/ToastCompoent/ToastComponent";

function Help() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpTabName, setHelpTabName] = useState([]);
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    let objKeys = {}
    data.map(ele => objKeys[ele.assetTypeEnum] = 1)

    setHelpTabName(Object.keys(objKeys));
  }, [data]);

  console.log('data', data)

  const tabName = (tab) => {
    if (tab === "BUSINESS_CARD") {
      return "Business card";
    } else if (tab === "CAROUSEL") {
      return "Corousel";
    } else if (tab === "ABOUT_US") {
      return "About us";
    } else if (tab === "PRODUCT") {
      return "Product";
    } else if (tab === "CONTRACTS") {
      return "Contract";
    } else if (tab === "SERVICE") {
      return "Service";
    } else if (tab === "APPRENTICESHIP") {
      return "Apprenticeship";
    } else if (tab === "VACANCY") {
      return "Vacancy";
    } else if (tab === "COURSES") {
      return "Courses";
    } else if (tab === "WHY_WORK") {
      return "Why work for us";
    } else if (tab === "TEAMS_AND_ROLES") {
      return "Teams and roles";
    } else if (tab === "WORK_EXPERIENCE") {
      return "Experience of work";
    } else if (tab === "FULL_MEMBERS") {
      return "Full Members";
    } else if (tab === "HUB_MEMBERS") {
      return "Hub Members";
    } else if (tab === "SPONSOR_LOGOS") {
      return "Sponsor Logos";
    } else if (tab === "ENGAGEMENT_TAB") {
      return "Engagement Tab";
    } else if (tab === "RESTORE_MEMBERS") {
      return "Restore Members";
    } else if (tab === "HOME") {
      return "Home";
    } else if (tab === "FAVORITES") {
      return "Favorites";
    } else if (tab === "ORGANIZATION_LOGIN") {
      return "+ Organization";
    } else if (tab === "EDIT_PROFILE") {
      return "Edit Profile";
    } else if (tab === "MICROSITES") {
      return "Microsites";
    } else if (tab === "USER_LOGIN") {
      return "User Login";
    } else if (tab === "SEARCH_AND_FILTERS") {
      return "Search and Filters";
    } else if (tab === "VIEWS") {
      return "Views";
    }
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const getHelpData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(searchTerm === '' ? userServiceApi.help() : userServiceApi.getFilteredHelpInfo("END_USER", searchTerm), { headers });
      if (response) {
        setData(response);
      }
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || error?.message || 'Sorry, Something went wrong'} />)

    }
    finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const handler = setTimeout(() => {
      getHelpData();
    }, 500);
    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm]);

  console.log('helpTabName', helpTabName)

  return (
    <div className="help-wrapper bg-white" >
      {/* place title bar outside to prevent scrollbar at title level */}
      <TitleBar title='Help' search setSearchTerm={setSearchTerm} searchTerm={searchTerm} searchPlaceholder="Search Help" />
      <div className=" help-container ">
        {(!loading && helpTabName.length > 0) ? helpTabName.map((tabNames, i) => (
          <div className="card-container-help">
            <div
              key={i}
              style={{ display: "flex", flexDirection: "column", width: '100%' }}
            >
              <div className="title-help">{tabName(tabNames)}</div>
              <div className="org-card-container-help">
                {data && data.length > 0
                  ? data.map((datas) =>
                    datas?.assetTypeEnum === tabNames ? (
                      <>
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            duration: 0.8,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01],
                          }}
                        >
                          <Card
                            className="help-page-card-item  "
                            key={datas.id}
                            bordered={false}
                          >
                            <div
                              className="help-text-container"
                            >
                              <span
                                className="help-card-title"
                              >
                                {datas.title}
                              </span>
                              <div className="help-card-description">
                                <span
                                  className="datas-details text-bold"
                                >
                                  {datas.description}
                                </span>
                              </div>
                              {datas?.webLink ? (
                                <a
                                  className="span-km"
                                  href={datas?.webLink}
                                  target="_blank"
                                  style={{color:"#3067AE"}}
                                >
                                  Know More
                                </a>
                              ) : null}
                            </div>
                            <div className="image-container"  >
                              {datas.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                                datas.mediaLink?.url ? (
                                <>
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      datas.mediaLink.url
                                    );
                                    const videoID = standardURL
                                      .split("v=")[1]
                                      ?.split("&")[0];
                                    return (
                                      <div
                                        className="media youtube"
                                        style={{ objectFit: "contain" }}
                                        onClick={() =>
                                          PlayVideoHandler(
                                            datas.mediaLink?.url,
                                            datas.mediaLink?.mediaType
                                          )
                                        }
                                      >
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          alt=""
                                          className="yt-video-preview-image video-preview-image"
                                        />
                                        <img
                                          className="ytBtnOverlay"
                                          src={ytPlayBtn}
                                        />
                                      </div>
                                    );
                                  })()}
                                </>
                              ) : datas.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                                datas.mediaLink?.url ? (
                                <div
                                  className="media vimeo youtube"
                                  onClick={() => {
                                    PlayVideoHandler(
                                      datas.mediaLink?.url,
                                      datas.mediaLink?.mediaType
                                    );
                                  }}
                                >
                                  <img
                                    src={`https://vumbnail.com/${datas.mediaLink?.url?.split("/")[4]
                                      }.jpg`}
                                    className="vimeo-video-preview-image video-preview-image"

                                  />
                                  <img className="ytBtnOverlay" src={ytPlayBtn} />
                                </div>
                              ) : (
                                <div className="media" onClick={() => {
                                  if (datas?.webLink) { window.open(datas?.webLink, "_blank") }
                                }
                                } >
                                  <div className="slider-Img">
                                    <img
                                      src={datas.mediaLink?.url}
                                      alt="image"
                                      className="home-preview-img"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="connectsu-line-help" />
                          </Card>
                        </motion.div>
                      </>
                    ) : null
                  )
                  : null}
              </div>
            </div>
          </div>
        )) : null}


        {!loading && helpTabName.length === 0 ? <div style={{
          fontWeight: 'bold',
          marginTop: '20px',
          marginBottom: '10px',
          fontSize: '15px',
          textAlign: 'center'
        }} > No Data </div> : null}
        {loading && (
          <div className="no-data-warning d-flex justify-content-center" style={{ height: '100% !important' }} >
            <Spin tip="Loading" size="large" />
          </div>
        )}

        {isModalOpen ? (
          <ModalPlayer
            closeModal={handleCancel}
            mediaLink={videoUrl}
            mediaType={mediatype}
          />
        ) : (
          ""
        )}
      </div>
    </div>

  );
}

export default Help;
