import React, { useState, useEffect, useRef } from "react";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
  ZoomControl,
  Tooltip,
} from "react-leaflet";
import { Button } from "antd";
import "./MapComponent.scss";
import L, { icon, map, marker } from "leaflet";
import { useParams } from "react-router-dom";
import locateMe from "../../assets/images/locateMe.png";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

function MyComponent({ ZoomValueHandler, radius, showAndHideTooltips }) {
  const map = useMap();
  const mapEvents = useMapEvents({
    zoomend: () => {
      // ZoomValueHandler(mapEvents.getZoom());
      // const currentZoom = map.getZoom();
       const currentZoom = map.getZoom();

      if (currentZoom >= 10) {
        showAndHideTooltips(true); // Show tooltips when zoom is 10 or higher
      } else {
        showAndHideTooltips(false); // Hide tooltips when zoom is below 10
      }
    },
    zoom: (e) => {
      localStorage.setItem("zoom", mapEvents.getZoom());
      localStorage.setItem("lng", e.target.getCenter().lng);
      localStorage.setItem("lat", e.target.getCenter().lat);
      console.log("getZom", mapEvents.getZoom());
      // if(map.getZoom() > 10)
      // {
      //   showAndHideTooltips(true)
      // } else if(map.getZoom() < 10){
      //   showAndHideTooltips(false)
      // }
    },
    dragend: (e) => {
      localStorage.setItem("lng", e.target.getCenter().lng);
      localStorage.setItem("lat", e.target.getCenter().lat);
    },
  });

  mapEvents.setZoom(radius);

  return null;
}

export default function MapComponent({
  radiusValue,
  ChangeRadiusHandler,
  setMarkerData,
  markerData,
  interest,
  sector,
  subsector,
  sizeId,
  selectedOrg,
  activeMiniPopup,
  allowSetView = false,
  disableSetView,
}) {
  const [position, setPosition] = useState();
  const [mapMarkers, setMapMarkers] = useState(markerData);
  const [radius, setRadius] = useState(2);
  const { id } = useParams();
  const partner = localStorage.getItem("partner");
  const popupRef = useRef(null);
  const markerRefs = useRef({});
  const mapRef = useRef({});
  const partnerLongitude = localStorage.getItem("partner-longitude");
  const partnerLatitude = localStorage.getItem("partner-latitude");
  const [flyTo, setFlyTo] = useState(false);
  const [outsideClick,setOutsideClick]=useState('')

  const [toggleLocation, setToggleLocation] = useState(false);
  const [location, setLocation] = useState(null);

  const fixedCoordinates = [partnerLatitude, partnerLongitude];
  const [activeToolTip, setActiveToolTip] = useState(false);
  const [mapZoom, setMapZoom] = useState(9);

  const SearchComponent = () => {
    const map = useMap();
    const [showSearchedMarker, setShowSearchedMarker] = useState(true);

    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider,
        style: "style", // Search bar style
        showMarker: false,
        showPopup: true,
        autoClose: false, // Keep the result open
        keepResult: true, // Keep the search input value
        notFoundMessage: "Sorry, that address could not be found.",
        marker: {
          icon: L.icon({
            iconSize: [25, 41],
            iconAnchor: [10, 41],
            popupAnchor: [2, -40],
            iconUrl: true
              ? "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png"
              : "",
            // shadowUrl:"https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
          }),
        },

        // marker:false
      });

      // Add search control to the map
      map.addControl(searchControl);

      // Select the input field and cancel button
      const searchInput = document.querySelector(
        ".leaflet-control-geosearch input"
      );
      const cancelButton = document.querySelector(
        ".leaflet-control-geosearch button"
      );

      // Reset map when input is cleared or cancel is clicked
      const resetMap = () => {
        if (searchInput.value === "") {
          // setCoordinates(fixedCoordinates);
          setShowSearchedMarker(false);
          map.setView(fixedCoordinates, 6); // Reset map view
        }
      };

      searchInput?.addEventListener("input", resetMap);
      cancelButton?.addEventListener("click", resetMap);

      map.on("geosearch/showlocation", (event) => {
        if (event.location && searchInput) {
          searchInput.value = event.location.label; // Update the search input with the result label
        }
      });

      // return () => {
      //   map.removeControl(searchControl);
      // };

      return () => {
        map.removeControl(searchControl);
        searchInput?.removeEventListener("input", resetMap);
        cancelButton?.removeEventListener("click", resetMap);
      };
    }, [map]);

    return null;
  };

  const MapEventHandler = () => {
    useMapEvents({
      click: (e) => {
        // Check if the click is outside any open popup or marker
        const popupEl = popupRef.current;
        if (popupEl && !popupEl.contains(e.originalEvent.target)) {
          selectedOrg({});
          console.log('outsideClick')
          setOutsideClick(true)
        }
      },
    });
    return null;
  };
  console.log("radius", radius);
  const getPartnerLocation = async () => {
    if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
      const latitude = localStorage.getItem("lat");
      const longitude = localStorage.getItem("lng");
      setPosition([latitude, longitude]);
    } else {
      try {
        const response = await getRequest(
          userapiservice.getPartnerDetails(partner)
        );

        const latitude = response.latitude;
        const longitude = response.longitude;
        setPosition([latitude, longitude]);
        localStorage.setItem("lat", latitude);
        localStorage.setItem("lng", longitude);
      } catch (error) {}
    }
  };

  const mapMarkersDataUpdate = () => {
    let copyOfMarkers = [...mapMarkers];
    copyOfMarkers = copyOfMarkers.map((item) => {
      return {
        ...item,
        showTooltip: true,
      };
    });

    console.log("copy", copyOfMarkers);
    setMapMarkers(copyOfMarkers);
    setActiveToolTip(true);
  };

  const toolTipToRender = () => {
    console.log("tooltip", activeToolTip ? true : false);
    return activeToolTip ? true : false;
  };

  const showAndHideTooltips = (show = false) => {
    console.log("markerref", markerRefs?.current, show);
    Object.values(markerRefs.current).forEach((markerRef) => {
      if (markerRef) {
        console.log('markerRef',markerRef)
        if (show) {
          markerRef?.openTooltip();
          console.log('check if')
        } else {
          markerRef?.closeTooltip();
          console.log('check else')

        }
      }
    });
  };

  // Function to hide all tooltips
  const hideAllTooltips = () => {
    Object.values(markerRefs.current).forEach((markerRef) => {
      if (markerRef) {
        markerRef.closeTooltip(); // Close tooltip for each marker
      }
    });
  };

  const handleLocate = () => {
    console.log("handleLocate");
    if (toggleLocation) {
      setLocation(fixedCoordinates); // Set to fixed location
      setFlyTo(false);
      showAndHideTooltips(false);
    } else {
      showAndHideTooltips(false);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation([position.coords.latitude, position.coords.longitude]);
        },
        () => {
          // alert(
          //   "Unable to fetch your location. Falling back to fixed coordinates."
          // );
          setLocation(fixedCoordinates); // Fallback if location fetch fails
        }
      );
      setFlyTo(true);
    }
    setToggleLocation(!toggleLocation); // Toggle the state
    setOutsideClick(false)
  };

  const LocationMarker = ({ targetLocation }) => {
    const map = useMap();

    useEffect(() => {
      console.log('i am here')
      if (targetLocation) {
        console.log('targetLocation',targetLocation)
        if(outsideClick) {
          console.log('outside 276')
          return
        } else {
          console.log('outside   279')

          map.flyTo(targetLocation, flyTo ? 13 : 6);

        }
      }
    }, [targetLocation, map]);

    return (
      targetLocation && (
        <>
          {flyTo ? (
            <Marker
              position={targetLocation}
              icon={L.icon({
                iconSize: [25, 41],
                iconAnchor: [10, 41],
                popupAnchor: [2, -40],
                iconUrl:
                  "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
                shadowUrl:
                  "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
              })}
            >
              <Popup>You are here</Popup>
            </Marker>
          ) : (
            ""
          )}
        </>
      )
    );
  };

  const ZoomValueHandler = (value) => {
    setRadius(value);
    // if (radiusValue !== value) {
    //   // getLocation();
    // }
    ChangeRadiusHandler(value);
  };

  useEffect(() => {
    if (radiusValue !== radius) {
      setRadius(radiusValue);
    }
    console.log("radiusValue", radiusValue);
  }, [radiusValue]);

  useEffect(() => {
    // setMapMarkers([]);

    let tempMapMarkers = [];
    markerData.forEach((element, elementIndex) => {
      if (element.addressOutputs && element.addressOutputs.length > 0) {
        element.addressOutputs.forEach((item, index) => {
          tempMapMarkers.push({
            ...item,
            index: elementIndex,
            name: element.name,
            addressOutputs: [element.addressOutputs[index]],
            sectorsOutputs: element.sectorsOutputs,
            carouselLink: element.carouselLink,
            micrositeLogo: element.micrositeLogo,
            businessDescription: element.businessDescription,
            pinColor: "Pink",
            showTooltip: false,
          });
        });
      }
    });

    const arrayWithNoDuplicates = tempMapMarkers.filter(
      (business, index, self) =>
        index ===
        self.findIndex(
          (b) =>
            b.name === business.name &&
            b.carouselLink.url === business.carouselLink.url
        )
    );

    if (tempMapMarkers.length > 0) {
      setMapMarkers(tempMapMarkers);
    } else {
      setMapMarkers([]);
    }

    // console.log("marker", markerData, markerData.length)
    // if (markerData.length == 1) {
    //   console.log("coming here", markerData[0]?.addressOutputs[0])
    //   localStorage.setItem("lng", markerData[0]?.addressOutputs[0]?.longitude)
    //   localStorage.setItem("lat", markerData[0]?.addressOutputs[0]?.latitude)
    //   // setPosition([markerData[0]?.addressOutputs[0]?.countryOutput?.latitude, markerData[0]?.addressOutputs[0]?.countryOutput?.longitude])
    // }
  }, [markerData]);

  useEffect(() => {
    getPartnerLocation();
  }, []);

  useEffect(() => {
    /* to close the open mini popup */
    if (activeMiniPopup?.closePopup) {
      // Object.values(markerRefs.current).forEach((marker) => {
      //   if (marker && typeof marker.closePopup === "function") {
      //     marker.closePopup();
      //   }
      // });

      const map = mapRef?.current;
      if (map && typeof map?.closePopup === "function") {
        map?.closePopup();
      }
    }
  }, [activeMiniPopup]);

  const markerOpenPopup = () => {
    if (mapMarkers?.length > 0 && activeMiniPopup?.addressOutputs?.length) {
      const matchingMarker = mapMarkers?.findIndex((item) =>
        item?.addressOutputs?.find(
          (output) => output?.id === activeMiniPopup?.addressOutputs[0]?.id
        )
      );

      const activeMarkerKey = `${mapMarkers[matchingMarker]?.addressOutputs[0]?.latitude}-${mapMarkers[matchingMarker]?.addressOutputs[0]?.longitude}`;
      const activeMarker = markerRefs?.current[activeMarkerKey];

      if (activeMarker) {
        activeMarker?.openPopup();
      }
    }
  };

  const getMarkerBySector = (sector) => {
    // console.log("getMarkersector", sector)
    switch (sector) {
      case "69f01e0d-8162-4685-84fa-f69a33de3b1c":
        // return "../../assets/images/MapMarkers/1.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
             <defs>
               <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
                 <feOffset dy="2" input="SourceAlpha" />
                 <feGaussianBlur stdDeviation="1.5" result="blur" />
                 <feFlood flood-opacity="0.329" />
                 <feComposite operator="in" in2="blur" />
                 <feComposite in="SourceGraphic" />
               </filter>
               <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
                 <feOffset dy="2" input="SourceAlpha" />
                 <feGaussianBlur stdDeviation="2" result="blur-2" />
                 <feFlood flood-opacity="0.329" />
                 <feComposite operator="in" in2="blur-2" />
                 <feComposite in="SourceGraphic" />
               </filter>
             </defs>
             <g id="_1" data-name="1" transform="translate(-1115 -490.574)">
               <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
                 <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd" />
               </g>
               <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
                 <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#edfa3b" fill-rule="evenodd" />
               </g>
             </g>
           </svg>`;

      case "3f0dfaf5-9ab9-42d3-a9f3-8c4521339f1c":
        // return "../../assets/images/MapMarkers/2.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_2" data-name="2" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f1e71f" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "0b5d2f45-1efb-448b-a397-46427afe2f67":
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_3" data-name="3" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#ebbe44" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "90ed42f3-366c-426b-81d0-3da6be23d598":
        // return "../../assets/images/MapMarkers/4.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_4" data-name="4" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f48d5b" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "0bf8e994-804b-4a40-855a-269970aa0e22":
        // return "../../assets/images/MapMarkers/5.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_5" data-name="5" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f04939" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "da2d026c-2767-4ac0-b09e-16f336a46421":
        // return "../../assets/images/MapMarkers/6.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_6" data-name="6" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#ea2730" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "7a91ba9f-b610-4c49-b919-1f7e1535e5dd":
        // return "../../assets/images/MapMarkers/7.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_7" data-name="7" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#a71795" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "db4bb640-7268-4825-a435-ceed615b8af3":
        // return "../../assets/images/MapMarkers/8.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_8" data-name="8" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f659a3" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "d3af87d2-eb9a-4fbc-8767-e3873fec5e45":
        // return "../../assets/images/MapMarkers/9.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_9" data-name="9" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f3ab9d" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "184fd08f-6837-4f74-98fb-08d52e6264f6":
        // return "../../assets/images/MapMarkers/10.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_10" data-name="10" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#914ed0" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "37e63bc6-11a4-4ecd-be2b-195ff45ad70d":
        // return "../../assets/images/MapMarkers/11.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_11" data-name="11" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#5d0c9c" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "bf04ef20-22e5-44d8-abbe-6aa5bf75e624":
        // return "../../assets/images/MapMarkers/12.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_12" data-name="12" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#370797" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "56998fb8-7ced-4a4c-9cf6-7f6273f4c9ab":
        // return "../../assets/images/MapMarkers/13.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_13" data-name="13" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4d6ee4" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "c3e6ee71-de01-4c57-8580-f9fed24ad9d7":
        // return "../../assets/images/MapMarkers/14.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_14" data-name="14" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#749bf7" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "a657a85f-c5db-4914-9c80-67f00e48fcce":
        // return "../../assets/images/MapMarkers/15.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_15" data-name="15" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#302084" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "36194b30-e1af-4ed2-ad74-90c44d23b5bd":
        // return "../../assets/images/MapMarkers/16.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_16" data-name="16" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4dd039" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "cf300dde-5d37-4b6e-a37d-47a93751d055":
        // return "../../assets/images/MapMarkers/17.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_17" data-name="17" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4ee8a2" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case "5f13c24f-0e96-4e68-9a7c-f53366808265":
        // return "../../assets/images/MapMarkers/18.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_18" data-name="18" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#018c48" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      default:
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_24" data-name="24" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#332007" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;
    }
  };

  const getMarkerByIndex = (index) => {
    const select = index % 17;
    switch (select) {
      case 0:
        // return "../../assets/images/MapMarkers/1.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
             <defs>
               <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
                 <feOffset dy="2" input="SourceAlpha" />
                 <feGaussianBlur stdDeviation="1.5" result="blur" />
                 <feFlood flood-opacity="0.329" />
                 <feComposite operator="in" in2="blur" />
                 <feComposite in="SourceGraphic" />
               </filter>
               <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
                 <feOffset dy="2" input="SourceAlpha" />
                 <feGaussianBlur stdDeviation="2" result="blur-2" />
                 <feFlood flood-opacity="0.329" />
                 <feComposite operator="in" in2="blur-2" />
                 <feComposite in="SourceGraphic" />
               </filter>
             </defs>
             <g id="_1" data-name="1" transform="translate(-1115 -490.574)">
               <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
                 <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd" />
               </g>
               <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
                 <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#edfa3b" fill-rule="evenodd" />
               </g>
             </g>
           </svg>`;

      case 1:
        // return "../../assets/images/MapMarkers/2.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_2" data-name="2" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f1e71f" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 2:
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_3" data-name="3" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#ebbe44" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 3:
        // return "../../assets/images/MapMarkers/4.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_4" data-name="4" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f48d5b" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 4:
        // return "../../assets/images/MapMarkers/5.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_5" data-name="5" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f04939" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 5:
        // return "../../assets/images/MapMarkers/6.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.853">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.853" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_6" data-name="6" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#6faa0f" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 6:
        // return "../../assets/images/MapMarkers/7.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_7" data-name="7" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#a71795" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 7:
        // return "../../assets/images/MapMarkers/8.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_8" data-name="8" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f659a3" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 8:
        // return "../../assets/images/MapMarkers/9.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_9" data-name="9" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#f3ab9d" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 9:
        // return "../../assets/images/MapMarkers/10.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_10" data-name="10" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#914ed0" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 10:
        // return "../../assets/images/MapMarkers/11.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_11" data-name="11" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#5d0c9c" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 11:
        // return "../../assets/images/MapMarkers/12.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_12" data-name="12" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#370797" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 12:
        // return "../../assets/images/MapMarkers/13.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_13" data-name="13" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4d6ee4" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 13:
        // return "../../assets/images/MapMarkers/14.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_14" data-name="14" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#749bf7" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 14:
        // return "../../assets/images/MapMarkers/15.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_15" data-name="15" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#302084" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 15:
        // return "../../assets/images/MapMarkers/16.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_16" data-name="16" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4dd039" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 16:
        // return "../../assets/images/MapMarkers/17.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_17" data-name="17" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#4ee8a2" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      case 17:
        // return "../../assets/images/MapMarkers/18.svg";
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_18" data-name="18" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#018c48" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;

      default:
        return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="50" height="55" viewBox="0 0 26.106 30.852">
        <defs>
          <filter id="Map_pin_icon" x="1.5" y="1.5" width="23.106" height="27.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="1.5" result="blur"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Map_pin_icon-2" x="0" y="0" width="26.106" height="30.852" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur-2"/>
            <feFlood flood-opacity="0.329"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="_24" data-name="24" transform="translate(-1115 -490.574)">
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon)">
            <path id="Map_pin_icon-3" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Z" transform="translate(-1339.77 299.23)" fill="#fff" fill-rule="evenodd"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 1115, 490.57)" filter="url(#Map_pin_icon-2)">
            <path id="Map_pin_icon-4" data-name="Map pin icon" d="M1352.82-276.378c-.219-.29-.43-.567-.639-.847l-5-6.709a6.8,6.8,0,0,1-1.4-3.9,6.824,6.824,0,0,1,2.987-6.1,7.022,7.022,0,0,1,5.3-1.187,7.022,7.022,0,0,1,4.561,2.945,6.9,6.9,0,0,1,1.238,4.274,7.066,7.066,0,0,1-1.48,4.076q-2.212,2.949-4.407,5.911-.534.717-1.07,1.433A.862.862,0,0,1,1352.82-276.378Zm-.023-8.442a3.349,3.349,0,0,0,3.378-3.282,3.352,3.352,0,0,0-.947-2.4,3.352,3.352,0,0,0-2.375-1.016,3.355,3.355,0,0,0-3.378,3.285,3.343,3.343,0,0,0,.947,2.4,3.343,3.343,0,0,0,2.378,1.011Z" transform="translate(-1339.77 299.23)" fill="#332007" fill-rule="evenodd"/>
          </g>
        </g>
      </svg>
      `;
    }
  };

  function SetViewOnMarker({ markers }) {
    const map = useMap();

    useEffect(() => {
      const latitude = mapMarkers[0]?.addressOutputs[0]?.latitude;
      const longitude = mapMarkers[0]?.addressOutputs[0]?.longitude;

      if (markers?.length === 1) {
        map.setView([latitude, longitude], localStorage.getItem("zoom"));
      } else if (markerData?.length > 1) {
        map.setView(
          [partnerLatitude, partnerLongitude],
          localStorage.getItem("zoom")
        );
      }
      disableSetView();
    }, []);

    return null;
  }

  const onMarkerPinClick = (index) => {
    let copyOfMarkers = [...mapMarkers];
    copyOfMarkers[index] = {
      ...copyOfMarkers[index],
      showTooltip: true,
    };
    setActiveToolTip(true);
    setMapMarkers(copyOfMarkers);
  };

  console.log("map", mapMarkers);


  return (
    <div>
      {position && markerData ? (
        <MapContainer
          id="map-container"
          className="map-container"
          ref={mapRef}
          center={position}
          zoom={
            localStorage.getItem("zoom") ? localStorage.getItem("zoom") : 10
          }
          // zoom={getZoomLevel(distanceInMiles)}
          // center={bounds.getCenter()}
          scrollWheelZoom={true}
          zoomControl={false}
          ZoomValueHandler={ZoomValueHandler}
          radius={radius}
        >
          <SearchComponent />
          <MyComponent
            ZoomValueHandler={ZoomValueHandler}
            radius={radius}
            showAndHideTooltips={showAndHideTooltips}
          />
          <TileLayer
            attribution=""
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl className="zoom-control" position="topright" />
          <LocationMarker targetLocation={location} />
          {localStorage.getItem("country") === "United Kingdom" && (
          // {localStorage.getItem("country") === "India" && (
            // <Button onClick={() => setFlyTo(!flyTo) }   className="locateBtn leaflet-top leaflet-right">
            <Button
              onClick={handleLocate}
              className="locateBtn leaflet-top leaflet-right"
            >
              <img className="locateMeImg" src={locateMe}></img>
            </Button>
          )}

          {mapMarkers?.length > 0 &&
            mapMarkers.map((data, index) => {
              const markerKey = `${data.addressOutputs[0]?.latitude}-${data.addressOutputs[0]?.longitude}`;

              return (
                <>
                  {console.log("mappppp", data?.showTooltip)}
                  <Marker
                    className="marker-pin"
                    key={`${data.addressOutputs[0]?.latitude}-${data.addressOutputs[0]?.longitude}`}
                    icon={
                      new L.divIcon({
                        // previously companies were color coded wrt sectors
                        // html: getMarkerBySector(data.sectorsOutputs[0]?.sectorOutput?.id),
                        html: getMarkerByIndex(data.index),
                        iconAnchor: [25, 55],
                        popupAnchor: [4, -40],
                      })
                    }
                    position={[
                      data.addressOutputs[0]?.latitude,
                      data.addressOutputs[0]?.longitude,
                    ]}
                    data={data}
                    eventHandlers={{
                      click: (e) => {
                        const businessCardPos =
                          e?.target._map.latLngToContainerPoint(e?.latlng);
                        selectedOrg(data, true, businessCardPos);
                        onMarkerPinClick(index);
                        setOutsideClick(true)
                        showAndHideTooltips(false)
                      },
                    }}
                    ref={(el) => {
                      if (el) {
                        markerRefs.current[markerKey] = el;
                      }
                    }}
                  >
                    <Tooltip
                      key={`${data.addressOutputs[0]?.latitude}-${data.addressOutputs[0]?.longitude}`}
                      direction="top"
                      offset={[0, -50]}
                      className="marker-tooltip"
                    >
                      <div
                        id="myElement"
                        className="tooltip-content"
                        ref={popupRef}
                      >
                        <img
                        className="image-in-tooltip"
                          src={data.micrositeLogo}
                          alt={data.name}
                        />
                      </div>
                    </Tooltip>
                  </Marker>
                </>
              );
            })}

          {mapMarkers?.length > 0 && allowSetView && (
            <SetViewOnMarker markers={mapMarkers} />
          )}

          <MapEventHandler />
        </MapContainer>
      ) : null}
      {/* {Object.keys(activeMiniPopup || {})?.length && markerOpenPopup()} */}
      {activeMiniPopup?.id && markerOpenPopup()}
    </div>
  );
}
