import { API_URL } from "./http";

const studentLogin = () => `${API_URL}/sign-in-with-phone-number`;
const getOtp = () => `${API_URL}/send-verification-code`;
const register = () => `${API_URL}/end-user/register`;


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  studentLogin,
  getOtp,
  register
};
