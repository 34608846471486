import React, { useEffect, useState, useRef } from "react";
import { Form, Select } from "antd";
import { Button, Switch, Radio, Modal, Input, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import TextArea from "antd/es/input/TextArea";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./OrgOnboarding.scss";
import TitleBar from "../../components/TitleBar/TitleBar";
import { Back } from "../../components/Icons/Icons";

export default function OnboardingPage() {
  const navigate = useNavigate();
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [formData, setFormData] = useState();
  const [countries, setCountries] = useState([]);
  const [formFilled, setFormFilled] = useState(false);
  const lastPage = localStorage.getItem("Page");
  const space = <Fragment>&nbsp&nbsp&nbsp&nbsp</Fragment>;
  // const partner = localStorage.getItem("partner");
  const [member, setMember] = useState(false);
  // const partnerID = localStorage.getItem("partnerId");
  // const [errorMessageVisible, setErrorMessageVisible] = useState(false)
  const [partner, setPartner] = useState(localStorage.getItem("partner"));
  const [partnerID, setPartnerID] = useState(localStorage.getItem("partnerId"));
  const [shortenedUrl, setShortenedUrl] = useState(null)
  const [orgSize,setOrgSize] = useState()

  const organisationSize = [
    {value:"MICRO",label:'Micro 0-9'},
    {value:"SMALL",label:'Small 10-49'},
    {value:"MEDIUM",label:'Medium 50-249'},
    {value:"LARGE",label:'Large 249+'},
]



  const radioGroupRef = useRef(null);

  const handlePrev = () => {
    if (radioGroupRef.current) {
      radioGroupRef.current.scrollLeft -= 150;
    }
  };

  const handleNext = () => {
    if (radioGroupRef.current) {
      radioGroupRef.current.scrollLeft += 150;
    }
  };

  const is1920 = useMediaQuery({ width: 1920 });
  const is1366 = useMediaQuery({ width: 1366 });
  const is1240 = useMediaQuery({ width: 1024 });
  const isMin1240 = useMediaQuery({ minWidth: 1024 })
  const is1680 = useMediaQuery({ width: 1680 });
  const is1600 = useMediaQuery({ width: 1600 });
  const is1280 = useMediaQuery({ width: 1280 });
  const is800 = useMediaQuery({ width: 800 });
  const is820 = useMediaQuery({ width: 820 });
  const isMin820 = useMediaQuery({ minWidth: 820 });
  const is768 = useMediaQuery({ width: 768 });
  const is320 = useMediaQuery({ width: 320 });
  const is375 = useMediaQuery({ width: 375 });
  const is412 = useMediaQuery({ width: 412 });
  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });
  const labelColSpan =
    is1366 || is1680 || is1600
      ? 6
      : is1920
        ? 8
        : is1240
          ? 7
          : is1280
            ? 8
            : is800
              ? 7
              : 8;

  const paddingTop = is1920
    ? "10px"
    : is1366
      ? "10px"
      : is320 || is1240
        ? "5px"
        : is375 || is820 || is412 || isSmallScreen
          ? "6px"
          : is768
            ? "4px"
            : "1px";

  const [allOrgTypes, setAllOrgTypes] = useState([]);

  const getOnOrganisationType = async () => {
    try {
      const response = await getRequest(
        userapiservice.getOnOrganisationType(partnerID)
      );
      if (response) {
        setAllOrgTypes(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOnOrganisationType();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('partnerId')) {
      setPartnerID(urlParams.get('partnerId'))
    }
    if (urlParams.get('partnerName')) {
      setPartner(urlParams.get('partnerName'))
    }
  }, [])

  useEffect(() => {

    if (partner) {
      getPartnerLogo(partner)
      localStorage.setItem('partner', partner)
    }
    if (partnerID) {
      getOnOrganisationType(partnerID)
      localStorage.setItem('partnerId', partnerID)
    }
    getCountriesList()

    // if (partner && partnerID) {
    //   shortenURL(window.location.href)
    // }
  }, [partner, partnerID])

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      // console.log(response)
      setPartnerLogo(response);
      setPartnerID(response.id)
      localStorage.setItem("partnerId", response.id);
      // console.log(response)
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getCountriesList = async () => {
    try {
      const response = await getRequest(userapiservice.getCountries());
      // console.log(response)
      setCountries(response);
      // console.log(response)
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    partner && getPartnerLogo();
  }, [partner]);

  useEffect(() => {
    getCountriesList();
  }, [])


  const submitHandler = (values) => {
    setFormData(values);

    let formData = {
      addressStreet: values?.addressStreet,
      email: values?.email,
      isActiveMember: member,
      name: values?.name,
      phone: values?.phone,
      postcode: values?.postcode,
      size: values?.size,
      typeId: values?.type,
      userName: values?.userName,
      websiteLink: values?.websiteLink,
    };
    console.log("FORMVALS", values);
    sessionStorage.setItem("adr", values?.addressStreet);
    sessionStorage.setItem("email", values?.email);
    sessionStorage.setItem("isactive", member);
    sessionStorage.setItem("name", values?.name);
    sessionStorage.setItem("phone", values?.phone);
    sessionStorage.setItem("postcode", values?.postcode);
    sessionStorage.setItem("size", values?.size);
    sessionStorage.setItem("userName", values?.userName);
    sessionStorage.setItem("webLink", values?.websiteLink);
    const typeName =
      allOrgTypes.find((type) => type.id === formData.typeId)?.type || "";
    sessionStorage.setItem("typeId", values?.type);
    sessionStorage.setItem("type", typeName);
    navigate("/terms", {
      state: {
        formData,
        partnerLogo: partnerLogo?.siteName,
        nameType: typeName,
      },
    });
    setFormFilled(true);
  };

  const validatePostalCode = (value) => {
    const ukPostalCodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/;
    if (ukPostalCodeRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  useEffect(() => {
    const storedSwitchValue = sessionStorage.getItem("isactive");
    // console.log("stored", storedSwitchValue)
    if (storedSwitchValue !== null) {
      // Convert the stored value to boolean if necessary
      setMember(storedSwitchValue === "true");
    }
  }, []);

  return (
    <motion.div
      className="onboarding-background-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
     <div className="onboarding-header">
      <div className="back-arrow" onClick={() => {navigate(-1);}}>
        <Back />
      </div>
      <div className="join-name">
        {partnerLogo?.siteName && `Apply to join "${partnerLogo?.siteName }"` }
      </div>

     </div>



      <div className="onBoard-container">
        <div className="form-container">
          {console.log("labelColSpan", labelColSpan)}
          <Form
            className="signup-form"
            // labelCol={{ span: 8 }}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              type: sessionStorage.getItem("typeId")
                ? sessionStorage.getItem("typeId")
                : allOrgTypes.length > 0
                  ? allOrgTypes[0]?.id
                  : "",
              isActiveMember: sessionStorage.getItem("isactive")
                ? sessionStorage.getItem("isactive")
                : false,
              name: sessionStorage.getItem("name")
                ? sessionStorage.getItem("name")
                : "",
              addressStreet: sessionStorage.getItem("adr")
                ? sessionStorage.getItem("adr")
                : "",
              postcode: sessionStorage.getItem("postcode")
                ? sessionStorage.getItem("postcode")
                : "",
              size: sessionStorage.getItem("size")
                ? sessionStorage.getItem("size")
                : null,
              userName: sessionStorage.getItem("userName")
                ? sessionStorage.getItem("userName")
                : "",
              email: sessionStorage.getItem("email")
                ? sessionStorage.getItem("email")
                : "",
              phone: sessionStorage.getItem("phone")
                ? sessionStorage.getItem("phone")
                : "",
              websiteLink: sessionStorage.getItem("webLink")
                ? sessionStorage.getItem("webLink")
                : "",
            }}
            onFinish={submitHandler}
          >
            <Row
              className="rowBody"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            ></Row>
            <Col
              className="gutter-row  Organisation-type"
              xs={24}
              xl={24}
              style={{ height: "auto", overflow: "auto hidden" }}
            >
              {allOrgTypes?.length > 3 && isSmallScreen ? (
                <Form.Item
                  className="form-item orgType"
                  label="Organisation type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Organisation type is required",
                    },
                  ]}
                >
                  <div style={{ position: "relative" }}>
                    {/* Left Arrow Button */}
                    <div
                      onClick={handlePrev}
                      style={{
                        position: "absolute",
                        left: "-1px",
                        top: "50%",
                        transform: "translateY(-60%)",
                        cursor: "pointer",
                        zIndex: 10,
                        padding: "10px 0 20px 0px",
                        background: "white",
                      }}
                    >
                      <LeftOutlined
                        style={{ fontSize: "15px", color: "#1890ff" }}
                      />
                    </div>

                    {/* Radio Group with Scroll */}
                    <Radio.Group
                      className="orgTypeBtns"
                      buttonStyle="solid"
                      ref={radioGroupRef}
                      style={{
                        display: "flex",
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        paddingBottom: "10px",
                      }}
                    >
                      {allOrgTypes?.length > 0
                        ? allOrgTypes.map((type) => (
                          <Radio.Button
                            key={type.id}
                            value={type.id}
                            style={{
                              width: "auto",
                              flexShrink: 0, // Prevent buttons from shrinking
                            }}
                          >
                            {type.type}
                          </Radio.Button>
                        ))
                        : null}
                    </Radio.Group>

                    {/* Right Arrow Button */}
                    <div
                      onClick={handleNext}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-75%)",
                        cursor: "pointer",
                        zIndex: 1,
                        padding: "10px 0",
                        background: "white",
                      }}
                    >
                      <RightOutlined
                        style={{ fontSize: "14px", color: "#1890ff" }}
                      />
                    </div>
                  </div>
                </Form.Item>
              ) : (
                <Form.Item
                  className="form-item orgType"
                  // style={{marginLeft:"0px",width:"99%"}}
                  label="Organisation type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Organisation type is required",
                    },
                  ]}
                >
                  <Radio.Group
                    className="orgTypeBtns"
                    buttonStyle="solid"
                    style={{
                      paddingLeft: "0px",
                      width:
                        is1366 || is1240 || is768 || is820 ? "100%" : "99%",
                      gap:
                        is1366 && allOrgTypes.length == 3
                          ? "3vw"
                          : is1366 && allOrgTypes.length > 3
                            ? "1vw"
                            : is1240
                              ? "3vw"
                              : is768
                                ? "2vw"
                                : is820
                                  ? "2vw"
                                  : "1vw",
                    }}
                  >
                    {allOrgTypes?.length > 0
                      ? allOrgTypes.map((type) => (
                        <Radio.Button
                          key={type.id}
                          value={type.id}
                          style={{ width: "auto" }}
                        >
                          {type.type}
                        </Radio.Button>
                      ))
                      : null}
                  </Radio.Group>
                </Form.Item>
              )}
            </Col>
            <Col
              className="gutter-row"
              xs={24}
              xl={24}
              style={{
                marginBottom: isSmallScreen
                  ? "20px"
                  : is1240
                    ? "5px"
                    : is768 || is820
                      ? "10px"
                      : "0px",
                marginTop: is1240
                  ? "5px"
                  : is768
                    ? "10px"
                    : is820
                      ? "5px"
                      : isSmallScreen
                        ? "-25px"
                        : "0px",
              }}
            >
              <Form.Item
                className="switch-item memberFormItem"
                // style={{ marginTop: "10vw" }}
                label="Are you an existing member"
                name="isActiveMember"
                rules={[
                  {
                    required: true,
                    message: "Choose if you are member or not",
                  },
                ]}
              >
                <div className="flexSwitch">
                  <Switch
                    // style={{ color: "green" }}
                    className="memberSwitch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    // defaultChecked={false}
                    checked={member}
                    onChange={(e) => {
                      if (e) {
                        setMember(true);
                      } else {
                        setMember(false);
                      }
                    }}
                  />
                  <div className="memberCondition custom-placeholder">{`${partnerLogo?.name ? partnerLogo?.name : ""
                    }`}</div>
                </div>
              </Form.Item>
              <div className="condition">{`${partnerLogo?.name ? partnerLogo?.name : ""
                }`}</div>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField org-name"
                label="Organisation name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Organisation name is required",
                  },
                ]}
              >
                <Input
                  placeholder="Organisation name"
                  className="custom-placeholder"
                // style={{ border: "1px solid #7E97B8",height:"55px", }}
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField"
                label="Address"
                name="addressStreet"
                rules={[{ required: true, message: "Address is required" }]}
              >
                <TextArea
                  style={{ paddingTop: paddingTop }}
                  className="addressBox custom-placeholder"
                  rows={1}
                  placeholder="Full address (You need to be in our catchment area)"
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField"
                label="Postal code"
                name="postcode"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePostalCode(value),
                    message: "Please enter a valid postal code",
                  },
                ]}
              >
                <Input
                  // style={{ border: "1px solid #7E97B8",height:"55px"  }}
                  className="addressBox custom-placeholder"
                  rows={1}
                  placeholder="Postal code"
                />
              </Form.Item>
            </Col>
          

            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            
            >
              <Form.Item
                className="form-item organisationSize"
                label="Organisation size"
                name="size"
                rules={[
                  {
                    required: true,
                    message: "Organisation size is required",
                  },
                ]}
              >
               

                <Select
                className="orgSize1"
                value={orgSize}
                placeholder='Select Organization Size'
                onChange={(e) => {
                setOrgSize(e)
                }}
                >
                  {organisationSize.map((item) => (
                    <Select.Option key={item?.value} value={item?.value}>
                      {item.label}
                       </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Col>
              
              </Col>
            </Col>





            <Col
              style={{ marginTop: isSmallScreen ? "2px" : "0" }}
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField"
                label="Full name"
                name="userName"
                rules={[
                  { required: true, message: "Full name is required" },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ ]*$/),
                    message:
                      "Field does not accept numbers or special characters",
                  },
                ]}
              >
                <Input
                  // style={{ border: "1px solid #7E97B8",height:"55px" }}
                  style={{
                    border: "1px solid #7E97B8",
                    // height: "55px",
                    fontFamily: "AirbnbCereal-Book, sans-serif",
                    // fontSize: "18px",
                    lineHeight: "23px",
                    letterSpacing: "1.44px",
                    color: "#000000",
                  }}
                  placeholder="Full name"
                  className="custom-placeholder"
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Email is required" },
                  { type: "email", message: "Enter valid email" },
                ]}
              >
                <Input
                  // style={{ border: "1px solid #7E97B8",height:"55px"  }}
                  placeholder="Email"
                  className="custom-placeholder"
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item adrField"
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required",
                  },
                  {
                    pattern: /^[0-9\s]+$/,
                    message: "Please enter only numbers.",
                  },
                ]}
              >
                <Input
                  // style={{ border: "1px solid #7E97B8",height:"55px"  }}
                  placeholder="Phone number"
                  className="custom-placeholder"
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            >
              <Form.Item
                className="form-item"
                label="Organisation website"
                name="websiteLink"
              >
                <Input
                  // style={{ border: "1px solid #7E97B8",height:"55px"  }}
                  placeholder="Website"
                  className="custom-placeholder"
                />
              </Form.Item>
            </Col>
            <Col
              className="gutter-row row-gap"
              xs={24}
              xl={24}
            // style={{ marginBottom: "20px" }}
            ></Col>
            <div className="submitBtn">
              <Button
                // style={{ width: '186px'}}
                type="primary"
                htmlType="submit"
                className="next_button"
              >
                Next
              </Button>
            </div>
          </Form>
          {/* </Row> */}
        </div>
        {/* <div className="submitBtn">
          <Button
            // style={{ width: '186px'}}
            type="primary"
            htmlType="submit"
            className="next_button"
          >
            Next
          </Button>
        </div> */}
      </div>
    </motion.div>
  );
}
