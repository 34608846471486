import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Popover, Select } from "antd";
import axios from "axios";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import { CloseActive, Filter, FilterSlider, ProfileClose } from "../../components/Icons/Icons";
import ToastComponent from "../../components/ToastCompoent/ToastComponent";
import OrganiationSizes from "../../json/organizationSize.json";
import { truncate } from "../../utils/helper";
import useComponentVisible from "../login/useComponentVisible";
import PartnerCarousel from "./partnerSlider";
import "./PartnerPage.scss";

const LogoCarousel = ({ partnerLogos }) => {
  const { globalState } =
    useContext(GlobalContext);

  const extendedPartnerLogos = useMemo(() => [...partnerLogos, ...partnerLogos], [partnerLogos]);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrollPosition((prevPosition) => {
        // Calculate the new scroll position (scrolling up)
        const newPosition = prevPosition - 1;

        // Reset the scroll position when it reaches the end
        if (newPosition < -(extendedPartnerLogos?.length * 17)) {
          return 0;
        }

        return newPosition;
      });
    }, 50); // Adjust the interval for scrolling speed

    return () => clearInterval(scrollInterval);
  }, [extendedPartnerLogos]);

  return (
    <div className={`overflow-hidden row p-0 ${window.innerHeight < 650 ? 'my-md-2' : 'my-md-4'} mx-auto business-partners-wrapper`} >
      <div className="our-business-partners-container  pb-0 p-md-2 d-flex align-items-center justify-content-center text-capitalize ">
        <strong className="our-business-partners " >
          Our Business Partners
        </strong>
      </div>
      <div className="business-partners-list overflow-hidden p-0" >
        <div className={`${globalState.isMobile ? 'mt-3' : ''} overflow-hidden h-100`} >

          <div
            className="business-partners-list-container  row m-md-0 p-0 d-flex  justify-content-center"
            style={{
              transform: `translateY(${scrollPosition}px)`,
              cursor: "pointer",
            }}
          >
            {extendedPartnerLogos.map((partner, index) => (
              <div
                onClick={() => window.open(partner?.urlLink.startsWith("https://") ? partner?.urlLink : `https://${partner?.urlLink}`, "_blank")}
                className="p-0 partner-wrapper"
              >
                <div className="partner-image d-flex justify-content-center align-items-center "
                >
                  <img src={partner?.mediaLink?.url} height="50px" style={{ maxHeight: '50px', }} alt="Partner Logo" />
                </div>
                <div
                  className="partner-description h-100 d-none d-md-block "
                >
                  {truncate(partner?.description, 60)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default function PartnerPage() {
  const [partnerLogos, setPartnerLogos] = useState([]);
  //to be commented in prod
  // const location = useLocation();
  // const partnerNotFormat = location?.pathname?.split("/")[2];
  // const formattedName = partnerNotFormat?.replace(/_/g, " ");
  // const partner = formattedName;

  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [organisationType, setOrganisationType] = useState([]);
  const { profileOpen, setProfileOpen } =
    useContext(GlobalContext);

  // FOR SUBDOMAIN IMPLEMENTATION
  //to be uncommented for prod
  const location = window.location.href;
  const firstSplit = location.split('/')[2];
  const secondSplit = firstSplit.split('.')[0];
  const partner = secondSplit?.replace(/-/g, " ") || "";

  // const partner = 'partner name' || "";

  // console.log('firstSplit partner',partner)
  // const partner = "Partner Name";


  localStorage.setItem("partner", partner);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    if (createdUser != "created") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    if (createdUser != "created") {
      if (latitude === "" || longitude === "") {
        // this is to stop fetching data on component mount, cause we have no Latitude and Longitude YET
        return;
      } else {
        async function getWeather() {
          let res = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=geojson&zoom=8&lat=${latitude}&lon=${longitude}`
          );
          // https://nominatim.org/release-docs/develop/api/Reverse/#example-with-formatgeojson
          // For more info on the OSM API, refer the link mentioned above

          localStorage.setItem(
            "country",
            res.data?.features[0]?.properties?.address?.country
          );
          if (res.data?.features[0]?.properties?.address?.county) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.county
            );
          } else if (res.data?.features[0]?.properties?.address?.state_district) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.state_district
            );
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.municipality
            );
          } else if (res.data?.features[0]?.properties?.address?.town) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.town
            );
          } else {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.city
            );
          }
          localStorage.setItem(
            "state",
            res.data?.features[0]?.properties?.address?.state
          );

          if (res.data?.features[0]?.properties?.address?.county) {
            setCounty(res.data?.features[0]?.properties?.address?.county);
          } else if (res.data?.features[0]?.properties?.address?.state_district) {
            setCounty(res.data?.features[0]?.properties?.address?.state_district);
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            setCounty(res.data?.features[0]?.properties?.address?.municipality);
          } else if (res.data?.features[0]?.properties?.address?.town) {
            setCounty(res.data?.features[0]?.properties?.address?.town);
          } else {
            setCounty(res.data?.features[0]?.properties?.address?.city);
          }
          // setCounty(res.data?.features[0]?.properties?.address?.county);
          setCountry(res.data?.features[0]?.properties?.address?.country);
          setState(res.data?.features[0]?.properties?.address?.state);
        }
        getWeather();
      }
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (partner) {
      getPartnersLogo();
    }
  }, []);

  const getPartnersLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerSupporterLogo(partner)
      );
      if (response) {
        setPartnerLogos(response);
      }
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || 'Sorry, Something went wrong'} />)

    }
  };

  const navigate = useNavigate();
  const [subSectors, setSubSectors] = useState([]);
  const [sectorId, setSectorId] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  );
  const [subsectorId, setSubsectorId] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  );
  const [size, setSize] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : null
  );
  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : ""
  );
  const interest = [
    "",
    "PRODUCT",
    "SERVICE",
    "CONTRACTS",
    "VACANCY",
    "APPRENTICESHIP",
    "WORK_EXPERIENCE",
    "COURSES",
  ];
  const [selectedEvent, setselectedEvent] = useState("");
  const [selectedOrganisationType, setSelectedOrganisationType] = useState(() => {
    return localStorage.getItem("OrganisationType") || "All";
  });
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [partnerCarousel, setPartnerCarousel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [SearchKeyWord, setSearchKeyWord] = useState('');

  const handleFreeTextChange = (e) => {
    setSearchKeyWord(e.target.value)
    localStorage.setItem("searchName", e.target.value);
  };
  const clearSearchKeyword = () => {
    setSearchKeyWord('')
    localStorage.setItem("searchName", '');
  }

  const [partnerId, setPartnerId] = useState();

  useEffect(() => {
    if (localStorage.getItem("partnerId")
    ) {
      setPartnerId(localStorage.getItem("partnerId"))
    }
  }, [])

  useEffect(() => {
    if (globalState.partner !== partner) {
      setGlobalState((prevState) => ({ ...prevState, 'partner': partner })
      );
    }
    if (globalState.partnerId !== partnerId) {
      setGlobalState((prevState) => ({ ...prevState, 'partnerId': partnerId })
      );
    }
  }, [partner, partnerId]);

  const [isShowProfile, setIsShowProfile] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [sectorValue, setSectorValue] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : ""
  );
  const [subSectorValue, setSubsectorValue] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : ""
  );
  const [sizeValue, setSizeValue] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : ""
  );

  const [openHome, setOpenHome] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        setPartnerLogo(response);
        setPartnerId(response.id)
        localStorage.setItem("partnerId", response.id);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getPartnerCarousel = async () => {
    setLoading(false);
    try {
      const response = await getRequest(
        userapiservice.getPartnerCarousel(partner)
      );
      setPartnerCarousel(response?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      setLoading(true);
    } catch (e) {
      setLoading(false);
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getSubSector = async (sectorId) => {
    try {
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));
      setSubSectors(response);
      // setSubsectorValue(subSectorId);
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || 'Sorry, Something went wrong'} />)

    }
  };

  const onOrgInterest = (value) => {
    setSelectedOrganisationType(value);
    localStorage.setItem(
      "OrganisationType",
      value ? value : ""
    );
    const name = organisationType?.find((type) => type?.id === value)?.type;
    localStorage.setItem('organisationNameById', name)
  };

  const onInterest = (e) => {
    setselectedEvent(e);
    // setinterestName(e);
    localStorage.setItem("Interest/AssetType", e);
    // console.log(e)
    if (e === "PRODUCT") {
      localStorage.setItem("tabValue", "product");
    } else if (e === "SERVICE") {
      localStorage.setItem("tabValue", "service");
    } else if (e === "CONTRACTS") {
      localStorage.setItem("tabValue", "contract");
    } else if (e === "VACANCY") {
      localStorage.setItem("tabValue", "vacancy");
    } else if (e === "APPRENTICESHIP") {
      localStorage.setItem("tabValue", "apprenticeship");
    } else {
      localStorage.setItem("tabValue", "work-experience");
    }
    // localStorage.setItem("tabValue",e)
  };

  const searchClick = () => {
    setOpenHome(true);
    // setOpen(true);
    // if (age) {
    //   setOpenHome(true);
    // } else {
    //   setProfileOpen(true);
    // }
  };

  const searchHandleKeyDown = (e) => {
    if (e.key === "Enter") {
      searchClick(e);
    }
  }
  useEffect(() => {
    if (openHome) { navigate("/microsite") }
    setOpenHome(false)
  }, [openHome])


  const onSector = async (sectorId) => {
    setSectorValue(sectorId);
    localStorage.setItem("sector", sectorId ? sectorId : "");
    if (sectorId) {
      try {
        const response = await getRequest(
          userapiservice.getSubSectors(sectorId)
        );
        setSubSectors(response);
      } catch (error) {
        toast.error(<ToastComponent type={'Error'} message={error?.error || 'Sorry, Something went wrong'} />)

      }
    } else {
      setSubsectorValue("");
      setSubSectors(null);
    }
  };

  const SizeChange = async (size) => {
    setSizeValue(size);
    localStorage.setItem("size", size ? size : "");
  };

  const subSectorChange = async (subSectorIds) => {
    setSubsectorValue(subSectorIds);
    localStorage.setItem("subsector", subSectorIds ? subSectorIds : "");
  };

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(
        userapiservice.getAllSectors(partnerId)
      );
      setSectors(response);
      if (sectorId) {
        setSectorValue(sectorId);
      }
      if (size) {
      }
    } catch (error) {
    }
  };

  const closeDropdown = () => {
    setIsShowProfile(!isShowProfile);
    setinterestName("");
    setSizeValue("");
    setSubsectorValue("");
    setSectorValue("");
  };

  useEffect(() => {
    if (partner) {
      getPartnerLogo();
      getPartnerCarousel();
    }
  }, [partner, sectorId, subsectorId, size, interestName]);


  const getOnOrganisationType = async () => {
    try {
      const response = await getRequest(
        userapiservice.getOnOrganisationType(partnerId)
      );
      if (response) {
        setOrganisationType(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getOnOrganisationType();
  }, [partnerId]);

  useEffect(() => {
    if (partner && partnerId) {
      fetchSectorList();
      localStorage.setItem("Page", "partner");
    }
  }, [partner, partnerId]);

  useEffect(() => {
    if (partner && sectorId) {
      getSubSector(sectorId);
    }
  }, [partner, sectorId]);

  useEffect(() => {
    if (localStorage.getItem("changeAge") === "true") {
      setProfileOpen(true);
    }
    localStorage.removeItem("changeAge")
  }, [])

  useEffect(() => {
    if (!globalState.isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const filtersPopUp = useRef(null);


  const handleClickOutside = (event) => {
    if (filtersPopUp.current && !filtersPopUp.current.contains(event.target) && !event.target.closest('.ant-select-dropdown')
    ) {
      setOpenFilter(false)
    }
  };

  const Filters = () => {
    return (
      <motion.div
        className="filter-modal-window-container"
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Form className="p-3 profile-filters-form ">
          <div className=" row ">
            <Form.Item className="col-12 col-md-6 form-item pr-md-3">
              <label className="label-text">Search organisations for</label>
              <Select
                value={selectedEvent}
                placeholder="Select interest"
                onChange={(e) => onInterest(e)}
              >
                {interest?.map((Search, i) => (
                  <Select.Option key={i} value={interest[i]}>
                    {interest[i] === ""
                      ? "All"
                      : interest[i] === "APPRENTICESHIP"
                        ? "Apprenticeships"
                        : interest[i] === "VACANCY"
                          ? "Vacancies"
                          : interest[i] === "PRODUCT"
                            ? "Products"
                            : interest[i] === "CONTRACTS"
                              ? "Contracts"
                              : interest[i] === "SERVICE"
                                ? "Services"
                                : interest[i] === "WORK_EXPERIENCE"
                                  ? "Experiences of work"
                                  : interest[i] === "COURSES"
                                    ? "Courses"
                                    : interest[i] === "CONTRACTS"
                                      ? "Contracts"
                                      : ""}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="col-12 col-md-6 form-item pr-md-3">
              <label className="label-text">Organisation Type</label>
              <Select
                value={selectedOrganisationType}
                placeholder="Select Organization Type"
                onChange={onOrgInterest}
              >
                {organisationType.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item className="col-12 col-md-6 form-item pl-md-3">
              <label>Categories</label>
              <Select
                value={sectorValue}
                placeholder="Select Sector"
                onChange={(e) => {
                  localStorage.removeItem("subsector");
                }}
                onSelect={(e) => {
                  onSector(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                {sectors?.map((Sector, i) => (
                  <Select.Option key={i} value={Sector.id}>
                    {Sector.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="col-12 col-md-6 form-item pr-md-3">
              <label>Sub categories</label>
              <Select
                value={subSectorValue}
                placeholder="Select Subsector"
                onSelect={(e) => {
                  subSectorChange(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                {subSectors &&
                  subSectors?.map((subSector, i) => (
                    <Select.Option key={i} value={subSector.id}>
                      {subSector.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className=" row ">
            {/* <Form.Item className="col-12 col-md-6 form-item pr-md-3">
              <label>Sub categories</label>
              <Select
                value={subSectorValue}
                placeholder="Select Subsector"
                onSelect={(e) => {
                  subSectorChange(e);
                }}
              >
                <Select.Option value="">All</Select.Option>
                {subSectors &&
                  subSectors?.map((subSector, i) => (
                    <Select.Option key={i} value={subSector.id}>
                      {subSector.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item> */}
            <Form.Item className="col-12 col-md-6 form-item pl-md-3">
              <label>Size by employees</label>
              <Select value={sizeValue} onChange={(e) => SizeChange(e)}>
                <Select.Option value="">All</Select.Option>
                {OrganiationSizes.map((OrganiationSize, i) => (
                  <Select.Option key={i} value={OrganiationSize.id}>
                    {OrganiationSize.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {/* <div className="filters-submit-btn-container" >
          <Form.Item className="d-flex justify-content-end mb-0" >
            <button
              className="btn btn-danger  filters-submit-btn"
              type="submit"
              onClick={(e) => { searchClick(e); setOpenFilter(false) }}
            >
              <span>Search</span>
            </button>
          </Form.Item>
        </div> */}
          <div className="filters-submit-btn-container">
            <Form.Item className="d-flex justify-content-end mb-0">
              {globalState.isMobile && openFilter && (
                <button
                  className="btn filters-submit-btn"
                  style={{ background: "#908a8a" }}
                  type="submit"
                  onClick={() => {
                    setOpenFilter(false);
                  }}
                >
                  <span>Cancel</span>
                </button>
              )}

              <button
                className="btn btn-primary filters-submit-btn"
                type="submit"
                onClick={(e) => {
                  searchClick(e);
                  setOpenFilter(false);
                }}
              >
                <span>Filter Results</span>
              </button>
            </Form.Item>
          </div>
        </Form>
      </motion.div>
    );
  }

  return (
    <div className={`main-body-container d-flex flex-column ${globalState.isMobile ? 'body-container-mobile bg-white' : 'body-container-desktop'}`}
      style={{
        backgroundImage: `${partnerLogo?.wallpaperToggle === true && !globalState.isMobile
          ? `url(${partnerLogo?.wallpaper})`
          : `none`
          }`,
        backgroundColor: `${partnerLogo?.wallpaperToggle === true && !globalState.isMobile
          ? `none`
          : "#F6F6F6"
          }`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <div className={`main-body ${globalState.isMobile ? 'body-mobile' : 'body-desktop'}`}>
        <div className=" partner-page-container">

          <h3 className='partner-page-org-title' >
            {partnerLogo?.siteName}
          </h3>

          {loading && (
            <div
              className={`partner-page-carousel ${!globalState.isMobile ? 'bordered-container' : ''}   bg-white`}>
              <PartnerCarousel carouselData={partnerCarousel} />
            </div>
          )}



          {loading && (
            <>
              <div className={`partner-page-logos-and-search ${globalState.isMobile ? 'my-0 ' : 'bordered-container'} align-items-center bg-white`} >
                <div className={`d-flex search-wrapper ${globalState.isMobile ? 'w-100 p-0' : 'w-md-75'}`} >
                  <div className="title-bar-search-wrapper" style={{ width: "100%" }}>
                    <Input onChange={handleFreeTextChange}
                      placeholder="Search"
                      className="h-100 w-100"
                      onKeyDown={searchHandleKeyDown}
                      value={SearchKeyWord}
                      prefix={
                        <SearchOutlined
                          className="search-icon-landing-page"
                          style={{ color: "#CC0E2D", verticalAlign: "middle" }}
                          onClick={searchClick}
                        />
                      }
                      suffix={
                        <span className="align-items-center d-flex gap-1">
                          {SearchKeyWord.length > 0 && <button
                            onClick={() => clearSearchKeyword()}
                            className="btn btn-sm  ">
                            <CloseActive />
                          </button>}
                          <Popover
                            destroyTooltipOnHide
                            content={<div ref={filtersPopUp}
                              className="partner-page-filters-popup"  >
                              <Filters />
                            </div>}
                            trigger="click"
                            open={!globalState.isMobile && openFilter}
                            onOpenChange={() => { setOpenFilter(!openFilter) }}
                            placement="bottomRight"
                          >
                            <button
                              className="btn btn-sm"
                              // type="button"
                              onClick={() => { setOpenFilter(true) }}
                            >
                              <FilterSlider onClick={() => { setOpenFilter(true) }} />
                            </button>
                          </Popover>
                        </span>
                      }
                    />
                  </div>

                </div>
                <div className=" d-flex partners-logo-carousel " >
                  <LogoCarousel partnerLogos={partnerLogos} />
                </div>
              </div>
            </>
          )}

          {loading && (
            <div className="partner-page-join-now bordered-container d-flex justify-content-between align-items-center bg-white"   >
              <div className="partner-org-logo-container">
                <img src={partnerLogo?.mediaLink} className="partner-org-logo " alt="logo" />
              </div>
              <div className="partner-org-title-container" >
                <span className="partner-org-title" > {partnerLogo?.siteName}</span>
              </div>
              <div className="partner-org-description-container">
                <p className="partner-org-description">{partnerLogo?.description}</p>
              </div>
              <a
                href={partnerLogo?.membershipLink || '/onboarding'}
                target="_blank"
                rel="noreferrer"
                className={`btn join-now-btn btn-primary text-white align-content-center w-full ml-auto end ${globalState.isMobile ? 'btn-sm' : 'btn-block'} custom-style-class `}
              >
                Find Out More
              </a>
              <div className="partner-org-description-xs-screens-container">
                <p className="partner-org-description-xs-screens">{partnerLogo?.description}</p>
              </div>
            </div>
          )}

          <Modal centered closable={false}
            footer={false} open={globalState.isMobile && openFilter} className="filters-modal-wrapper">
            <div className="d-flex align-items-center justify-content-between title-bar position-relative" >
              <div className="mobile-filter-icon">
                <Filter />
              </div>
              <div className="mobile-filter-close-icon">
                <button className="btn btn-small p-0" onClick={() => { setOpenFilter(false) }} >
                  <ProfileClose />
                </button>
              </div>
            </div>
            <Filters />
          </Modal>
        </div>
      </div>
    </div>
  );
}

