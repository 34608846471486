import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Checkbox, Modal, Row, Col } from "antd";
import userapiservice from "../../api/userapiservice";
import { postRequest } from "../../api/http";
import { toast } from "react-toastify";
import Leftarrow from "../../assets/images/Leftarrow.svg";
// import "./OrgOnboarding.scss";
import './terms.scss'
import ToastComponent from "../../components/ToastCompoent/ToastComponent";

export default function TermsPage() {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  // console.log("vals in terms", state?.formData?.isActiveMember)
  const partner = localStorage.getItem("partner");

  const [submitBtnStatus, SetSubmitBtnStatus] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  const handleChange = (e) => {
    // console.log("val", e.target.checked)
    SetSubmitBtnStatus(!e.target.checked);
  };

  const handleCancel = () => {
    setReviewModalVisible(false);
  };

  const submitHandler = async () => {
    let submit = {
      // firstName: state?.formData?.values.firstName,
      // lastName: state?.formData?.values.lastName,
      userName: state?.formData?.userName,
      email: state?.formData?.email,
      phone: state?.formData?.phone,
      partnerName: partner,
      isActiveMember: state?.formData?.isActiveMember,
      name: state?.formData?.name,
      websiteLink: state?.formData?.websiteLink,
      typeId: state?.formData?.typeId,
      size: state?.formData?.size,
      addressInputs: [
        {
          street: state?.formData?.addressStreet,
          postalCode: state?.formData?.postcode,
          // countryId: state?.formData?.values.countryId
        },
      ],
    };

    // console.log("submit", submit)
    try {
      const response = await postRequest(userapiservice.signUpOrg(), submit);
      if (response) {
        setReviewModalVisible(false);
        setSuccessModal(true);
        sessionStorage.clear();
        setTimeout(() => {
          navigate(`/`);
        }, 10000);
      }
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.response?.data?.message || 'Sorry, Something went wrong'} />)

      // setErrorModal(true);
      // setTimeout(() => {
      //   navigate("/onboarding");
      // }, 10000);
    }
  };

  return (
    <motion.div
      className="background-container-terms"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="header">
        <div className="onBoarding-header">
          {/* <h2>Welcome to {state?.partnerLogo}</h2> */}
          <img
            className="back-btn"
            src={Leftarrow}
            onClick={() => {
              navigate("/onboarding");
            }}
          />
          <h2>Apply to join "{state?.partnerLogo}"</h2>
        </div>
      </div>
      <div className="container">
        <div className="apply-header">
          <h2>Terms and Conditions</h2>
        </div>
        <div className="terms-text">
          <div className="terms-content">
            {/* <h3><b>Terms and Conditions</b></h3> */}
            <p>
              Please read these Terms and Conditions carefully before using our
              services.
            </p>
            <p>
              By accessing or using our services, you agree to be bound by these
              Terms and Conditions. If you do not agree with any part of these
              terms, do not access or use our services.
            </p>
            <br></br>
            <p>
              1. **Service Description:** Our services provide [brief
              description of services] to users [define users]. The services are
              subject to change or termination without prior notice.
            </p>
            <br></br>
            <p>
              2. **User Obligations:** Users must provide accurate information
              during registration. You are responsible for maintaining the
              confidentiality of your account and password. You agree to accept
              responsibility for all activities that occur under your account.
            </p>
            <br></br>
            <p>
              3. **Intellectual Property:** All content provided through our
              services, including text, graphics, logos, images, and software,
              is the property of [your company name] and protected by applicable
              intellectual property laws.
            </p>
            <br></br>
            <p>
              4. **Limitation of Liability:** We are not liable for any direct,
              indirect, incidental, special, or consequential damages arising
              from the use of our services.
            </p>
            <br></br>
            <p>
              5. **Privacy Policy:** Our Privacy Policy explains how we collect,
              use, and protect your personal information. By using our services,
              you consent to our Privacy Policy.
            </p>
            <br></br>
            <p>
              6. **Governing Law:** These Terms and Conditions shall be governed
              by and construed in accordance with the laws of [your
              jurisdiction].
            </p>
            <br></br>
            <p>
              By using our services, you acknowledge that you have read,
              understood, and agreed to these Terms and Conditions. If you do
              not agree with any part of these terms, you must refrain from
              using our services.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <h4>
              <b>[Your company name]</b>
            </h4>
            <h4>
              <b>[Contact information]</b>
            </h4>
            <h4>
              <b>[Date]</b>
            </h4>
            <br></br>
            (Note: This is a generic sample and should be tailored to your
            specific needs. Consult with a legal professional to ensure accuracy
            and compliance with relevant laws.)
          </div>
        </div>
        <div className="check-submit">
          <div className="checkbox">
            <Checkbox onChange={handleChange}>
              <span className="checkbox-condition">
              Accept the terms and conditions
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="submitbtn">
          <Button
            disabled={submitBtnStatus}
            onClick={() => setReviewModalVisible(true)}
            type="primary"
            htmlType="submit"
          >
            Review & Submit
          </Button>
        </div>
      </div>
      <Modal
      className="signup-confirm-modal"
        open={successModal}
        footer={null}
        // width={320}
        onCancel={() => setSuccessModal(false)}
      >
        <p className="signup-text">
          {/* Your Signup Process is completed and sent for approval. You
                        will be informed after approval... */}
          Thank you for signing up and once approved please check your email for
          your login details &#40; also check your spam folder &#41;
        </p>
      </Modal>
      <Modal
        open={errorModal}
        footer={null}
      // onCancel={() => setErrorModal(false)}
      >
        <p className="signup-text">Sorry, Organisation name already exists.</p>
      </Modal>
      <Modal
        open={reviewModalVisible}
        onCancel={handleCancel}
        width={window.innerWidth >= 768 ? "850px" : null}
        // closeIcon={<img className="closeIcon" src={closeIcon} />}
        footer={null}
        className="viewMicrositeModal"
      >
        <div className="approve-container">
          <Row
            className="rowBody"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h3 className="review-title" style={{ margin: "10px 0px" }}>Review your Information</h3>
          </Row>

        <div className="approve-content">
        <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Organisation type
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.nameType}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Are you an existing member
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.isActiveMember === true ? "Yes" : "No"}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Organisation name
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.name}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Address
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.addressStreet}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Postal code
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.postcode}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Organisation size
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.size === "MICRO" ? "Micro 0-9"
                : state?.formData?.size === "SMALL" ? "Small 10-49"
                  : state?.formData?.size === "MEDIUM" ? "Medium 50-249"
                    : state?.formData?.size === "LARGE" ? "Large 249+"
                      : ""}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Full name
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.userName}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Email
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.email}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Phone number
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.phone}
            </Col>
          </Row>
          <Row className="rowBody" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row tabContent" span={10}>
              Organisation website
            </Col>
            <Col className="gutter-row tabContent-body" span={12}>
              {state?.formData?.websiteLink}
            </Col>
          </Row>
        </div>


          <div className="submitbtnend" style={{ gap: "10px" }}>
            <Button
              onClick={() => navigate("/onboarding")}
            // type="primary"
            // htmlType="submit"
            >
              Edit
            </Button>
            <Button
              onClick={() => submitHandler()}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}
