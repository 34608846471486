import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnimatedRoutes from "./AnimatedRoutes";
import "./App.css";
import { useSelector, useDispatch } from 'react-redux';
import { checkIsMobile } from "./Reducer/globalSlice";
import { GlobalContextProvider } from './components/globalContext/GlobalContext';
import { ToastError } from "./components/Icons/Icons";


function App() {
  const dispatch = useDispatch();
  const global = useSelector(state => state.global);
  console.log('global',global)

  useEffect(() => {
    const typeOfDevice =
    window.innerWidth < 760
      ? "mobile"
      : window.innerWidth < 1023
      ? "tab"
      : "laptop";

      dispatch(checkIsMobile(typeOfDevice))

  },[])

  return (
    <GlobalContextProvider>

      <Router>
        <AnimatedRoutes />
        <ToastContainer
 icon={({ type, theme }) => {
  switch (type) {
    case 'error':
      return <ToastError />;
    default:
      return null;
  }
}}

          position="top-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
     </GlobalContextProvider>
  );
}

export default App;
