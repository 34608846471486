import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import SharedSiteNavbar from "../components/Layout/SharedSiteNavbar";
import Loader from "../components/Loader/Loader";
const PublicRoutes = () => {
  const isShared = useSelector(state => state.global.isShared)

  return (
    <>
      {isShared === null ? <Loader /> : (
        <div className="h-100">
          {/* {!isShared ? <Navbar /> : <SharedSiteNavbar />} */}
          <Outlet />
          {!isShared && <Footer />}
        </div>
      )}
    </>
  );
};

export default PublicRoutes;
