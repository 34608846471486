import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg"
import ModalPlayer from "../../components/PlayerModal/playerModal";
import CardList from "./newUx/CardList/CardList";
import ToastComponent from "../../components/ToastCompoent/ToastComponent";


export default function Services({ ChangeShowDetailHandler }) {
  const [loading, setLoading] = useState(true);
  // const [selectedOption, setselectOption] = useState({});
  // const [data, setData] = useState([{}]);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const [activeKey, setActiveKey] = useState("service");
  const [responseData, setReponseData] = useState([]);
  const [mediatype, setmediatype] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];

  
  const getServicesData = async (activeKey, id) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      if(id){
      const response = await getRequest(
        userapiservice.getMicrositeDetailsTabs(id, activeKey)
      );
      setReponseData(response);
      // console.log(response)
      console.log("response data",response)
      setLoading(false);}
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || error?.message || 'Sorry, Something went wrong'} />)

      setLoading(false);
    }
  };
  useEffect(() => {
    getServicesData(activeKey, id);
  }, [activeKey, id]);

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf('youtu.be') !== -1) {
    const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
    return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
    }
  

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const onCardClickHandler = (data, id) => {
    navigate(`/microsite/${data.id}/micrositeDetails`, {
      state: { page: activeKey, data: data },
    });
  };
  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };


  if (loading) {
    return (
      <div
      className="ant-tabs-content-holder"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader">
        <Spin tip="Loading" size="large" />
      </div>
    </div>
    )
  } else {
    return (
      <>
        <CardList
          responseData={responseData}
          color={color}
          PlayVideoHandler={PlayVideoHandler}
          convertToStandardURL={convertToStandardURL}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          videoUrl={videoUrl}
          mediaType={mediatype}
          onCardClickHandler={onCardClickHandler}
        />
      </>
    );
  }
}
