import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import callIcon from "../../../assets/newImages/callIcon.svg";
import LinkOutlined from "../../../assets/newImages/LinkOutlined.svg";
import { Back } from "../../../components/Icons/Icons";
import Loader from "../../../components/Loader/Loader";
import ViewAndDownloadBrochure from "../ViewAndDownloadBrochure";
import InfoCard from "./InfoCard/InfoCard";
import MicrositeDetailsMedia from "./micrositeDetailsMedia/MicrositeDetailsMedia";
import "./newMicrositeDetails.scss";

const NewMicrositeDetails = ({ data }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isBrochureLoaded, setIsBrochureLoaded] = useState(false);
  // const[isLoading,setIsLoading]=useState(false);

  
  const [isContentVisible, setIsContentVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  // const { state } = location;

  const [loading, setLoading] = useState(true);

  // directly consuming navigation state 
  const { state: navigateState } = location;
  const [state, setState] = useState(null)
  useEffect(() => {
    setLoading(true)
    // Retrieve any existing data from sessionStorage
    const storedData = sessionStorage.getItem('sharedData');

    if (navigateState) {
      // If data is passed via navigate state, use it and save it to sessionStorage for reloads
      setState(navigateState);
      sessionStorage.setItem('sharedData', JSON.stringify(navigateState));
    } else if (storedData) {
      // If navigate state is unavailable (e.g., after reload), use the data from sessionStorage
      setState(JSON.parse(storedData));
    }

    // Set loading to false once data is retrieved or determined
    setLoading(false);
  }, [navigateState]);
  

  // const isLoading =
  //   !isMediaLoaded ||
  //   ((state?.page === "product" || state?.page === "service") &&
  //     !isBrochureLoaded);
      
  const isLoading =
    !isMediaLoaded || ((state?.page === "product" || state?.page === "service") && (state?.data?.brochureAvailable ?? false) && !isBrochureLoaded);
      
  console.log("state.page", state,"isLoadingV",isLoading);

  const [disable, setDisable] = useState(false);
  const [micrositeLogo, setMicrositeLogo] = useState(null);
  const [micrositeName, setMicrositeName] = useState(null);
  const reduxGlobalState = useSelector((state) => state?.global);


  const ageCheck = () => {
    const age = localStorage.getItem("age");
    if (age < 18) {
      setDisable(true);
    }
  };

  useEffect(() => {
    ageCheck();
    if (localStorage.getItem("micrositeLogo")) {
      setMicrositeLogo(localStorage.getItem("micrositeLogo"))
    }
    if (localStorage.getItem("micrositeName")) {
      setMicrositeName(localStorage.getItem("micrositeName"))
    }
  }, []);

  const aboutUsTitle = (data) => {
    if (data === "WhyDo") {
      return "Why do we do what we do ?";
    }
    if (data === "whatDo") {
      return "What do we do ?";
    }
    if (data === "howDo") {
      return "How do we do what we do ?";
    }
    return "";
  };
  const handleCardTitle = ({ state }) => {
    let title;

    if (state?.page === "about-us") {
      title = aboutUsTitle(state?.data?.assetTitle);
    } else if (state?.page === "courses" || state?.page === "teams-and-roles") {
      title = state?.data?.subTitle;
    } else if (state?.page === "news_tab") {
      title = state?.data?.title;
    } else {
      title = state?.data?.assetTitle;
    }
    return title;
  };
  const jobTypeTitle = (data) => {
    if (data === "FREELANCING") {
      return "Freelancing";
    }
    if (data === "FULL_TIME") {
      return "Full time";
    }
    if (data === "INTERNSHIP") {
      return "Internship";
    }
    if (data === "PART_TIME") {
      return "Part time";
    }
    if (data === "TEMPORARY") {
      return "Temporary";
    }
    if (data === "REMOTE") {
      return "Remote";
    }
    return "";
  };
  const phoneHandler = () => {
    // localStorage.setItem("tabValue", "contact-us");
    localStorage.setItem("tabValue", "EXPERIENCES_OF_WORK");
    localStorage.setItem("contact-tabValue", "SALES");
    if (state?.page === "work-experience") {
      localStorage.setItem("contact-tabValue", "EXPERIENCES_OF_WORK");
      localStorage.setItem("contact-tabValue", "SALES");
    } else if (
      state?.page === "vacancy" ||
      state?.page === "apprenticeship" ||
      state?.page === "why-work"
    ) {
      localStorage.setItem("contact-tabValue", "HUMAN_RESOURCE");
    } else if (state?.page === "service" || state?.page === "product") {
      localStorage.setItem("contact-tabValue", "SALES");
    }
    navigate(-1);
  };
  const LinkHandler = () => {
    const urlLink = state?.data?.urlLink || state?.data?.webLink;

    if (urlLink) {
      // Create a hidden anchor tag
      const anchor = document.createElement("a");
      anchor.href = urlLink;
      anchor.target = "_blank"; // Open the link in a new tab/window if needed

      // Programmatically trigger a click event on the anchor tag
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      anchor.dispatchEvent(clickEvent);
    } else {
      // Handle the case when urlLink is not defined
      console.error("urlLink is not defined.");
    }
  };


  const handleDetails = (state) => {
    switch (state?.page) {
      case "product": // product and service have same details
      case "service":
        return (
          <div div className="micro-detail-wrapper">
            <div className="micro-detail-container">
              <MicrositeDetailsMedia data={state?.data}   onLoadComplete={() => setIsMediaLoaded(true)} />
              <div className="info-wrapper">
                <InfoCard
                  state={state}
                  handleCardTitle={handleCardTitle}
                  jobTypeTitle={jobTypeTitle}
                  disable={disable}
                  phoneHandler={phoneHandler}
                  LinkHandler={LinkHandler}
                />
                <div className="micro-brochure" style={{
                  justifyContent: state?.data?.brochureAvailable ? 'space-between' : 'flex-end',
                }}>
                {console.log("state?.data?.brochureAvailable",state?.data?.brochureAvailable)}
                  {state?.data?.brochureAvailable && (
                    <ViewAndDownloadBrochure state={state}    onLoadComplete={() => setIsBrochureLoaded(true)} />
                  )}
                  <div className="micro-icons">
                    {state?.page !== "about-us" && (
                      <div className="phone-icon">
                        {/* {alert("phone")} */}
                        <Button
                          //   disabled={disable}
                          className="call-btn"
                          onClick={phoneHandler}
                        >
                          <img src={callIcon} alt="Call" />
                        </Button>
                      </div>
                    )}

                    {state?.data?.urlLink || state?.data?.webLink ? (
                      <div className="phone-icon">
                        <Button
                          className="call-btn"
                          // icon={<LinkOutlined />}
                          onClick={LinkHandler}
                        >
                          <img src={LinkOutlined} alt="LinkOutlined" />
                        </Button>
                      </div>
                    ) : null}


                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div div className="micro-detail-wrapper">
            <div className="micro-detail-container">
              <MicrositeDetailsMedia data={state?.data}  onLoadComplete={() => setIsMediaLoaded(true)}/>
              <div className="info-wrapper">
                {/* {alert("no  phone alert")} */}
                <InfoCard
                  state={state}
                  handleCardTitle={handleCardTitle}
                  jobTypeTitle={jobTypeTitle}
                  disable={disable}
                  phoneHandler={phoneHandler}
                  LinkHandler={LinkHandler}
                />
                
                {/* ======================== */}

                <div className="micro-brochure" style={{
                  justifyContent: state?.data?.brochureAvailable ? 'space-between' : 'cente-r',
                }}>
                {console.log("state?.data?.brochureAvailable",state?.data?.brochureAvailable)}
                  {state?.data?.brochureAvailable && (
                    <ViewAndDownloadBrochure state={state}    onLoadComplete={() => setIsBrochureLoaded(true)} />
                  )}
                  <div className="micro-icons">
                    {state?.page !== "about-us" && (
                      <div className="phone-icon">
                        {/* {alert("phone")} */}
                        <Button
                          //   disabled={disable}
                          className="call-btn"
                          onClick={phoneHandler}
                        >
                          <img src={callIcon} alt="Call" />
                        </Button>
                      </div>
                    )}

                    {state?.data?.urlLink || state?.data?.webLink ? (
                      <div className="phone-icon">
                        <Button
                          className="call-btn"
                          // icon={<LinkOutlined />}
                          onClick={LinkHandler}
                        >
                          <img src={LinkOutlined} alt="LinkOutlined" />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* =============================== */}
                </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="microsite-details-container">
      <div className="microsite-details-body-container">
          <div className="microsite-title-bar-container">
            <span
              className="microsite-back-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </span>
            <div className="microsite-navbar">
              <span className="microsite-brand">
                {(reduxGlobalState?.micrositeDetails?.micrositeLogo || micrositeLogo) && (
                  <img
                    src={reduxGlobalState?.micrositeDetails?.micrositeLogo || micrositeLogo}
                    alt={`${micrositeName} microsite Logo`}
                  />
                )}
              </span>
              {/* <strong className="microsite-brand-title">
                {reduxGlobalState?.micrositeDetails?.micrositeName || micrositeName}
              </strong> */}
            </div>
          </div>
          {isLoading &&    <div
          className="microsite-details-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign:"center",
            flexGrow: "1",
             minWidth:"75vw",
             minHeight:"75vh"
          }}
        >
          <Loader />
        </div>}
          <div style={{ display: !isLoading ? "block" : "none" }}>
          {/* {loading ? <Loader /> : handleDetails(state)} */}
          {handleDetails(state)}
          </div>
        </div>
    </div>
  );
};

export default NewMicrositeDetails;
