import { InfoCircleOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import Loader from "../../components/Loader/Loader";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import SubTabsComponent from "./newUx/subTabs/SubTabs";
const levelIndexMap = {
  "Level 1": 1,
  "Level 2": 2,
  "Level 3": 3,
  "Level 4": 4,
  "Level 5": 5,
  "Level 6": 6,
  "Level 7": 7,
  "Level 8": 8,
  "Entry Level": 0,
};

export default function TeamsAndRoles() {
  const [activeKey] = useState("teams-and-roles");
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [responseData, setResponseData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levels, setLevels] = useState([]);
  const color = [
    "#E75E0D",
    "#EB9F11",
    "#ABC710",
    "#009340",
    "#CC0370",
    "#8366A9",
    "#149BD7",
  ];
  const levelColor = [
    "#ff0000",
    "#ff9900",
    "#332206",
    "#b6d7a8",
    "#00ffff",
    "#0000ff",
    "#9900ff",
    "#6aa84f",
    "#ff00ff",
  ];

  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });

  const getData = async (activeKey, id) => {
    // console.log(id);
    try {
      if (id) {
        const response = await getRequest(
          userapiservice.getMicrositeDetailsTabs(id, activeKey)
        );
        setResponseData(response);
        setLoading(false);
      }
    } catch (error) {
      // Handle error
    }
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const transformedData = responseData?.reduce((acc, item) => {
    const teamEntry = acc.find((entry) => entry.assetTitle === item?.assetTitle);
    const itemCopy = {
      id: item?.id,
      assetTitle: item?.assetTitle,
      subTitle: item?.subTitle,
      available: item?.available,
      description: item?.description,
      type: item?.type,
      mediaLink: {
        id: item?.mediaLink?.id,
        mediaType: item?.mediaLink?.mediaType,
        url: item?.mediaLink?.url,
      },
      levelId: item?.levelId,
      levelInfo: levels.find((level) => level.id === item?.levelId) || null,
    };

    if (teamEntry) {
      teamEntry.subTitles.push(itemCopy);
    } else {
      acc.push({
        assetTitle: item?.assetTitle,
        subTitles: [itemCopy],
      });
    }

    return acc;
  }, []);

  useEffect(() => {
    getData(activeKey, id);
  }, [activeKey, id]);

  const PlayVideoHandler = (url, mediaType) => {
    setMediaType(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setMediaType(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const onCardClickHandler = (data) => {
    navigate(`/microsite/${data.id}/micrositeDetails`, {
      state: { page: activeKey, data: data },
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        // const authToken = localStorage.getItem("token");

        const response = await axios.get(userapiservice.getLevels());

        const data = response.data;
        // console.log(data);
        setLevels(data);
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };

    fetchLevels();
  }, []);
  const levelsData = [
    {
      id: 0,
      value: "Entry Level",
      additionalInfo:
        "Qualifications for Entry Level are:\n Entry Level Award\n Entry Level Certificate (ELC)\n Entry Level diploma\n Entry Level English for speakers of other languages(ESOL)\n Entry Level essential skills\n  Entry Level functional skills\n Skills for Life",
    },
    {
      id: 1,
      value: "Level 1",
      additionalInfo:
        "Level 1 Qualifications are:\n first certificate\n GCSE- grades 3,2,1 or grades D,E,F,G\n Level 1 award\n Level 1 certificate\n Level 1 diploma\n Level 1 ESOL\n Level 1 essential skills\n level 1 functional skills\n level 1 national vocational qualification(NVQ)\n Music grades 1,2 and 3",
    },
    {
      id: 2,
      value: "Level 2",
      additionalInfo:
        "Level 2 Qualifications are:\n CSE- grade 1\nGCSE-grades 9,8,7,6,5,4 or grades A*,A,B,C\n intermediate apprenticeship\n Level 2 award\n Level 2 certificate\n Level 2 diploma\n Level 2 ESOL\n Level 2 essential skills\n Level 2 Functional skills\n Level 2 national certificate\n Level 2 national diploma\n Level 2 NVQ\n Music grades 4 and 5\n O-Level- grade A,B or C ",
    },
    {
      id: 3,
      value: "Level 3",
      additionalInfo:
        "Level 3 Qualifications are:\n A Level\n Access to higher education diploma\n Advanced apprenticeship\n applied general\n AS level\n international Baccalaureate diploma\n Level 3 award\n Level 3 certificate\n Level 3 diploma\n Level 3 ESOL\n Level 3 national certificate\n Level 3 national diploma\n Level 3 NVQ\n music grades 6,7 and 8\n T Level\n Tech level",
    },
    {
      id: 4,
      value: "Level 4",
      additionalInfo:
        "Level 4 Qualifications are:\n Certificate of higher education(CertHE)\n Higher apprenticeship\n Higher national Certificate\nLevel 4 award\n Level 4 certificate\n Level 4 diploma\n n Level 4 NVQ",
    },
    {
      id: 5,
      value: "Level 5",
      additionalInfo:
        "Level 5 qualifications are:\n diploma of higher education (DipHE)\n foundation degree\n higher national diploma (HND)\n level 5 award\n level 5 certificate\n level 5 diploma\n level 5 NVQ",
    },
    {
      id: 6,
      value: "Level 6",
      additionalInfo:
        "Level 6 qualifications are:\n degree apprenticeship\n degree with honours for example bachelor of the arts (BA) hons, bachelor of science (BSc) hons\n graduate certificate\n graduate diploma\n level 6 award\n level 6 certificate\n level 6 diploma\n level 6 NVQ\n ordinary degree without honours",
    },
    {
      id: 7,
      value: "Level 7",
      additionalInfo:
        "Level 7 qualifications are:\n integrated master's degree, for example master of engineering (MEng)\n level 7 award\n level 7 certificate\n level 7 diploma\n level 7 NVQ\n master's degree, for example master of arts (MA), master of science (MSc)\n postgraduate certificate\n postgraduate certificate in education (PGCE)\n postgraduate diploma",
    },
    {
      id: 8,
      value: "Level 8",
      additionalInfo:
        "Level 8 qualifications are:\n doctorate, for example doctor of philosophy (PhD or DPhil)\n level 8 award\n level 8 certificate\n level 8 diploma",
    },
  ];

  const menu = (
    <Menu>
      {levelsData.map((level) => (
        <Menu.Item key={level.id} onClick={(e) => e.stopPropagation()}>
          <Tooltip
            overlay={
              <div>
                {level.additionalInfo.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            }
            placement="topLeft"
            arrowPointAtCenter={false}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: levelColor[level.id],
                  marginRight: "8px",
                  marginLeft: "8px",
                }}
              ></div>
              {level.value}
            </div>
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (loading) {
    return (
      <Loader />
    )
  } else {
    return (
      <div
        className="background-container"  >
        <div className="card-container">
          <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Dropdown
              overlay={menu}
              placement="bottomLeft"
              arrow
              trigger={["click", "hover"]}
            >
              <InfoCircleOutlined style={{ marginRight: 4, color: "green" }} />
            </Dropdown>
            Required qualification level for role
          </div>
          {activeKey === "teams-and-roles" &&
            responseData &&
            responseData.length > 0 ? (
            <SubTabsComponent
              data={transformedData}
              PlayVideoHandler={PlayVideoHandler}
              color={color}
              convertToStandardURL={convertToStandardURL}
              handleCancel={handleCancel}
              isModalOpen={isModalOpen}
              mediaType={mediaType}
              onCardClickHandler={onCardClickHandler}
              videoUrl={videoUrl}
              levels={levels}
              levelIndexMap={levelIndexMap}
              levelColor={levelColor}
            />
          ) : (
            <div className="background-container" style={{ width: "100%" }}>
              <div className="login-wall">
                <div className="info-container">
                  <span>Nothing here at the moment</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {isModalOpen ? (
          <ModalPlayer
            closeModal={handleCancel}
            mediaLink={videoUrl}
            mediaType={mediaType}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
