import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import Loader from "../../components/Loader/Loader";
import CardList from "./newUx/CardList/CardList";
import './news.scss';
import ToastComponent from "../../components/ToastCompoent/ToastComponent";

const News = ({ activeKey = "news_tab" }) => {
    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];
    const id = localStorage.getItem("micrositeID");
    // const [activeKey, setActiveKey] = useState("news_tab");
    const [mediaType, setMediaType] = useState("");

    const [loading, setLoading] = useState(true);
    const [responseData, setReponseData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

    const [textSearch, setTextSearch] = useState('')
    const navigate = useNavigate();

    const onCardClickHandler = (data, id) => {
        if (activeKey === "news_tab") {
            navigate(`/microsite/${data.id}/micrositeDetails`, {
                state: { page: activeKey, data: data },
            });
        }
        else {

            window.location.replace(data.urlLink);
        }
    };

    const getServicesData = async (activeKey, id, searchKeyword) => {
        try {
            setLoading(true);
            // const headers = {
            //   Authorization: `Bearer ${localStorage.getItem("token")}`,
            // };
            if (id) {
                const response = await getRequest(searchKeyword === '' ?
                    userapiservice.getMicrositeDetailsTabs(id, activeKey) :
                    userapiservice.getNewsByDate(id, searchKeyword)
                );
                setReponseData(response);
                // console.log(response)
            }
        } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || error?.message || 'Sorry, Something went wrong'} />)

        }
        finally {
            setLoading(false);
        }
    };

    const getPlaceholder = (activeKey) => {
        switch (activeKey) {
            case 'news_tab':
                return "Search Blog"
            case 'press':
                return "Search Articles"
            default:
                return "Search"
        }
    }

    const PlayVideoHandler = (url, mediaType) => {
        setMediaType(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        setVideoUrl("");
    };

    const convertToStandardURL = (shortUrl) => {
        if (shortUrl?.indexOf('youtu.be') !== -1) {
            const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
            return `https://www.youtube.com/watch?v=${video_id}`;
        }
        return shortUrl;
    }


    useEffect(() => {

        const handler = setTimeout(() => {
            getServicesData(activeKey, id, textSearch)
        }, 500)
        return () => {
            clearTimeout(handler)
        }

    }, [activeKey, id, textSearch]);

    {
        return <div className="news-container" >
            <div className="search-container" >
                <Input
                    // className="title-bar-search"
                    placeholder={getPlaceholder(activeKey)}
                    suffix={
                        <SearchOutlined style={{ color: "#CC0E2D", verticalAlign: "middle" }} />
                    }
                    // type="Date"
                    value={textSearch}
                    onChange={(e) =>
                        setTextSearch(e.target.value)
                    }
                    style={{ maxWidth: '300px' }}
                />
            </div>

            {loading ? <Loader /> : <>
                <CardList
                    responseData={responseData}
                    color={color}
                    PlayVideoHandler={PlayVideoHandler}
                    convertToStandardURL={convertToStandardURL}
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    videoUrl={videoUrl}
                    mediaType={mediaType}
                    onCardClickHandler={onCardClickHandler}
                />
                {
                    !loading && responseData.length === 0
                }
            </>}
        </div>

    }
}

export default News