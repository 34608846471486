import React, { useEffect, useState } from "react";
import SearchBar from "../searchBar/SearchBar";
import "../home/HomePage.scss";
import { Tag, Button, Modal, Layout, Form, Card } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./MapView.scss";
import { getRequest, postRequest } from "../../api/http";
import FiledCircle from "../../assets/images/filled-circle.svg";
import viewIcon from "../../assets/images/ListView.svg";
import userapiservice from "../../api/userapiservice";
import useComponentVisible from "../login/useComponentVisible";
// import MapComponent from "../../components/maps/map";
import MapComponent from "./MapComponent";
// import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import ytPlayBtn from "../../assets/newImages/Play.svg";

import Filter from "../home/filter";
import { motion } from "framer-motion";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import useWindowDimensions from "../../custom-hooks/window";
import axios from "axios";
import ToastComponent from "../../components/ToastCompoent/ToastComponent";

export default function MapView({
  microSitesData,
  radius,
  selectedData,
  expandMap = true,
  cardData,
  previewCardState,
  allowSetView=false,
  disableSetView,
  businessCardHeight,
}) {
  const zoomValue = localStorage.getItem("zoom");
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState(microSitesData);
  // const [radiusValue, setRadiusValue] = useState(
  //   localStorage.getItem("zoom") ? localStorage.getItem("zoom") : 9
  // );
  const [radiusValue, setRadiusValue] = useState(zoomValue);
  //   const [radiusValue, setRadiusValue] = useState(
  //    radius
  //   );

  const [markerData, setMarkerData] = useState([]);
  const [subSectors, setSubSectors] = useState([]);

  const [boolaf, setBoolAf] = useState(false);
  const [boolgl, setBoolGl] = useState(false);
  const [boolms, setBoolMs] = useState(false);
  const [booltz, setBoolTz] = useState(false);

  const [af, setAf] = useState("");
  const [gl, setGl] = useState("");
  const [ms, setMs] = useState("");
  const [tz, setTz] = useState("");

  useEffect(() => {
    setRadiusValue(radius);
  }, [radius]);

  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const partnerId = localStorage.getItem("partnerId");
  const ageID = localStorage.getItem("ageID");
  const profID = localStorage.getItem("profID");

  const [getSector, setGetSector] = useState([]);

  const [organisationTypeName, setOrganisationTypeName] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );
  const [selectedOrganisationType, setSelectedOrganisationType] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );

  const [searchName, setSearchName] = useState(
    localStorage.getItem("searchName")
      ? localStorage.getItem("searchName")
      : null
  );

  const [selectedEvent, setselectedEvent] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );
  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );
  const [sectorId, setSectorId] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  );
  const [subsectorId, setSubsectorId] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  );
  const [size, setSize] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : null
  );
  const [updateFilterData, setUpdateFilterData] = useState(false);
  const [selectedMicrosite, setSelectedMicrosite] = useState(cardData);
  const [cardState, setCardState] = useState(previewCardState);
  const [currentBusinessCardPos,setCurrentBusinessCardPos]= useState({})
  const [test, setTest] = useState(false);
  const [clicked, setClicked] = useState("");

  useEffect(() => {
    setMapData(microSitesData);
  }, [microSitesData]);


  const interest = [
    "",
    "APPRENTICESHIP",
    "VACANCY",
    "PRODUCT",
    "CONTRACTS",
    "SERVICE",
    "WORK_EXPERIENCE",
  ];

  const defaultOrganisationType = [""];
  const [organisationNameById, setOrganisationNameById] = useState("");

  const [partnerData, setPartnerData] = useState({});
  const [dynamicOrganisationTypes, setDynamicOrganisationTypes] = useState([]);

  const getPartnerDetails = async () => {
    try {
      console.log('partner -- 136',partner)
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        setPartnerData(response);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, []);
  useEffect(() => {
    if (clicked === "clicked") {
      setTest(true);
      setClicked("");
    }
  }, [clicked]);

  const organisationType = [
    ...defaultOrganisationType,
    ...dynamicOrganisationTypes,
  ];

  const { height, width } = useWindowDimensions();
  const { Content, Footer } = Layout;
  const navigate = useNavigate();
  const [isShowProfile, setIsShowProfile] = useState(false);
  const { setIsComponentVisible } = useComponentVisible(true, setIsShowProfile);
  const [allResponse, setAllResponse] = useState([]);
  const [selectedZoom, setSelectedZoom] = useState();
  const [manualControl, setManualControl] = useState(false);

  const partner = localStorage.getItem("partner");

  const viewHandler = () => {
    navigate("/home");
  };

  const handleAF = () => {
    if (!boolaf) {
      setAf("A-F");
      setBoolAf(true);
    } else {
      setAf("");
      setBoolAf(false);
    }
  };

  const handleGL = () => {
    if (!boolgl) {
      setGl("G-L");
      setBoolGl(true);
    } else {
      setGl("");
      setBoolGl(false);
    }
  };

  const handleMS = () => {
    if (!boolms) {
      setMs("M-S");
      setBoolMs(true);
    } else {
      setMs("");
      setBoolMs(false);
    }
  };

  const handleTZ = () => {
    if (!booltz) {
      setTz("T-Z");
      setBoolTz(true);
    } else {
      setTz("");
      setBoolTz(false);
    }
  };

  const ChangeModeHandler = (value) => {
    setClicked("clicked");
    // setTest(true)
    setManualControl(true);

    if (value === 2) {
      setSelectedZoom(2);
      if (width <= 375) {
        setRadiusValue(13);
      } else if (width <= 768) {
        setRadiusValue(14);
      } else if (width <= 1024) {
        setRadiusValue(15);
      } else {
        setRadiusValue(15);
      }
    } else if (value === 5) {
      setSelectedZoom(5);
      if (width <= 375) {
        setRadiusValue(12);
      } else if (width <= 768) {
        setRadiusValue(13);
      } else if (width <= 1024) {
        setRadiusValue(14);
      } else {
        setRadiusValue(14);
      }
    } else if (value === 15) {
      setSelectedZoom(15);
      if (width <= 375) {
        setRadiusValue(11);
      } else if (width <= 768) {
        setRadiusValue(12);
      } else if (width <= 1024) {
        setRadiusValue(13);
      } else {
        setRadiusValue(13);
      }
    } else if (value === 25) {
      setSelectedZoom(25);
      if (width <= 375) {
        setRadiusValue(10);
      } else if (width <= 768) {
        setRadiusValue(11);
      } else if (width <= 1024) {
        setRadiusValue(12);
      } else {
        setRadiusValue(11);
      }
    }
  };

  const ChangeRadiusHandler = (value) => {
    !(clicked === "clicked") && setSelectedZoom();
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const zoomPercentage = (mode) => {
    if (width <= 375) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else if (width <= 768) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else if (width <= 1024) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    }
  };

  const onRemoveData = async (
    organisationType,
    interest,
    sectorId,
    subSectorIds,
    size,
    search
  ) => {
    try {
      const response = await getRequest(
        userapiservice.filterMicrosites(
          partner,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size,
          search
        )
      );
      setMarkerData(response.result);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong....",
      });
    }
  };

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(
        userapiservice.getAllSectors(partnerId)
      );
      setGetSector(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSectorList();
  }, []);

  const FindSectorName = (id) => {
    let response = "";
    getSector.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const getSubSector = async (sectorId) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));

      setSubSectors(response);
      // setSubsectorValue(subSectorId);
      setLoading(false);
    } catch (error) {
      toast.error(<ToastComponent type={'Error'} message={error?.error || error?.message || 'Sorry, Something went wrong'} />)
      setLoading(false);
    }
  };

  const FindSubSectorName = (id) => {
    let response = "";
    subSectors.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const deleteSelectedValue = (variableName) => {
    if (variableName === "interest") {
      setselectedEvent(null);
    }
    if (variableName == "organisationType") {
      setSelectedOrganisationType(null);
    }
  };

  const getMarkers = async () => {
    try {
      const response = await getRequest(
        userapiservice.filterMicrosites(
          partner,
          organisationTypeName,
          interestName,
          sectorId,
          subsectorId,
          size,
          searchName,
          af,
          gl,
          ms,
          tz
        )
      );
      if (response) {
        setMarkerData(response?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMarkers();
  }, [af, gl, ms, tz]);

  const RemoveHandler = (value) => {
    localStorage.removeItem(value);
    if (value === "organisationType") {
      localStorage.removeItem("OrganisationType");
      setOrganisationTypeName(null);
      setSelectedOrganisationType(null);
      onRemoveData(
        null,
        selectedEvent,
        sectorId,
        subsectorId,
        size,
        searchName
      );
    }
    if (value === "interest") {
      localStorage.removeItem("Interest/AssetType");
      localStorage.removeItem("tabValue");
      setselectedEvent(null);
      setinterestName(null);
      onRemoveData(
        selectedOrganisationType,
        null,
        sectorId,
        subsectorId,
        size,
        searchName
      );
    }
    if (value === "sector") {
      setSectorId(null);
      setSubsectorId(null);
      localStorage.removeItem("subsector");
      onRemoveData(
        selectedOrganisationType,
        selectedEvent,
        null,
        null,
        size,
        searchName
      );
    }
    if (value === "subsector") {
      onRemoveData(
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        null,
        size,
        searchName
      );
      setSubsectorId(null);
    }
    if (value === "size") {
      onRemoveData(
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        subsectorId,
        null,
        searchName
      );
      setSize(null);
    }
    if (value === "searchName") {
      onRemoveData(
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        subsectorId,
        size,
        null
      );
      setSearchName(null);
    }
    setUpdateFilterData(!updateFilterData);
  };

  const selectedOrg = (data, state,businessCardPos) => {
    setCardState(state);
    setSelectedMicrosite(data);
    selectedData(data);
                      setCurrentBusinessCardPos(businessCardPos);
  };

  const OnCardClickHandler = async (id, logo, name) => {
    const formattedName = name.replace(/ /g, "_");
    localStorage.setItem("micrositeName", formattedName);
    navigate(`/microsite/${formattedName}`, {
      state: { page: "map", logo: { logo } },
    });
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  useEffect(() => {
    setLoading(true);
    // fetchMicrosites(selectedEvent, sectorId, subsectorId, size);
    FindSectorName();
    FindSubSectorName();
  }, []);

  useEffect(() => {
    if (sectorId) {
      getSubSector(sectorId);
    }
    localStorage.setItem("Page", "view");
  }, [sectorId]);

  // CREATE PROFILE CODE
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    if (createdUser != "created") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    if (createdUser != "created") {
      if (latitude === "" || longitude === "") {
        // this is to stop fetching data on component mount, cause we have no Latitude and Longitude YET
        return;
      } else {
        async function getWeather() {
          let res = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=geojson&zoom=8&lat=${latitude}&lon=${longitude}`
          );
          // https://nominatim.org/release-docs/develop/api/Reverse/#example-with-formatgeojson
          // For more info on the OSM API, refer the link mentioned above

          localStorage.setItem(
            "country",
            res.data?.features[0]?.properties?.address?.country
          );
          if (res.data?.features[0]?.properties?.address?.county) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.county
            );
          } else if (
            res.data?.features[0]?.properties?.address?.state_district
          ) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.state_district
            );
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.municipality
            );
          } else if (res.data?.features[0]?.properties?.address?.town) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.town
            );
          } else {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.city
            );
          }
          localStorage.setItem(
            "state",
            res.data?.features[0]?.properties?.address?.state
          );

          if (res.data?.features[0]?.properties?.address?.county) {
            setCounty(res.data?.features[0]?.properties?.address?.county);
          } else if (
            res.data?.features[0]?.properties?.address?.state_district
          ) {
            setCounty(
              res.data?.features[0]?.properties?.address?.state_district
            );
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            setCounty(res.data?.features[0]?.properties?.address?.municipality);
          } else if (res.data?.features[0]?.properties?.address?.town) {
            setCounty(res.data?.features[0]?.properties?.address?.town);
          } else {
            setCounty(res.data?.features[0]?.properties?.address?.city);
          }
          // setCounty(res.data?.features[0]?.properties?.address?.county);
          setCountry(res.data?.features[0]?.properties?.address?.country);
          setState(res.data?.features[0]?.properties?.address?.state);
        }
        getWeather();
      }
    }
  }, [latitude, longitude]);

  // const sendUserDetails = async () => {
  //   try {
  //     const response = await postRequest(userapiservice.sendDetails(), {
  //       ageGroupId: ageID,
  //       tagId: profID, // make dynamic
  //       latitude: latitude,
  //       longitude: longitude,
  //       countryName: country,
  //       countyName: county,
  //       state: state,
  //       partnerId: partnerId,
  //     });
  //     if (response) {
  //       localStorage.setItem("user", "created");
  //     }
  //     // console.log("res", response)
  //   } catch (error) {
  //     console.error("error in");
  //   }
  // };

  // useEffect(() => {
  //   if (latitude && longitude && country && county && state) {
  //     sendUserDetails();
  //   }
  // }, [county]);


  if (mapData) {
    return (
      <Layout className="layout">
        <Content>
          {/* Map component */}
          {mapData && (
            <div className="map-container">
              <MapComponent
              disableSetView={disableSetView}
                // interest={selectedEvent}
                // sector={sectorId}
                // subsector={subsectorId}
                // sizeId={size}
                // radiusValue={radiusValue}
                radiusValue={radiusValue}
                ChangeRadiusHandler={ChangeRadiusHandler}
                // setMarkerData={setMarkerData}
                markerData={mapData}
                selectedOrg={selectedOrg}
                activeMiniPopup={selectedMicrosite}
                allowSetView={allowSetView}
                // closePopup={closePopup}
              />
              {/* <Map /> */}
            </div>
          )}

          {/* Floating card component */}
          <motion.div
            className="card-container-view"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            {selectedMicrosite && cardState ? (
              // <div style={{top:currentBusinessCardPos?.y >=businessCardHeight ? `${currentBusinessCardPos?.y - businessCardHeight }px`  :  `${currentBusinessCardPos?.y+20}px` || '50%'}} className="previewCard">
              <div style={{top:currentBusinessCardPos?.y >=businessCardHeight ? `${businessCardHeight/2 + 20 }px`  :  `${currentBusinessCardPos?.y+20}px` || '50%'}} className="previewCard">             
                <Card
                  className="card-item home-page-card-item card-hover map-card"
                  bordered={false}
                >
                  <div className="image-container">
                    {selectedMicrosite?.carouselLink?.mediaType ===
                      "SOCIAL_MEDIA" && selectedMicrosite?.carouselLink?.url ? (
                      <>
                        <div
                          className="media youtube"
                          style={{ objectFit: "contain" }}
                          onClick={() =>
                            PlayVideoHandler(
                              selectedMicrosite?.carouselLink?.url,
                              selectedMicrosite?.carouselLink?.mediaType
                            )
                          }
                        >
                          {(() => {
                            const standardURL = convertToStandardURL(
                              selectedMicrosite?.carouselLink?.url
                            );
                            const videoID = new URLSearchParams(
                              new URL(standardURL).search
                            ).get("v");
                            return (
                              <>
                                <img
                                  src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                  style={{ height: "90%", borderRadius: "8px" }}
                                  alt=""
                                  className="video-preview-image"
                                />
                                <img
                                  className="ytBtnOverlay"
                                  src={ytPlayBtn}
                                  onClick={() =>
                                    PlayVideoHandler(
                                      selectedMicrosite?.carouselLink?.url,
                                      selectedMicrosite?.carouselLink?.mediaType
                                    )
                                  }
                                />
                              </>
                            );
                          })()}
                        </div>
                      </>
                    ) : selectedMicrosite?.carouselLink?.mediaType ===
                        "VIMEO_MEDIA" &&
                      selectedMicrosite?.carouselLink?.url ? (
                      <div
                        className="media vimeo youtube"
                        onClick={() => {
                          PlayVideoHandler(
                            selectedMicrosite?.carouselLink?.url,
                            selectedMicrosite?.carouselLink?.mediaType
                          );
                        }}
                      >
                        <img
                          src={`https://vumbnail.com/${
                            selectedMicrosite?.carouselLink?.url?.split("/")[4]
                          }.jpg`}
                          className="video-preview-image"
                          style={{ height: "90%", borderRadius: "8px" }}
                        />
                        <img className="ytBtnOverlay" src={ytPlayBtn} />
                      </div>
                    ) : (
                      <div className="media">
                        <div className="slider-Img">
                          <img
                            src={selectedMicrosite?.carouselLink?.url}
                            alt=""
                            className="home-preview-img"
                            style={{ height: "90%", borderRadius: "8px" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-container">
                    <span className="microsite-position text-bold">
                      <img
                        src={selectedMicrosite?.micrositeLogo}
                        alt={selectedMicrosite?.name}
                        className="preview-image"
                        onClick={() => {
                          OnCardClickHandler(
                            selectedMicrosite?.id,
                            selectedMicrosite?.micrositeLogo,
                            selectedMicrosite?.name
                          );
                        }}
                      />
                    </span>
                    <CloseOutlined
                      className="closeIcon"
                      onClick={() => {
                        setCardState(false);

                        setSelectedMicrosite({closePopup:true});
                        // setSelectedMicrosite({});

                        selectedData({});
                      }}
                    />
                    <div
                      className="sector-info-container"
                      onClick={() => {
                        OnCardClickHandler(
                          selectedMicrosite?.id,
                          selectedMicrosite?.micrositeLogo,
                          selectedMicrosite?.name
                        );
                      }}
                    >
                      <span className="microsite-details text-bold">
                        {selectedMicrosite?.businessDescription}
                      </span>
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </motion.div>
          {isModalOpen ? (
            <ModalPlayer
              closeModal={handleCancel}
              mediaLink={videoUrl}
              mediaType={mediatype}
            />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    );
  }
}
