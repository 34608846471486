import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { setIsShared, setPartnerData } from "./Reducer/globalSlice";

import { AnimatePresence, motion } from "framer-motion";
import { getRequest } from "./api/http";
import userapiservice from "./api/userapiservice";
import { GlobalContext } from "./components/globalContext/GlobalContext";
import Loader from "./components/Loader/Loader";
import ProtectedRoutes from "./Routes/ProtectedRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import { privateRoutes, publicRoutes } from "./Routes/routes";

function AnimatedRoutes() {
  const location = useLocation();

  const dispatch = useDispatch();
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const [Loading, setLoading] = useState(true)

  const getPartnerDetails = async (partnerName) => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partnerName)
      );
      if (response) {
        localStorage.setItem('partner-latitude', response?.latitude)
        localStorage.setItem('partner-longitude', response?.longitude)
        localStorage.setItem("partnerID", response.id);

        dispatch(setPartnerData(response))
        // set partnerId in global context also
        setGlobalState({
          ...globalState,
          "partnerID": response.id,
          "partnerName": response.name
        })
      }
    } catch (e) {
      console.log('error fetching Partner Details', e)
    }
  };

  function isMicrositePath() {
    const pattern = /^\/microsite\/[a-zA-Z0-9_-]+$/;
    return pattern.test(window.location.pathname);
  }

  useEffect(() => {
    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search);
    // handle if site is NOT shared 
    if (!urlParams.get('partnerId') || !isMicrositePath()) {
      if (localStorage.getItem('sharedSite') === true) {
        dispatch(setIsShared(true))
      }
      else {
        localStorage.setItem('sharedSite', false)
        dispatch(setIsShared(false))
      }
    }
    // handle setting params from shared site
    if (urlParams.get('partnerId')) {
      localStorage.setItem('partnerId', urlParams.get('partnerId'))

      // set IsShared as true to hide footer in microsite and micrositeDetails
      // consider as shared; only if shared site is microsite/:id 
      // all other screens no need to set IsShared to true 
      if (isMicrositePath()) {
        dispatch(setIsShared(true))
        localStorage.setItem('sharedSite', true)
      }
    }
    if (urlParams.get('partnerName')) {
      getPartnerDetails(urlParams.get('partnerName'))
      localStorage.setItem('partnerName', urlParams.get('partnerName'))
      localStorage.setItem('partner', urlParams.get('partnerName'))
    }
    setLoading(false)
  }, [])

  return (
    <AnimatePresence>
      {Loading ? <Loader /> :
        <Routes location={location} >
          <Route element={<ProtectedRoutes />} key={"ProtectedRoutes"}>
            {publicRoutes?.map((route, index) => {
              return (
                <Route
                  id={`${index + route.path}`}
                  key={`${index + route.path}`}
                  path={route?.path}
                  element={<PageWrapper>{route.component}</PageWrapper>}
                />
              );
            })}
          </Route>

          <Route element={<PublicRoutes />} key={"PublicRoutes"}>
            {privateRoutes.map((route, index) => {
              return (
                <Route
                  id={`${index + route.path}`}
                  key={`${index + route.path}`}
                  path={`/${route.path}`}
                  element={<PageWrapper>{route.component}</PageWrapper>} />
              );
            })}
          </Route>
        </Routes>}
    </AnimatePresence>
  );
}

const PageWrapper = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export default AnimatedRoutes;
