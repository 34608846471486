import React from "react";
import { Spin } from "antd";
import './Loader.scss'

const Loader = () => {
  return (
    <div className="loader-container">
      <Spin tip="Loading" size="large" />
    </div>
  );
};

export default Loader;
