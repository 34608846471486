import React from "react";

import LoginPage from "../screens/login/LoginPage";
import OtpPage from "../screens/otp/OtpPage";
import PartnerPage from "../screens/partnerScreen/PartnerPage";
import HomePage from "../screens/home/HomePage";
import EditProfilePage from "../screens/editProfile/EditProfile";
import ViewPage from "../screens/View/View";
import MicrositePage from "../screens/microsites/microsite";
import MicrositeDetailsPage from "../screens/microsites/micrositeDetails";
import OnboardingPage from "../screens/onboarding/OrgOnboarding";
import TermsPage from "../screens/onboarding/terms";
import FavoritesPage from "../screens/favorites/Favorites";
import Nav from "../components/NavigateComponent/Navigate";
import HelpPage from "../screens/login/Help";
import FeedBackPage from "../screens/login/Feedback";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import MicroSites from "../screens/home/MicroSites";
import NewMicrosite from "../screens/microsites/newUx/NewMicrosite";
import NewMicrositeDetails from "../screens/microsites/newUx/NewMicrositeDetails";

const publicRoutes = [
  {
    path: "login",
    component: <LoginPage />,
  },
  {
    path: "help",
    component: <HelpPage />,
  },
  {
    path: "feedback",
    component: <FeedBackPage />,
  },
  {
    path: "verifyOtp",
    component: <OtpPage />,
  },
  {
    path: "frontend/:partner",
    component: <PartnerPage />,
  },
  {
    path: "home",
    component: <MicroSites />,
  },
  {
    path: "edit",
    component: <EditProfilePage />,
  },
  {
    path: "view",
    component: <ViewPage />,
  },

  {
    path: "onboarding",
    component: <OnboardingPage />,
  },
  {
    path: "terms",
    component: <TermsPage />,
  },
  // {
  //   path: "favorites",
  //   component: <FavoritesPage />,
  // },

  {
    path: "",
    component: <Nav />,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
  {
    path: "microsite",
    component: <MicroSites />,
  },
  // {
  //   path: "test",
  //   component: <Test />,
  // },
];

const privateRoutes = [
  {
    path: "microsite/:id",
    component: <NewMicrosite />,
  },
  {
    path: "microsite/:id/micrositeDetails",
    // component: <MicrositeDetailsPage />,
    component: <NewMicrositeDetails />,
  },
];

export { privateRoutes, publicRoutes };
