import React from 'react'
import search from "../../assets/newImages/Search2.svg"
import filter from "../../assets/newImages/Filter.svg"
import filterSlider from "../../assets/newImages/FilterSlider.svg"
import filterWhite from "../../assets/newImages/FilterWhite.svg"
import help from "../../assets/newImages/Help.svg"
import helpActive from "../../assets/newImages/HelpActive.svg"
import home from "../../assets/newImages/Home.svg"
import homeActive from "../../assets/newImages/HomeActive.svg"
import support from "../../assets/newImages/Support.svg"
import supportActive from "../../assets/newImages/SupportActive.svg"
import menu from "../../assets/newImages/Menu.svg"
import calendar from "../../assets/newImages/calendar.svg"
import share from "../../assets/newImages/Share.svg"
import profileClose from "../../assets/newImages/ProfileClose.svg"
import closeActive from "../../assets/newImages/CloseActive.svg"
import user from "../../assets/newImages/User.svg"
import next from "../../assets/newImages/Next.svg"
import previous from "../../assets/newImages/Previous.svg"
import back from '../../assets/newImages/Back_Black.svg'
import fileUpload from '../../assets/newImages/FileUpload.svg'
import favorite from '../../assets/newImages/Favorite.svg'
import favoriteActive from '../../assets/newImages/FavoriteActive.svg'
import tabLeftArrow from '../../assets/newImages/TabLeftArrow.svg'
import tabRightArrow from '../../assets/newImages/TabRightArrow.svg'
import addOrganization from '../../assets/newImages/AddOrganization.svg'
import addOrganizationActive from '../../assets/newImages/AddOrganizationActive.svg'
import toastError from '../../assets/newImages/ToastError.svg'

import './icons.scss'

export const Search = () => {
    return (
        <img src={search} className='icon-image' alt="search icon" />
    )
}

export const Filter = () => {
    return (
        <img src={filter} className='filter icon-image' alt="Filter icon" />
    )
}
export const FilterSlider = () => {
    return (
        <img src={filterSlider} className='filter-slider icon-image' alt="Filter icon" />
    )
}
export const FilterWhite = () => {
    return (
        <img src={filterWhite} className='icon-image' alt="Filter icon" />
    )
}

export const Help = () => {
    return (
        <img src={help} className='icon-image footer-help' alt="Help icon" />
    )
}

export const HelpActive = () => {
    return (
        <img src={helpActive} className='icon-image footer-help' alt="Help icon" />
    )
}

export const Home = () => {
    return (
        <img src={home} className='icon-image footer-home' alt="Home icon" />
    )
}
export const HomeActive = () => {
    return (
        <img src={homeActive} className='icon-image footer-home' alt="Home icon" />
    )
}

export const Support = () => {
    return (
        <img src={support} className='icon-image footer-support' alt="Support icon" />
    )
}

export const SupportActive = () => {
    return (
        <img src={supportActive}
            className='icon-image footer-support' alt="Support icon" />
    )
}

export const Menu = () => {
    return (
        <img src={menu} className='nav-bar-menu icon-image' alt="Menu icon" />
    )
}

export const Calendar = () => {
    return (
        <img src={calendar} className='icon-image' alt="date" />
    )
}

export const Share = () => {
    return (
        <img src={share} className='icon-image footer-share' alt="Share icon" />
    )
}
export const ProfileClose = () => {
    return (
        <img src={profileClose} style={{ width: '13px', height: '13px' }} className='icon-image' alt="ProfileClose icon" />
    )
}

export const CloseActive = () => {
    return (
        <img src={closeActive} className='close-active icon-image' alt="Close icon" />
    )
}

export const User = () => {
    return (
        <img src={user} className='icon-image nav-bar-user' alt="user icon" />
    )
}
export const Next = () => {
    return (
        <img src={next} className='icon-image next-arrow' alt="user icon" />
    )
}
export const Previous = () => {
    return (
        <img src={previous} className='icon-image previous-arrow' alt="user icon" />
    )
}
export const Back = () => {
    return (
        <img src={back} className='icon-image black-back-btn' alt="user icon" />
    )
}
export const FileUpload = () => {
    return (
        <img src={fileUpload} style={{ width: '70px', height: '70px' }} className='icon-image' alt="user icon" />
    )
}
export const Favorite = () => {
    return (
        <img src={favorite} style={{ width: '30px', height: '30px' }} className='icon-image' alt="user icon" />
    )
}
export const FavoriteActive = () => {
    return (
        <img src={favoriteActive} style={{ width: '30px', height: '30px' }} className='icon-image' alt="user icon" />
    )
}

export const AddOrganization = () => {
    return (
        <img src={addOrganization} style={{ width: '30px', height: '30px' }} className='icon-image' alt="Add Organization" />
    )
}

export const AddOrganizationActive = () => {
    return (
        <img src={addOrganizationActive} style={{ width: '30px', height: '30px' }} className='icon-image' alt="Add Organization" />
    )
}

export const TabRightArrow = ({customClassName}) => {
    return (
        <img src={tabRightArrow}   className={`${customClassName ? customClassName : 'icon-image tab-arrows'}`} alt="tab right arrow" />
    )
}

export const TabLeftArrow = ({customClassName}) => {
    return (
        <img src={tabLeftArrow}  className={`${customClassName ? customClassName : 'icon-image tab-arrows'}`} alt="tab left arrow" />
    )
}

export const ToastError = ({customClassName}) => {
    return (
        <img src={toastError}  className={`${customClassName ? customClassName : 'icon-image'}`} alt="Error" />
    )
}