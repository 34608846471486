import React from "react";
import { Modal } from "antd";
import "./playerModal.scss"


export default function ModalPlayer({ closeModal, mediaLink, mediaType }) {

    function getVideoId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    // console.log("url", getVideoId(mediaLink))

    return (
        <Modal
            className="video-modal"
            open={true}
            footer={null}
            onCancel={() => closeModal()}
            // width={350}
            destroyOnClose={true}
        >
            {mediaType === "SOCIAL_MEDIA" ?
                (
                    <div className="preview-secondary-player">
                        <iframe
                            controls={true}
                            loading="lazy"
                            src={`https://www.youtube.com/embed/${getVideoId(mediaLink)}?autoplay=1`}
                            height="100%"
                            width="100%"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ background: "black" }}
                        />
                    </div>
                ) : (
                    <div className="preview-secondary-player">
                        <iframe
                            controls={true}
                            src={mediaLink}
                            // onEnded={() => setVideoCompleted(true)}
                            height="100%"
                            width="100%"
                            style={{ background: "black" }}
                            allowFullScreen
                            allow="autoplay"
                        ></iframe>
                    </div>
                )
            }
        </Modal >
    )
} 