import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import axios from "axios";
import { Button, Form, Modal, Select, Radio, Row, Col, Input,Upload } from "antd";
import { UploadOutlined,EditOutlined } from '@ant-design/icons';
import closeIcon from "../../assets/images/closecross.svg";
import "../editProfile/EditProfile.scss";
import { getRequest, putRequest, postRequest } from "../../api/http";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import SearchBarHelp from "../login/searchHelp";
import username from "../../assets/images/User.svg";
import profilepic from "../../assets/images/profile-pic.svg"
import phoneProfile from "../../assets/images/Phone-Icon.svg";
import mail from "../../assets/images/email.svg";
import ethnicityIcon from "../../assets/images/ethnicity.svg";
import ageIcon from "../../assets/images/age.svg";
import proftag from "../../assets/images/Tag.svg";
import gender from "../../assets/images/gender.svg";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import BackArrow from "../../assets/newImages/Back.svg"
import { Back } from "../../components/Icons/Icons";
import userImg from '../../assets/newImages/User.svg'
import ToastComponent from "../../components/ToastCompoent/ToastComponent";
import { toastConfig } from "../../utils/util";

export default function EditProfilePage() {
  // console.log()
  const navigate = useNavigate();
  // console.log("onentry", show);
  const [form] = Form.useForm();
  const [age, setAge] = useState(
    localStorage.getItem("age") ? localStorage.getItem("age") : ""
  );
  const { endUserDetails, setEndUserDetails,profileUpdated, setProfileUpdated } = useContext(GlobalContext);
  const [profileData, setProfileData] = useState();
  const [profilePicData, setProfilePicData] = useState(null);
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupData, setAgeGroupsData] = useState([]);
  const [selectedAgeGroupsData, setSelectedAgeGroupsData] = useState();
  const [ethnicity, setEthnicity] = useState();
  const [viewProfile, setViewProfile] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [isActive, setActive] = useState("false");
  const [userId, setUserId] = useState();
  const [profilePic, setProfilePic] = useState(null);
  const [existingProfilePic,setExistingProfilePic] = useState(null)

  const is1920 = useMediaQuery({ width: 1920 });
  const is1366 = useMediaQuery({ width: 1366 });
  const is1240 = useMediaQuery({ width: 1024 });
  const is1680 = useMediaQuery({ width: 1680 });
  const is1600 = useMediaQuery({ width: 1600 });
  const is1280 = useMediaQuery({ width: 1280 });
  const is800 = useMediaQuery({ width: 800 });
  const is820 = useMediaQuery({ width: 820 });
  const is768 = useMediaQuery({ width: 768 });
  const is320 = useMediaQuery({ width: 320 });
  const is375 = useMediaQuery({ width: 375 });
  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });
  const isMobileScreenExcept320 = useMediaQuery({
    minWidth: 375,
    maxWidth: 767,
  });

  const columnGap = is1920 ? 4 : is768 || is820 ? 8 : 6;
  const EditSectionColumnGap =
    is1920 ? 5 : is820 || is768 ? 14 : is1366 ? 8 : 10;

  const handleProfilePicUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) {
      setProfilePic(URL.createObjectURL(file));
        setExistingProfilePic(URL.createObjectURL(file)); 
      
      setProfilePicData({
        file: file,
        mediaProperty: {
          id: generateRandomId(),
          name: file.name,
          extension: file.name.split(".").pop(),
        },
        mediaType: "IMAGE", 
      });
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      // message.error('You can only upload image files!');
    }
    
    const isLt2M = file.size / 1024 / 1024 < 2; // Limit size to 2MB
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
    }

    return isImage && isLt2M;
  };


  const generateRandomId = () => {
    return `${Math.random().toString(36).substring(2, 15)}-${Math.random()
      .toString(36)
      .substring(2, 15)}`; // Generate a random ID
  };

  const getProfileData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await getRequest(
        userapiservice.getEndUserDetails(),
        headers
      );
      if (response) {
        console.log("responseOfProfile", response);
        setProfileData(response);
        // setUserId(response.id);
        if (response?.ageGroup) {
          getAgeData(response?.ageGroup?.id);
          setSelectedAgeGroupsData(response?.tag?.id);
          // setSelectedAgeGroupsData(response?.tag?.userTag);
        }
      }
    } catch (e) {
      Modal.error({
        title: "profile Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeGroups = async () => {
    try {
      const response = await getRequest(userapiservice.getEndUserAgeGroups());
      setAgeGroups(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeData = async (value) => {
    try {
      const response = await getRequest(userapiservice.getWhatDoYouDo(value));
      setAgeGroupsData(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };
  useEffect(() => {
    form.resetFields();
  }, [profileData]);

  

  const submitHandler = async (data) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
  
    const formData = new FormData();
  
    let endUserDetailsInput = {
      ...data,
    };
  
    if (profilePicData?.file) {
      formData.append("media", profilePicData.file, profilePicData.file.name);
  
      endUserDetailsInput = {
        ...endUserDetailsInput,
        media: {
          mediaProperty: {
            id: profilePicData.mediaProperty.id,
            name: profilePicData.mediaProperty.name,
            extension: profilePicData.mediaProperty.extension,
          },
          mediaType: profilePicData.mediaType,
        },
      };
    }
  
    const endUserDetailsInputBlob = new Blob(
      [JSON.stringify(endUserDetailsInput)],
      { type: "application/json" }
    );
    formData.append("endUserDetailsInput", endUserDetailsInputBlob);
  
  const parsedBlobContent = JSON.parse(await endUserDetailsInputBlob.text());

  const formDataObject = {};
  formData.forEach((value, key) => {
    formDataObject[key] =
      value instanceof Blob ? parsedBlobContent : value instanceof File ? value.name : value;
  });

  setEndUserDetails(formDataObject);

    try {
      const response = await putRequest(
        userapiservice.getEndUserDetails(),
        formData,
        headers
      );   
  
      if (response) {
        setProfileUpdated(true);
        const AGEname =
          ageGroups?.find((ageGroup) => ageGroup.id === data.ageId)?.ageGroup ||
          "";
        setLocalAge(AGEname, data.ageId);
        localStorage.setItem("profID", response?.tag?.id);
        // localStorage.setItem("profID", data.tagId);
        // if (!localStorage.getItem("profID")) {
        //   localStorage.setItem("profID", data.tagId)
        // }
        const name =
          ageGroupData?.find((tagObj) => tagObj?.tag?.id === data.tag)?.tag
            ?.userTag || "";
            localStorage.setItem("prof", response?.tag?.userTag);
        // localStorage.setItem("prof", name);
        // if (!localStorage.getItem("prof")) {
        //   localStorage.setItem("prof", name);
        // }
        getAgeGroups();
        getProfileData();
        setViewProfile(true);
        toast.success(<ToastComponent type={'Success'} message={'Profile Updated Successfully'} />)
        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
      toast.error(<ToastComponent type={'Error'} message={error?.message || 'Failed to update profile'} />)
    }
  };
  
  
  const ageGroupDataChange = async (groupData) => {
    setSelectedAgeGroupsData(groupData);
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const setLocalAge = (value, id) => {
    if (value === "< 18") {
      setAge(18);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 18);
    } else if (value === "19 - 23") {
      setAge(23);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 23);
    } else if (value === "24 - 37") {
      setAge(37);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 37);
    } else if (value === "38 - 53") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    } else if (value === "54+") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    }
  };

  const setEthnicityHandler = (e) => {
    // console.log(e)
    setEthnicity(e);
  };

  const handleEditDetailsClick = async () => {
    getAgeGroups();
    await getProfileData();
    setViewProfile(false);
  };

  useEffect(() => {
    getAgeGroups();
    getProfileData();
  }, []);


  useEffect(()=>{
    const getProfilePic= async ()=>{
       try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await getRequest(
          userapiservice.getEndUserDetails(),
          headers
        );

        if(response){
          console.log("vresp",response)
          setExistingProfilePic(response?.mediaLink)
        }
       } catch (error) {
        
       }
    }

    getProfilePic()

  },[])

  return (
    <div className="wrapper-edit-profile">
      {viewProfile ? (
        // <SearchBarHelp viewProfile="View Profile" />
        <div className="edit-profile-header">
          <div
            className="edit-profile-backarrow"
            onClick={() => navigate("/frontend/Partner_Name")}
          >
            {/* <img src={Back} /> */}
            {!isSmallScreen && <Back/>}
            Profile
          </div>
          <div
            className="edit-detail-button-container"
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button
              className="edit-details-button"
              onClick={handleEditDetailsClick}
            >
              Edit Details
            </button>
          </div>
        </div>
      ) : (
        <div className="edit-profile-backarrow" onClick={() => navigate(-1)}>
          {/* <img src={BackArrow} /> */}
          {!isSmallScreen && <Back/>}
          Edit Profile
        </div>
      )}

      <div className="profile-card-container">
        {viewProfile ? (
          <div className="partner-details">
            <div className="col-1 w-100" style={{ width: "100%" }}>
              {profileData?.mediaLink && (
                <Row span={24} className="w-100 row-spacing">
                  <Col
                    className="gutter-row partner-info-label"
                    xs={is320 ? 8 : 10}
                    md={columnGap}
                  >
                    <img src={profilepic} alt="profile-pic"></img>
                    <label style={{ whiteSpace: "nowrap" }}>
                      Profile Picture:
                    </label>
                  </Col>
                  <Col className="gutter-row partner-info-value">
                    {profileData?.mediaLink ? (
                      <img
                        src={profileData?.mediaLink}
                        alt="User Profile"
                        className="user-profile-pic"
                        // style={{width:"2vw"}}
                      />
                    ) : (
                      <p>No profile picture uploaded</p>
                    )}
                  </Col>
                </Row>
              )}
              <Row span={24} className="w-100 row-spacing">
                <Col
                  className="gutter-row partner-info-label"
                  xs={is320 ? 8 : 10}
                  md={columnGap}
                >
                  <img src={username} alt="username"></img>
                  <label>Username:</label>
                </Col>
                <Col className="gutter-row partner-info-value">
                  <p>{profileData?.userName ? profileData?.userName : ""}</p>
                </Col>
              </Row>
              <Row span={24} className="w-100 row-spacing">
                <Col
                  className="gutter-row partner-info-label"
                  xs={is320 ? 8 : 10}
                  md={columnGap}
                >
                  <img src={mail} alt="mail"></img>
                  <label>Email:</label>
                </Col>
                <Col className="gutter-row partner-info-value">
                  <p>{profileData?.email ? profileData?.email : ""}</p>
                </Col>
              </Row>
              <Row span={24} className="w-100 row-spacing">
                <Col
                  className="gutter-row partner-info-label"
                  xs={is320 ? 8 : 10}
                  md={columnGap}
                >
                  <img src={phoneProfile} alt="phone"></img>
                  <label>Phone:</label>
                </Col>
                <Col className="gutter-row partner-info-value">
                  <p>
                    {profileData?.phoneNumber ? profileData?.phoneNumber : ""}
                  </p>
                </Col>
              </Row>
              <Row span={24} className="w-100 row-spacing">
                <Col
                  className="gutter-row partner-info-label"
                  xs={is320 ? 8 : 10}
                  md={columnGap}
                >
                  <img src={ageIcon} alt="age"></img>
                  <label>Age:</label>
                </Col>
                <Col
                  className="gutter-row partner-info-value"
                  xs={is320 ? 8 : 10}
                  md={columnGap}
                >
                  <p>
                    {profileData?.ageGroup?.ageGroup
                      ? profileData?.ageGroup?.ageGroup
                      : ""}
                  </p>
                </Col>
              </Row>
              {profileData?.gender && (
                <Row span={24} className="w-100 row-spacing">
                  <Col
                    className="gutter-row partner-info-label"
                    xs={is320 ? 8 : 10}
                    md={columnGap}
                  >
                    <img src={gender} alt="gender"></img>
                    <label>Gender:</label>
                  </Col>
                  <Col className="gutter-row partner-info-value">
                    <p>{profileData?.gender ? profileData?.gender : ""}</p>
                  </Col>
                </Row>
              )}
              {profileData?.ethnicity && (
                <Row span={24} className="w-100 row-spacing">
                  <Col
                    className="gutter-row partner-info-label"
                    xs={is320 ? 8 : 10}
                    md={columnGap}
                  >
                    <img src={ethnicityIcon} alt="ethnicity"></img>
                    <label>Ethnicity:</label>
                  </Col>
                  <Col className="gutter-row partner-info-value">
                    <p>
                      {profileData?.ethnicity ? profileData?.ethnicity : ""}
                    </p>
                  </Col>
                </Row>
              )}
              {profileData?.tag?.id && (
                <Row span={24} className="w-100 row-spacing">
                  <Col
                    className="gutter-row partner-info-label"
                    xs={is320 ? 8 : 10}
                    md={columnGap}
                  >
                    <img src={proftag} alt="proftag"></img>
                    <label>What do you do?:</label>
                  </Col>
                  <Col className="gutter-row partner-info-value">
                    <p>
                      {profileData?.tag?.userTag
                        ? profileData?.tag?.userTag
                        : ""}
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: !isSmallScreen && "5px",
              marginTop: !isSmallScreen && "10px",
              paddingLeft: !isSmallScreen && "5px",
              paddingRight: "5px",
              // marginTop: !isSmallScreen && "0px",
              marginTop: is1920
                ? "-15px"
                : is1366
                ? "-3px"
                : is1240
                ? "-4px"
                : is768 || is820
                ? "-4px"
                : !isSmallScreen && "0px",
              marginLeft: isSmallScreen ? "10px" : "20px",
            }}
          >
            <Row xs={24} md={18}>
              <Col xs={24} md={24}>
                {Object.keys(profileData).length > 0 ? (
                  <Form
                    name="editForm"
                    form={form}
                    onFinish={submitHandler}
                    className="ProfileForm"
                    initialValues={{
                      email: profileData && profileData?.email,
                      userName: profileData && profileData?.userName,
                      ageId: profileData && profileData?.ageGroup?.id,
                      gender: profileData && profileData?.gender,
                      ethnicity: profileData && profileData?.ethnicity,
                      // tagId: profileData && profileData?.tagId
                      tagId: selectedAgeGroupsData,
                    }}
                  >
                    <Col span={24} md={EditSectionColumnGap}>
                      {/* ===================== */}

                      {/* Profile Pic Upload */}
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={profilepic} alt="profile-pic" />
                            <label>Profile Picture</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="media"
                        style={{
                          marginLeft: "12px",
                          marginBottom: isSmallScreen && "55px",
                        }}
                      >
                        <div
                          className="profile-pic-container"
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          
                            <div className="" style={{ position: "relative" }}>
                              <img
                                src={existingProfilePic || userImg}
                                alt="Profile"
                                style={{
                                  width: is1920
                                    ? "60px"
                                    : is1366
                                    ? "40px"
                                    : "40px",
                                  height: is1920
                                    ? "60px"
                                    : is1366
                                    ? "40px"
                                    : "40px",
                                  borderRadius: "50%",
                                  border: "1px solid #3067AE",
                                  position: "relative",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-15px",
                                  right: "-12px",
                                  cursor: "pointer",
                                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                                  borderRadius: "0%",
                                  padding: "1px",
                                  transition: "background-color 0.3s",
                                }}
                                onClick={() =>
                                  document.getElementById("file-upload").click()
                                }
                                onMouseEnter={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "rgba(255, 255, 255, 1)")
                                }
                                onMouseLeave={(e) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "rgba(255, 255, 255, 0.8)")
                                }
                              >
                                <EditOutlined
                                  style={{
                                    fontSize: is1920 ? "18px" : "15px",
                                    color: "#3067AE",
                                  }}
                                />
                              </div>
                            </div>
                          
                          <input
                            style={{
                              width: isSmallScreen ? "80%" : undefined,
                              fontSize: isSmallScreen ? "12px" : undefined,
                              display: "none", // Hide the file input
                            }}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleProfilePicUpload(e)}
                            className="file-input"
                            id="file-upload"
                          />
                        </div>
                      </Form.Item>
                      {/* ========================== */}

                      <Form.Item
                        name="userName"
                        label={
                          <div className="style-label">
                            <img src={username} alt="username"></img>
                            <label>Username</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "Username is required" },
                        ]}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: isSmallScreen && "-7px",
                        }}
                      >
                        <Input
                          style={{
                            height: isSmallScreen
                              ? "40px"
                              : is1920
                              ? "40px"
                              : "35px",
                          }}
                          className="label-values"
                          placeholder="UserName"
                        />
                      </Form.Item>

                      <Form.Item
                        name="email"
                        label={
                          <div className="style-label">
                            <img src={mail} alt="mail"></img>
                            <label>Email</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        style={{
                          marginBottom: is1366 ? "24px" : is1920 && "35px",
                        }}
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Enter valid email" },
                        ]}
                      >
                        <Input
                          className="label-values"
                          placeholder="Email"
                          style={{
                            height: isSmallScreen
                              ? "40px"
                              : is1920
                              ? "40px"
                              : "35px",
                          }}
                        />
                      </Form.Item>
                      {/* ================= */}

                      <Form.Item
                        name="phoneNumber"
                        label={
                          <div
                            className="style-label"
                            style={{
                              marginLeft:
                                is1920 || is1366 || is1240 || is768 || is820
                                  ? "10px"
                                  : undefined,
                            }}
                          >
                            <img src={phoneProfile} alt="phone"></img>
                            <label>Phone</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        validateFirst={true}
                        // rules={[{ required: true }]}
                        style={{
                          // marginTop: !isSmallScreen && "-10px",
                          marginBottom: isSmallScreen && "30px",
                        }}
                      >
                        <p className="readOnlyPhoneNumber">
                          {" "}
                          {profileData?.phoneNumber
                            ? profileData?.phoneNumber
                            : ""}
                        </p>
                      </Form.Item>
                      {/* ================= */}
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={ageIcon} alt="age"></img>
                            <label>Age</label>
                          </div>
                        }
                        colon={false}
                        className="form-item-edit editForm-row-spacing"
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        rules={[{ required: true, message: "Age is required" }]}
                        name="ageId"
                        // style={{marginBottom:is320?"5px":"10px"}}
                      >
                        <Radio.Group
                          className="label-values"
                          // defaultValue="a"
                          defaultValue="24 - 37"
                          size={is320 ? "medium" : "large"}
                          buttonStyle="solid"
                          style={{
                            marginTop: isSmallScreen
                              ? "0px"
                              : is1920
                              ? "-18px"
                              : is1366
                              ? "-15px"
                              : is1240
                              ? "-12px"
                              : is768 || is820
                              ? "-12px"
                              : "0px",
                            marginBottom: "-22px",
                          }}
                        >
                          {ageGroups && ageGroups.length > 0
                            ? ageGroups.slice(1).map((ageGroup) => (
                                <Radio.Button
                                  className="ageGroup-buttons"
                                  value={ageGroup?.id}
                                  style={{
                                    width: isMobileScreenExcept320
                                      ? "21vw"
                                      : "auto",
                                  }}
                                  onClick={() => {
                                    getAgeData(ageGroup?.id);
                                    toggleClass();
                                    setLocalAge(ageGroup.ageGroup, ageGroup.id);
                                    form.setFieldValue("tagId", null);
                                  }}
                                  onChange={() => {
                                    setSelectedAgeGroupsData();
                                  }}
                                >
                                  {ageGroup?.ageGroup}
                                </Radio.Button>
                              ))
                            : ""}
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div
                            className="style-label"
                            style={{ marginLeft: "12px" }}
                          >
                            <img src={gender} alt="gender"></img>
                            <label>Gender</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="gender"
                      >
                        <Select
                          className="label-values"
                          popupClassName="gender-menu"
                          // style={{ fontSize: 24 }}
                        >
                          <Select.Option className="gender-select" value="Male">
                            Male
                          </Select.Option>
                          <Select.Option
                            className="gender-select"
                            value="Female"
                          >
                            Female
                          </Select.Option>
                          <Select.Option
                            className="gender-select"
                            value="Others"
                          >
                            Others
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div
                            className="style-label"
                            style={{ marginLeft: "12px" }}
                          >
                            <img src={ethnicityIcon} alt="ethnicity"></img>
                            <label>Ethnicity</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="ethnicity"
                      >
                        <Select
                          className="label-values"
                          value={ethnicity}
                          onChange={(e) => setEthnicityHandler(e)}
                        >
                          <Select.Option value="Indian">Indian</Select.Option>
                          <Select.Option value="American">
                            American
                          </Select.Option>
                          <Select.Option value="British">British</Select.Option>
                          <Select.Option value="African">African</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={proftag} alt="prof"></img>
                            <label>What do you do?</label>
                          </div>
                        }
                        className="form-item-edit editForm-row-spacing"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "What do you do? is required",
                          },
                        ]}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="tagId"
                      >
                        <Select
                          className="customSelect label-values"
                          value={selectedAgeGroupsData}
                          onChange={(e) => ageGroupDataChange(e)}
                        >
                          {ageGroupData &&
                            ageGroupData?.map((ageGroupData, i) => (
                              <Select.Option
                                key={i}
                                value={ageGroupData.tag.id}
                              >
                                {ageGroupData.tag.userTag}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <div className="form-btn-container" style={{width:is320 && "65vw"}}>
                        <Form.Item>
                          <Button
                            className="profile-cancel-btn"
                            onClick={() => {
                              setProfilePic(null);
                              setViewProfile(true);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            className="profile-submit-btn"
                            htmlType="submit"
                            type="primary"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Form>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        )}
        {showAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please set age!
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
