import { Dropdown, Modal, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import { setPartnerData, setUserProfile } from "../../Reducer/globalSlice";
import { GlobalContext } from "../globalContext/GlobalContext";
import { AddOrganization, AddOrganizationActive, Back, Menu, User } from "../Icons/Icons";
import userImg from '../../assets/newImages/User.svg'
import './navbar.scss';


const Navbar = () => {

  const { globalState, setGlobalState, profileUpdated, setProfileUpdated } = useContext(GlobalContext);

  const [LocalStatePartnerId, setLocalStatePartnerId] = useState()
  const [LocalStatePartnerName, setLocalStatePartnerName] = useState()
  const [LocalStateSharedSite, setLocalStateSharedSite] = useState()
  const routerLocation = useLocation();
  //to be commented in prod 22-24
  // const location = useLocation();
  // const partnerNotFormat = location?.pathname?.split("/")[2];
  // const formattedName = partnerNotFormat?.replace(/_/g, " ");

  // FOR SUBDOMAIN IMPLEMENTATION
  //to be umcommeted in prod from 29-32
  const location = window.location.href;
  const firstSplit = location.split('/')[2];
  const secondSplit = firstSplit.split('.')[0];
  const prodPartner = secondSplit?.replace(/-/g, " ") || "";
  // const prodPartner = "Partner Name";
  // console.log('partner in nav',partner)

  //to be uncommeted in prod just below one line
  localStorage.setItem("partner", prodPartner);

  useEffect(() => {
    // partnerId partnerName sharedSite
    if (localStorage.getItem('partnerId')) {
      setLocalStatePartnerId(localStorage.getItem('partnerId'))
    }
    if (localStorage.getItem('partnerName')) {
      setLocalStatePartnerName(localStorage.getItem('partnerName'))
    }
    if (localStorage.getItem('sharedSite')) {
      setLocalStateSharedSite(localStorage.getItem('sharedSite'))
    }
  }, [])



  const [partner, setPartner] = useState(prodPartner || LocalStatePartnerName || "Partner Name");
  const [showProfileOptions, setShowProfileOptions] = useState(false);

  const navigate = useNavigate();

  const profileOptionsRef = useRef(null);
  const profileImageRef = useRef(null);

  const isEditPage = routerLocation?.pathname?.includes("/edit");

  const dispatch = useDispatch();
  const reduxGlobalState = useSelector(state => state.global)
  const partnerData = useSelector(state => state.global.partner)

  const handleClickOutside = (event) => {
    if (
      profileOptionsRef.current &&
      !profileOptionsRef.current.contains(event.target) &&
      profileImageRef.current &&
      !profileImageRef.current.contains(event.target)
    ) {
      setShowProfileOptions(false);
    }
  };

  useEffect(() => {
    const getProfilePic = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await getRequest(
          userapiservice.getEndUserDetails(),
          headers
        );

        if (response) {
          dispatch(setUserProfile(response))
          setProfileUpdated(false);
        }
      } catch (error) {
        console.log('error loading profile ', error)
      }
    }

    if (!reduxGlobalState.userProfile || profileUpdated === true) {
      getProfilePic()
    }
  }, [profileUpdated, reduxGlobalState.userProfile, dispatch])


  const getPartnerDetails = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        localStorage.setItem('partner-latitude', response?.latitude)
        localStorage.setItem('partner-longitude', response?.longitude)
        localStorage.setItem("partnerID", response.id);

        dispatch(setPartnerData(response))
        // set partnerId in global context also
        setGlobalState({
          ...globalState,
          "partnerID": response.id,
          "partnerName": response.name
        })
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    const locationSplit = routerLocation?.pathname?.split('/');
    // if (partner && locationSplit.length >= 2 && locationSplit[1] === 'frontend' && !reduxGlobalState.partner) {
    //   getPartnerDetails();
    // }
    if (partner && !reduxGlobalState.partner) {
      getPartnerDetails();
    }
  }, [partner]);

  const environment = process.env.ENV || ''

  const navBarLinks = [
    { displayName: 'Home', link: `${environment === 'PRODUCTION' ? '/' : '/frontend/Partner_Name'}`, },
    { displayName: 'Browse', link: '/microsite', },
    { displayName: 'Add your organisation', link: '/onboarding', classNames: 'onboarding' }
  ]

  // popover items when hamburger is clicked
  const hamburgerItems = navBarLinks.map((ele, i) => {
    if (ele.link !== '/onboarding')
      return {
        key: i,
        label: <NavLink to={ele.link} className={`${(routerLocation.pathname === ele.link) ? 'active text-danger' : 'text-primary'} nav-link ${ele.classNames}  `}>
          {ele.displayName}
        </NavLink>
      }
  })


  const toggleProfileOptions = () => {
    setShowProfileOptions(prev => !prev);
  };

  const handleLogout = () => {

    dispatch(setUserProfile(null))
    localStorage.clear();
    // bug : if am in /microsite and logout we don't have partnerId
    window.location.href = '/';
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (globalState.isMobile) {
    return <nav className="navbar navbar-mobile justify-content-between align-content-center">
      {routerLocation.pathname === "/frontend/Partner_Name" || routerLocation.pathname === "/" ? <span className="nav-partner-menu">
        <Dropdown menu={{ items: hamburgerItems }} trigger={['click']}>
          <Space onClick={(e) => e.preventDefault()}>
            <Menu />
          </Space>
        </Dropdown>
      </span>
        :
        <span className="nav-partner-menu" onClick={() => { navigate(-1) }} >
          <Back />
        </span>}


      <span className="nav-partner-logo nav-partner-with-logo">
        {partnerData?.mediaLink && < img src={partnerData?.mediaLink} alt="partner logo" />}
      </span >



      <ul className="navbar-nav d-flex justify-content-between  align-items-center flex-row p-0 m-0 h-100">
        {
          <li className={`nav-item fw-bold`} >
            <NavLink to={'/onboarding'} className={` nav-link `}>
              {routerLocation.pathname === "/onboarding" ? <AddOrganizationActive /> : <AddOrganization />}
            </NavLink>
          </li>}

        <li className="nav-item fw-bold">
          {reduxGlobalState?.userProfile?.mediaLink ?
            <div>
              <img className="user-icon" src={reduxGlobalState?.userProfile?.mediaLink
              } alt="user-icon" style={{ cursor: "pointer" }}
                onClick={toggleProfileOptions}
              />
            </div>
            :
            <NavLink to={'/login'} className={`${routerLocation.pathname === '/login' ? 'active text-danger' : 'text-primary'} nav-link`}>
              <User />
            </NavLink>
          }
        </li>
      </ul>
      {showProfileOptions && (
        <div className="profile-options" ref={profileOptionsRef}>
          {!isEditPage &&
            <div className="profile-option" onClick={() => navigate("/edit")}>Edit Profile</div>
          }
          <div className="profile-option" onClick={handleLogout}>Logout</div>
        </div>
      )}
    </nav>
  }

  return (

    <nav className="navbar navbar-desktop row justify-content-between align-content-center m-0 navbar-expand-lg" >
      <div className="col-3 col-md-4 col-lg-5 d-flex align-items-center flex-row partner-info">
        <span className="navbar-brand partner-with-logo">
          {partnerData?.mediaLink && < img src={partnerData?.mediaLink} width="60" className="img-fluid" alt="" />}
        </span >
        <strong className="navbar-brand-title" >
          {partnerData?.siteName}
        </strong>
      </div >
      <ul className={`col-9 col-md-8 col-lg-7 navbar-nav d-flex align-items-center flex-row justify-content-around`}>
        {navBarLinks.map((ele, i) => {
          return <React.Fragment key={i}>
            <li className="nav-item fw-bold" >
              <NavLink to={ele.link} className={`${(routerLocation.pathname === ele.link) ? 'active text-danger' : 'text-primary'} nav-link ${ele.classNames}  `}>
                {ele.displayName}
              </NavLink>
            </li>
            <li style={{
              borderRight: '2px solid #BAC4D2', display: 'inline-block',
              width: '2px', height: '10px'
            }} >  &nbsp;</li>
          </React.Fragment>
        })}

        {showProfileOptions && (
          <div className="profile-options" ref={profileOptionsRef}>
            {!isEditPage &&
              <div className="profile-option" onClick={() => navigate("/edit")}>Edit Profile</div>
            }
            <div className="profile-option" onClick={handleLogout}>Logout</div>
          </div>
        )}

        <>
          {globalState.user ?
            (<li className="nav-item fw-bold">
              username
              <img src="" alt="profile picture" />

            </li>)
            :
            <li className={`nav-item fw-bold`}>
              {reduxGlobalState?.userProfile ?
                <div>
                  <img className="user-icon" src={reduxGlobalState?.userProfile?.mediaLink || userImg} alt="user-icon" style={{ cursor: "pointer" }}
                    onClick={toggleProfileOptions} ref={profileImageRef}
                  />
                </div>
                :
                <NavLink to={'/login'} className={`${routerLocation.pathname === '/login' ? 'active text-danger' : 'text-primary'} nav-link`}>
                  Login
                </NavLink>

                //   <div>
                //   <img className="user-icon" src={userImg} alt="user-icon" style={{ cursor: "pointer" }}
                //     onClick={toggleProfileOptions} ref={profileImageRef}
                //   />
                // </div>
              }
            </li>}

        </>
      </ul>
    </nav >

  )
}

export default Navbar